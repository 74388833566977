import { get } from 'lodash'

import { ReportUserActionType } from 'my-constants/action-types';
import { ReportUserService } from 'my-services/report_user';

import {toast} from "react-toastify";

export const initListUser = () => {
    return dispatch => {
        return ReportUserService.getListUser()
            .then(res => {
                if(res.status) {
                    const listUser = res.res.data.List.map(item => ({
                        value: item.id,
                        label: item.username.toUpperCase(),
                        group_id: item.group_id ? item.group_id : null ,
                        group_name: item.group_name ? item.group_name: null,
                        credit: item.credit
                    }))
                    // const listAccFull = res.res.data.ListAccFull
                    // console.log('listAccFull--', listAccFull)
                    const listAccFull = res.res.data.ListAccFull.map(item => ({
                        value: item._id,
                        label: item.acc_name.toUpperCase(),

                    }))

                    const extraCostInfo = res.res.data.ListBook.filter(item => item.book_name == 'extracost')
                    // const listUser = res.res.data.List
                    //Dispatch data to reducer
                    dispatch({
                        type: ReportUserActionType.INIT_LIST_USER,
                        listUser: listUser,
                        listAccFull: listAccFull,
                        extraCostInfo: extraCostInfo[0],

                    })
                }
            })
    }
}

export const initListAccount = (post) => {
    return dispatch => {
        // dispatch({
        //     type: ReportUserActionType.INIT_LIST_ACCOUNT
        // })
        return ReportUserService.getListAccount(post)
            .then(res => {
                if(res.status) {
                    // const listAccount = res.res.data.List.map(item => ({
                    //     value: item.id,
                    //     label: item.acc_name,
                    // }))

                    const listAccount = res.res.data.List
                    //Dispatch data to reducer
                    dispatch({
                        type: ReportUserActionType.INIT_LIST_ACCOUNT,
                        listAccount: listAccount

                    })
                }

            })
    }
}

export const getReport = (post) => {
    return dispatch => {
        return ReportUserService.getReport(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: ReportUserActionType.GET_REPORT_SUCCESS,
                        payload: res.res.data.List,
                    });
                } else {
                    dispatch({
                        type: ReportUserActionType.GET_REPORT_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        },
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: ReportUserActionType.GET_REPORT_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};

export const getReportTotal = (post) => {
    return dispatch => {
        return ReportUserService.getReportTotal(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: ReportUserActionType.GET_REPORT_TOTAL_SUCCESS,
                        // payload: res.res.data.List,
                        payload: res,
                    });
                } else {
                    dispatch({
                        type: ReportUserActionType.GET_REPORT_TOTAL_SUCCESS,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        },
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: ReportUserActionType.GET_REPORT_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};

export const saveReport = (post) => {
    return dispatch => {
        return ReportUserService.saveReport(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportUserActionType.SAVE_REPORT_SUCCESS,
                        saveStatus: res.status
                    })
                    toast.success("SUCCESS !!!");

                } else {
                    dispatch({
                        type: ReportUserActionType.SAVE_REPORT_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: ReportUserActionType.SAVE_REPORT_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const saveReport_2 = (post) => {
    return dispatch => {
        return ReportUserService.saveReport_2(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportUserActionType.SAVE_REPORT_SUCCESS,
                        saveStatus: res.status
                    })
                    toast.success("SUCCESS !!!");

                } else {
                    dispatch({
                        type: ReportUserActionType.SAVE_REPORT_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: ReportUserActionType.SAVE_REPORT_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const deleteReport = (post) => {
    return dispatch => {
        return ReportUserService.deleteReport(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportUserActionType.DELETE_REPORT_SUCCESS,
                        deleteStatus: res.status
                    })
                    toast.success("SUCCESS !!!");
                } else {
                    dispatch({
                        type: ReportUserActionType.DELETE_REPORT_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: ReportUserActionType.DELETE_REPORT_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const getListUserImage = () => {
    return dispatch => {
        return ReportUserService.getListUserImage()
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportUserActionType.GET_LIST_USER_IMAGE_SUCCESS,
                        payload: res
                    })
                } else {
                    dispatch({
                        type: ReportUserActionType.GET_LIST_USER_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                }

            })
            .catch( e => {
                dispatch({
                    type: ReportUserActionType.GET_LIST_USER_IMAGE_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                })
            })
    }
}

export const editReport = (post) => {
    return dispatch => {
        dispatch({
            type: ReportUserActionType.EDIT_REPORT,
            selectedItem: post.selectedItem,
            isEditReport: true
        });
    }
};

export const changeDisabledEdit = () => {
    return dispatch => {
        dispatch({
            type: ReportUserActionType.CHANGE_DISABLE_EDIT_GROUP,
        });
    }
};


export const changeInput = (list) => {
    return (dispatch) => {
        dispatch({
            type: ReportUserActionType.CHANGE_INPUT,
            payload: list
        })
    }
};

export const changeItemEdit = (params) => {
    return (dispatch) => {
        dispatch({
            type: ReportUserActionType.CHANGE_EDIT_ITEM,
            payload: params
        })
    }
};

export const addExtraItem = (params) => {
    return (dispatch) => {
        dispatch({
            type: ReportUserActionType.ADD_EXTRA_ITEM,
            payload: params
        })
    }
};