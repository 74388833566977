import { get } from 'lodash'

import { ManageImageActionType } from 'my-constants/action-types';
import { ManageImageService } from 'my-services/manage';
import {toast} from "react-toastify";

export const initListUser = () => {
    return dispatch => {
        dispatch({
            type: ManageImageActionType.INIT_LIST_USER_IMAGE
        })
        return ManageImageService.getListUser()
            .then(res => {
                if(res.status) {
                    const listUser = res.res.data.List.map(item => ({
                        value: item.id,
                        label: item.username.toUpperCase(),
                    }))

                    const listUserOP6 = res.res.data.ListOP6.map(item => ({
                        value: item.id,
                        label: item.username.toUpperCase(),
                    }))

                    const listAcc = res.res.data.ListAcc.map(item => ({
                        value: item.id,
                        user_id: item.uid,
                        label: item.acc_name.toUpperCase(),
                    }))

                    const listTrick = res.res.data.ListTrick.map(item => ({
                        value: item.id,
                        label: item.trick.toUpperCase(),
                    }))

                    //Dispatch data to reducer
                    dispatch({
                        type: ManageImageActionType.INIT_LIST_USER_IMAGE,
                        listUser: listUser,
                        listUserOP6: listUserOP6,
                        listAcc: listAcc,
                        listTrick: listTrick,

                    })
                }
            })
    }
}

export const initListAccount = (post) => {
    return dispatch => {
        dispatch({
            type: ManageImageActionType.INIT_LIST_ACCOUNT_IMAGE
        })
        return ManageImageService.getListAccount(post)
            .then(res => {
                if(res.status) {
                    const listAccount = res.res.data.List.map(item => ({
                        value: item.id,
                        label: item.acc_name,
                    }))
                    //Dispatch data to reducer
                    dispatch({
                        type: ManageImageActionType.INIT_LIST_ACCOUNT_IMAGE,
                        listAccount: listAccount

                    })
                }

            })
    }
}

export const saveImage = (post) => {
    return dispatch => {
        return ManageImageService.saveImage(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ManageImageActionType.SAVE_IMAGE_SUCCESS,
                        saveStatus: res.status
                    })
                    toast.success("SUCCESS !!!");

                } else {
                    dispatch({
                        type: ManageImageActionType.SAVE_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: ManageImageActionType.SAVE_IMAGE_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const deleteImage = (post) => {
    return dispatch => {
        return ManageImageService.deleteImage(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ManageImageActionType.DELETE_IMAGE_SUCCESS,
                        deleteStatus: res.status
                    })
                    toast.success("SUCCESS !!!");
                } else {
                    dispatch({
                        type: ManageImageActionType.DELETE_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: ManageImageActionType.DELETE_IMAGE_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const getListUserImage = () => {
    return dispatch => {
        return ManageImageService.getListUserImage()
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ManageImageActionType.GET_LIST_USER_IMAGE_SUCCESS,
                        payload: res
                    })
                } else {
                    dispatch({
                        type: ManageImageActionType.GET_LIST_USER_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                }

            })
            .catch( e => {
                dispatch({
                    type: ManageImageActionType.GET_LIST_USER_IMAGE_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                })
            })
    }
}

export const editUserImage = (post) => {
    return dispatch => {
        dispatch({
            type: ManageImageActionType.EDIT_IMAGE_SELECTED,
            selectedItem: post.selectedItem
        });
    }
};
export const clearInput = () => {
    return dispatch => {
        dispatch({
            type: ManageImageActionType.CLEAR_INPUT_IMAGE,
        });
    }
};
export const disabledEdit = () => {
    return dispatch => {
        dispatch({
            type: ManageImageActionType.DISABLE_EDIT_GROUP,
        });
    }
};

export const changeDisabledEdit = () => {
    return dispatch => {
        dispatch({
            type: ManageImageActionType.CHANGE_DISABLE_EDIT_GROUP,
        });
    }
};
export const resetSelectDay = () => {
    return (dispatch) => {
        dispatch({
            type: ManageImageActionType.RESET_SELECT_DAY,
        })
    }
};

export const selectDay = (post) => {
    console.log('ManageImageActionType---')
    return dispatch => {
        dispatch({
            type: ManageImageActionType.CHANGE_DAY,
            selectedDay: post,
        });
    }
};
//
// export const toggleImage = (params) => {
//     return dispatch => {
//         dispatch({
//             type: ManageImageActionType.CHANGE_DAY,
//             selectedDay: post,
//         })
//     }
// }
