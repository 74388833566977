import React, { Component } from 'react';

import { CreditLogListContainer } from 'my-containers/admin/credit_log'

class CreditLogPage extends Component {

    render() {
        return (
            <div className="portlet light bordered">
                <div className="portlet-body">
                    <div className="row">
                        <div className="col-md-12">
                            <CreditLogListContainer/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreditLogPage;