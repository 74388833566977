import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { TransComponent, LoadingComponent } from 'my-components';
import ReactPaginate from 'react-paginate';
import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'
import { getTrick, saveTrick, deleteTrick, editTrick} from 'my-actions/manage/TrickConfigAction';
import {Field, reduxForm} from "redux-form";
import {  renderError } from 'my-utils/components/redux-form/render-form'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {get as _get} from "lodash";

import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class TrickConfigContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            perPage: 20,
            currentPage: 0,
            filters: [],
        }

    }

    componentWillMount() {
        this.props.initialize({...this.props.initialValues})
    }

    componentDidMount() {
        this.props.getTrick()

    }

    componentDidUpdate() {
        if(this.props.reload) {
            this.props.getTrick();

        }
    }

    componentWillReceiveProps (nextProps, prevProps) {
        console.log('nextProps.selectedItem---', nextProps.selectedItem)
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            this.props.initialize({
                ...nextProps.selectedItem
            })

            this.setState(
                {
                    id: nextProps.selectedItem.id
                })

        }


    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });

    };


    handleEdit = item => {
        const params = {
            selectedItem: item.selectedItem,
        }
        this.props.editTrick(params)
    }

    handleDelete = item => {
        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteTrick(params)
    }


    handleSubmitSave = e => {
        e.preventDefault();

        const params = {
            id: this.state.id,
            trick: _get(this.props.initialValues, 'trick'),

        }
        // console.log('params----', params)
        this.props.saveTrick(params)
        this.props.initialize({})

    }




    render() {
        let  { listTrick } = this.props


        let  { filters, perPage, currentPage } = this.state

        let pageCount = Math.ceil(listTrick.length / perPage)
        return (
            <div className="portlet light bordered">
                <div className="portlet-body form">
                    <div className="row">
                        <div className="col-md-4">
                            <form name="form_group">
                                <div className="form-body">
                                    <div className="form-group">
                                        <label><TransComponent i18nKey="Trick" /></label>
                                        <Field
                                            name="trick"
                                            type="text"
                                            component="input"
                                            className="form-control form-control-solid placeholder-no-fix "
                                            autoComplete="off"
                                        />
                                        <Field name="trick"component={renderError} />
                                    </div>
                                        {/*{editStatus && <button type="button" className="btn red" disabled={this.props.invalid || !show_button } onClick={this.handleUpdate}><TransComponent i18nKey="Update" /></button>}*/}
                                        {/*{!editStatus && <button type="button" className="btn red" disabled={ !show_button } onClick={this.handleCreate}><TransComponent i18nKey="Create new" /></button>}*/}
                                        <div className="form-actions text-right">
                                            <button type="button" className="btn red" disabled={this.props.invalid}
                                                    onClick={this.handleSubmitSave}><TransComponent i18nKey="Save" /></button>
                                        </div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={4000}
                                    />

                                </div>
                            </form>
                        </div>
                        <div className="col-md-8">
                            <div className="row portlet-title">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr className="font-red-sunglo">
                                            <th>#</th>
                                            <th className="uppercase"><TransComponent i18nKey="Trick"/></th>
                                            <th><TransComponent i18nKey="Edit"/></th>
                                            <th><TransComponent i18nKey="Delete"/></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            listTrick.length ?
                                                listTrick.map( (item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td> {currentPage * perPage + index + 1} </td>
                                                            <td className="uppercase">{item.trick}</td>
                                                            <td>
                                                                <button className="text-success btn btn-link " onClick={ () => this.handleEdit({selectedItem:item})}>
                                                                    <i className="fa fa-edit" />
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="text-success btn btn-link font-red" onClick={ () => this.handleDelete({selectedItem:item})}>
                                                                    <i className="fa fa-close" />
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                                : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                                        }
                                        </tbody>
                                    </table>
                                    {pageCount > 1 &&
                                    <ReactPaginate
                                        previousLabel={"prev"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group.values'),
        listTrick: state.TrickConfigReducer.listTrick ,
        reload: state.TrickConfigReducer.reload ,
        selectedItem: state.TrickConfigReducer.selectedItem ,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTrick: () => dispatch(getTrick()),
        saveTrick: (params) => dispatch(saveTrick(params)),
        deleteTrick: (params) => dispatch(deleteTrick(params)),
        editTrick: (params) => dispatch(editTrick(params))


    };
};

const validate = values => {
    const errors = {}
    if (!values.trick) {
        errors.trick = '"trick" is required'
    }

    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate,
        enableReinitialize: false
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(TrickConfigContainer);