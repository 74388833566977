import React,{ Component } from 'react';

// import { TransComponent } from 'my-components'

import { ManageImageContainer } from 'my-containers/manage'

class ManageImagePage extends Component {
    render() {
        return (
            <ManageImageContainer/>
        );
    }
}

export default ManageImagePage;