import { AppConfig} from 'my-constants'
import { BaseService, HttpService, HttpServiceMultipart} from 'my-utils/core'

class PolicyService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/policy_image`

    getList() {
        return HttpService.post(`${this.serviceUrl}/get_list_policy`)
    }

    saveImage(post) {
        return HttpServiceMultipart.post(`${this.serviceUrl}/action`, post)
    }

    deleteImage(post){
        return HttpService.post(`${this.serviceUrl}/delete`, post);
    };
}

export default new PolicyService()