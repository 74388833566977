import { HistoryRecoveryActionType } from 'my-constants/action-types'

let defaultState = {
    listHistory: []
};

export const HistoryRecoveryReducer = (state = defaultState, action) => {
	switch(action.type){
		case HistoryRecoveryActionType.GET_HISTORY_RECOVERY_SUCCESS:
			return { ...state, listHistory: action.payload, error: null };
        case HistoryRecoveryActionType.GET_HISTORY_RECOVERY_FAIL:
			return { ...state, error: action.payload };
		default:
			return {...state}
	}
};


export default HistoryRecoveryReducer;