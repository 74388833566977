import React, { Component } from 'react';

// import { CreditLogListContainer } from 'my-containers/admin/credit_log'
import { HistoryCreditContainer } from 'my-containers/admin/history_credit'

class HistoryCreditPage extends Component {

    render() {
        return (
            <div className="portlet light bordered">
                <div className="portlet-body">
                    <div className="row">
                        <div className="col-md-12">
                            <HistoryCreditContainer/>
                            {/*<h1>History Credit Page</h1>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HistoryCreditPage;