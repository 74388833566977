import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { TransComponent } from 'my-components'
import { getAccountGroups, editAccountGroup, deleteAccountGroup } from 'my-actions/AccountGroupAction';
import ReactPaginate from 'react-paginate';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'

class ListUserContaner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            offset: 0,
            data: [],
            perPage: 20,
            currentPage: 0
        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        this.props.getAccountGroups();
    }

    componentDidUpdate() {
        // refresh data

        if(this.props.reload) {
            this.props.getAccountGroups();

        }
    }

    handleEditAccountGroup = item => {
        const params = {
            selectedItem: item.selectedItem,
        }
        this.props.editAccountGroup(params)
    }

    handleDeleteAccountGroup = item => {
        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteAccountGroup(params)
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    render() {
        let lstAccountGroup = this.props.lstAccountGroup
        
        return (
            <div className="portlet light bordered">
                <div className="row portlet-title">
                    <div className="col-md-9 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="List Account Group" /></span>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th><TransComponent i18nKey="Name"/></th>
                                <th><TransComponent i18nKey="Note"/></th>
                                <th><TransComponent i18nKey="Edit"/></th>
                                <th><TransComponent i18nKey="Delete" /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lstAccountGroup.length ?
                                    lstAccountGroup.map( (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {this.state.currentPage * this.state.perPage + index + 1} </td>
                                                <td className="uppercase">{item.name}</td>

                                                <td className="uppercase">{item.note}</td>
                                                <td>
                                                    <a href="javascript:void(0)" className="text-success btn btn-link" onClick={ () => this.handleEditAccountGroup({selectedItem:item})}>
                                                        <i className="fa fa-edit" />
                                                    </a>
                                                </td>
                                                <td>
                                                    <button className="text-success btn btn-link font-red" onClick={ () => this.handleDeleteAccountGroup({selectedItem:item})}>
                                                        <i className="fa fa-close" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        reload: state.AccountGroupReducer.reload,
        lstAccountGroup: state.AccountGroupReducer.lstAccountGroup,
    };
};

const mapDispatchToProps = dispatch => {
    // console.log('Dispatch==', dispatch)
    return {
        getAccountGroups: (params) => dispatch(getAccountGroups(params)),
        editAccountGroup: (params) => dispatch(editAccountGroup(params)),
        deleteAccountGroup: (params) => dispatch(deleteAccountGroup(params))
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListUserContaner);