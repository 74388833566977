import React, { Component } from 'react'

import { TransComponent } from 'my-components'
import { ReportUserContainer_2 } from 'my-containers/report_user_2'

export default class ReportPage_2 extends Component {
    render() {
        return (
                <div className="portlet-body">
                    <ReportUserContainer_2 />
                </div>
        )
    }
}
