import React, { Component } from 'react';

import { HistoryRecoveryContainer } from 'my-containers/admin/history_recovery'

class HistoryRecoveryPage extends Component {

    render() {
        return (
            <div className="portlet light bordered">
                <div className="portlet-body">
                    <div className="row">
                        <div className="col-md-12">
                            <HistoryRecoveryContainer/>
                            {/*<h1>History Recovery Page</h1>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HistoryRecoveryPage;