import React, {Component} from "react";

import { CookieService } from 'my-utils/core'
import { TransComponent } from 'my-components'
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import { RolesConfig} from 'my-constants'
import { Helpers } from 'my-utils'

class InfoUserContainer extends Component {
    render() {
        const username = this.props.userInfo.username ? this.props.userInfo.username : null;
        const credit = this.props.userInfo.credit ? this.props.userInfo.credit : 0;
        const status = CookieService.get("status");
        const roles = CookieService.get("roles");

        return (
            <>
                <div className="col-md-4 hide-on-mobile" style={{paddingTop: "10px", fontSize: '12px'}}>
                    <div className="font-white"><span><TransComponent i18nKey="hello: " /></span>
                        <span className="uppercase"> { username } </span>
                    </div>
                    {
                        Number(status) === 1 && Number(roles) === RolesConfig.AGENT &&
                            <div className="font-white" style={{fontSize: '14px', fontWeight: 'bold'}}><span><TransComponent i18nKey="Credit: " /> </span>
                                <span> { Helpers.formatMoney(credit, Number.isInteger(+credit) ? 0 : 2) } </span>
                            </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        userInfo: state.LanguageReducer.user_info || {}
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(InfoUserContainer);