import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class ImagesService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;

    /*
    |--------------------------------------------------------------------------
    | @content: Get list image
    |--------------------------------------------------------------------------
    */
    // getListImage(post) {
    //     return HttpService.post(`${this.serviceUrl}/images/get_image`, post);
    // };

    getListImage(post) {
        return HttpService.post(`${this.serviceUrl}/images/get_list_image`, post);
    };

}

export default new ImagesService()