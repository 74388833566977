import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";
import { connect } from 'react-redux';

import { RoutesService } from 'my-routes';
import { CookieService } from 'my-utils/core';
import { TransComponent } from 'my-components'
import { RolesConfig} from 'my-constants'


export const menus = [
    { to: RoutesService.getPath('ADMIN', 'DASHBOARD'), roles: [RolesConfig.AGENT, RolesConfig.SALE], exact: true, name: 'Dashboard', icon: 'fa fa-pie-chart'},
    // { to: RoutesService.getPath('ADMIN', 'CREATE_USER'), roles: [RolesConfig.FINANCIAL, RolesConfig.SALE], exact: true, name: 'Create user', icon: 'fa fa-sliders'},
    { to: RoutesService.getPath('ADMIN', 'LIST_USER'), roles: [RolesConfig.FINANCIAL, RolesConfig.SALE, RolesConfig.ADMINOP6], exact: true, name: 'Users', icon: 'fa fa-sliders'},
    { to: RoutesService.getPath('ADMIN', 'ACCOUNT'), roles: [RolesConfig.ADMIN, RolesConfig.AGENT, RolesConfig.FINANCIAL, RolesConfig.SALE], exact: false, name: 'Account', icon: 'fa fa-gears',
        // sub_menus: [
        //     { to: RoutesService.getPath('ADMIN', 'CREATE_USER'), roles: [10], exact: true, name: 'Account', icon: 'fa fa-sliders' },
        //     { to: RoutesService.getPath('ADMIN', 'CREATE_GROUP_USER'), roles: [10], exact: true, name: 'Create Group', icon: 'fa fa-sliders' },
        //     { to: RoutesService.getPath('ADMIN', 'MANAGE_IMAGE'), roles: [10], exact: true, name: 'Manage User Image', icon: 'fa fa-sliders' },
        //     { to: RoutesService.getPath('ADMIN', 'MANAGE_GROUP_IMAGE'), roles: [10], exact: true, name: 'Manage Group Image', icon: 'fa fa-sliders' },
        // ],
    },
    { to: RoutesService.getPath('ADMIN', 'MANAGE_IMAGE'), roles: [RolesConfig.ADMIN, RolesConfig.ADMINOP6], exact: true, name: 'Quản lý hình ảnh', icon: 'fa fa-pie-chart'},
    { to: RoutesService.getPath('ADMIN', 'TRICK_CONFIG'), roles: [RolesConfig.ADMIN], exact: true, name: 'Cấu hình Tricks', icon: 'fa fa-gears'},
    { to: RoutesService.getPath('ADMIN', 'REPORT_IMAGE'), roles: [RolesConfig.ADMIN, RolesConfig.AGENT, RolesConfig.FINANCIAL, RolesConfig.SALE], exact: true, name: 'Báo cáo phân tích', icon: 'fa fa-pie-chart'},
    /* ========= ADMIN NAVIGATION =========== */
    { to: RoutesService.getPath('ADMIN_MANAGE', 'LANGUAGE'), roles: [RolesConfig.SUPER_ADMIN], exact: true, name: 'Language', icon: 'fa fa-language fa-fw'},
    // { to: RoutesService.getPath('ADMIN_MANAGE', 'CREDIT_LOG'), roles: [RolesConfig.SUPER_ADMIN, RolesConfig.FINANCIAL,
    //         RolesConfig.SALE, RolesConfig.AGENT], exact: true, name: 'credit logs', icon: 'fa fa-money fa-fw'},
    { to: RoutesService.getPath('ADMIN_MANAGE', 'CONFIG'), roles: [RolesConfig.SUPER_ADMIN], exact: true, name: 'config', icon: 'fa fa-money fa-fw'},
    { to: RoutesService.getPath('ADMIN_MANAGE', 'ACCOUNT GROUP'), roles: [RolesConfig.SUPER_ADMIN], exact: true, name: 'account group', icon: 'fa fa-money fa-fw'},
    { to: RoutesService.getPath('ADMIN', 'ACCOUNTANT_REPORT'), roles: [ RolesConfig.FINANCIAL, RolesConfig.SALE, RolesConfig.AGENT], exact: false, name: 'Report', icon: 'fa fa-money fa-fw',
        sub_menus: [
            { to: RoutesService.getPath('ADMIN', 'CREATE_REPORT'), roles: [RolesConfig.FINANCIAL, RolesConfig.SALE, RolesConfig.AGENT], exact: true, name: 'Chi tiết báo cáo', icon: 'fa fa-sliders' },
            { to: RoutesService.getPath('ADMIN', 'ACCOUNTANT_REPORT'), roles: [RolesConfig.FINANCIAL, RolesConfig.SALE, RolesConfig.AGENT], exact: true, name: 'Báo cáo chu kỳ', icon: 'fa fa-calendar-check-o' },
            { to: RoutesService.getPath('ADMIN', 'HISTORY_CREDIT'), roles: [RolesConfig.FINANCIAL, RolesConfig.SALE], exact: true, name: 'Lịch sử bơm điểm', icon: 'fa fa-calendar-check-o' },
            { to: RoutesService.getPath('ADMIN', 'HISTORY_RECOVERY'), roles: [RolesConfig.FINANCIAL, RolesConfig.SALE], exact: true, name: 'Lịch sử hoàn trả', icon: 'fa fa-calendar-check-o' },
        ]

    },
    { to: RoutesService.getPath('ADMIN_MANAGE', 'NOTICE'), roles: [RolesConfig.SUPER_ADMIN, RolesConfig.SALE], exact: true, name: 'Notice', icon: 'fa fa-sticky-note fa-fw'},
    { to: RoutesService.getPath('ADMIN_MANAGE', 'POLICY'), roles: [RolesConfig.SALE], exact: true, name: 'Policy', icon: 'fa fa-sticky-note fa-fw'},

];

/*===============================
 * Custom HTML Link Router
 * ==============================*/
const MenuLink = ({ menu, isReplaceRouterLink }) => {
    const roles = CookieService.get("roles");
    const status = CookieService.get("status");
    return (
        <Route
            path={menu.to}
            exact={menu.exact}
            children={
                ({match}) => {
                    let active = (match) ? "active" : ""
                    let sub_menus = ""
                    if (typeof menu.sub_menus !== "undefined") {
                        sub_menus = menu.sub_menus.map((menu, index) => {
                            if(menu.roles.indexOf(Number(roles)) === -1 || Number(status) === 0) return null;

                            return (
                                <SubMenuLink key={index} menu={menu} isReplaceRouterLink={isReplaceRouterLink}/>
                            )
                        })
                    }

                    return (
                        <li className={`nav-item ${active}`}>
                            {
                                isReplaceRouterLink
                                    ? (
                                        <a href={menu.to} className="nav-link text-uppercase text-expanded">
                                            <i className={menu.icon} />
                                            <span className="title"><TransComponent i18nKey={menu.name}/></span>
                                            {sub_menus ? active ? <span className="arrow open" /> : <span className="arrow" /> : ""}
                                        </a>
                                    )
                                    : (
                                        <Link
                                            to={menu.to}
                                            className="nav-link text-uppercase text-expanded"
                                        >
                                            <i className={menu.icon} />
                                            <span className="title"><TransComponent i18nKey={menu.name}/></span>
                                            {sub_menus ? active ? <span className="arrow open" /> : <span className="arrow" /> : ""}
                                        </Link>
                                    )
                            }
                            {sub_menus ? <ul className="sub-menu">
                                {sub_menus}
                            </ul> : ""}
                        </li>
                    )
                }
            }
        />
    )
}

/*===============================
 * Custom HTML Link Router
 * ==============================*/
const SubMenuLink = ({ menu, isReplaceRouterLink }) => (
    <Route
        path={menu.to}
        exact={menu.exact}
        children={
            ({ match }) => {
                let active = (match) ? "active" : "";
                return (
                    <li className={`nav-item ${active}`}>
                        {
                            isReplaceRouterLink
                                ? (
                                    <a href={menu.to} className="nav-link">
                                        <span className="title"><TransComponent i18nKey={menu.name} /></span>
                                    </a>
                                )
                                : (
                                    <Link
                                        to={menu.to}
                                        className="nav-link"
                                    >
                                        <span className="title"><TransComponent i18nKey={menu.name} /></span>
                                    </Link>
                                )
                        }
                    </li>
                )
            }
        }
    />
);
    
class MainNavigation extends Component {
    /*====================================
     * Initial Menu Elements
     *====================================*/
    initMenus(menus) {
        const roles = CookieService.get("roles");
        const status = CookieService.get("status");
        let xhtml = null
        if (menus.length) {
            xhtml = menus.map((menu, index) => {
                if(menu.roles.indexOf(Number(roles)) === -1 || Number(status) === 0) return null;

                return (
                    <MenuLink key={index} menu={menu} isReplaceRouterLink={this.props.isReplaceRouterLink} />
                )
            })
        }
        return xhtml
    }

    render() {
        return (
            <div className="page-sidebar-wrapper">
                <div className="page-sidebar navbar-collapse collapse">
                    <ul className="page-sidebar-menu  page-header-fixed " data-keep-expanded="false" data-auto-scroll="true" data-slide-speed={200} style={{ paddingTop: 20 }}>
                        {this.initMenus(menus)}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, null)(MainNavigation);