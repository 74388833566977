import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { AccountService } from 'my-services/account';
import { getAccount } from 'my-actions/AccountAction';
import connect from "react-redux/es/connect/connect";

class ButtonCheckLoginContainer extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        isCheck: PropTypes.bool,
    };

    static defaultProps = {
        isCheck: false,
    };

    state = {
        isMoving: false,
        status: null,
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isMove && nextProps.isMove !== this.props.isMove) {
            this.handleMoveToScanIP(this.props.item)();
        }
    }

    handleMoveToScanIP = item => () => {
        // if (this.state.isMoving || item.status != 1) {
        if (this.state.isMoving || item.status == 0 || (item.status == 2 && !item.is_change_info)) {
            return null;
        }

        this.setState({ isMoving: true }, ()=> {
            return AccountService.moveToScanIP(item.id)
                .then(res => {
                    if (res.status) {
                        this.props.getAccount()
                        this.setState({ isMoving: false, status: 'success' });
                    }
                    else this.setState({ isMoving: false, status: 'err' });
                })
                .catch(() => this.setState({ isMoving: false, status: 'err' }));
        });
    };

    render() {
        const { isMoving, status } = this.state;
        const { item } = this.props
        let classIcon = 'fa-share ';

        // if (isMoving) classIcon = 'fa-spinner fa-spin';
        // else if(this.props.item.status == 2 ) classIcon = 'fa-check font-green  ';
        // else if (status) {
        //     classIcon = status === 'success' ? 'fa-check font-green' : 'fa-close font-red ';
        // }

        if (isMoving) classIcon = 'fa-spinner fa-spin';
        else if(item.status == 2 && !item.is_change_info) classIcon = 'fa-check font-green  ';
        else if(item.status == 2 && item.is_change_info) classIcon = 'fa-share border-flash ';
        else if (status) {
            classIcon = status === 'success' ? 'fa-check font-green' : 'fa-close font-red ';
        }

        return <i className={ `fa ${classIcon} cursor-pointer` } onClick={this.handleMoveToScanIP(this.props.item)} />
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAccount: () => dispatch(getAccount())
    };
};

export default connect(null, mapDispatchToProps)(ButtonCheckLoginContainer);
