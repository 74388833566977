import { ReportUserActionType } from 'my-constants/action-types';

import { get } from 'lodash';

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    listReport: [],
    listReportTotal: [],
    listAccount: [],
    isEditReport: false,
    clearInput: false,
    disabledEdit: false,
    isSelectDay: false,
    total_credit_wl: 0,
    isSelectDaySearch: false,
    isChangeInput: false,

}

const ReportUserReducer = (state = defaultState, action) => {

    switch(action.type) {
        case ReportUserActionType.INIT_LIST_USER:
            return {...state, listUser: action.listUser, listAccFull: action.listAccFull , extraCostInfo: action.extraCostInfo,errors:  {}, reload: false, editStatus: false}
        case ReportUserActionType.INIT_LIST_ACCOUNT:
            let listAccount = action.listAccount
            // Thay đổi: listAccount Rỗng hay có đều thêm extraCostItem

            let extraCostItem = {
                acc_group_name: "",
                acc_name: "",
                banker_id: "",
                banker_name: "",
                book_id: state.extraCostInfo.id,
                book_name: state.extraCostInfo.book_name,
                credit_wl: "",
                note_cycle: "",
                sub_code: "",
                sub_pass: "",
                sub_user: "",
            }
            console.log('extraCostItem--', extraCostItem)
            listAccount.unshift(extraCostItem)
            return {...state, listAccount: listAccount, errors:  {}, reload: false, editStatus: false, isEditReport: false}
        case ReportUserActionType.CHANGE_DAY:
            return {...state, selectedDay: action.selectedDay, isSelectDay: true}
        case ReportUserActionType.RESET_SELECT_DAY:
            return {...state, isSelectDay: false}
        case ReportUserActionType.CHANGE_DAY_SEARCH:
            return {...state, selectedDaySearch: action.selectedDaySearch, isSelectDaySearch: true}
        case ReportUserActionType.RESET_SELECT_DAY_SEARCH:
            return {...state, isSelectDaySearch: false}

        case ReportUserActionType.SAVE_REPORT_SUCCESS:
            return {...state, saveStatus: action.saveStatus, selectedItem: {id: null}, reload: action.saveStatus, listAccount: [], selectedDay: {}, isEditReport: false }
        case ReportUserActionType.SAVE_REPORT_FAIL:
            return {...state, errors: action.payload};

        case ReportUserActionType.GET_REPORT_SUCCESS:
            return {...state, listReport: action.payload , reload: action.saveStatus,}
        case ReportUserActionType.GET_REPORT_FAIL:
            return {...state, errors: action.payload};

        case ReportUserActionType.GET_REPORT_TOTAL_SUCCESS:
            let listReportTotal = get(action.payload, 'res.data.List', {})
            let listBook = get(action.payload, 'res.data.Book', {})
            let optBook = listBook.map(item => ({ value: item.id, label: item.book_name.toUpperCase() }))
            return {...state, listReportTotal: listReportTotal , optBook: optBook, reload: action.saveStatus,}
        case ReportUserActionType.GET_REPORT_TOTAL_FAIL:
            return {...state, errors: action.payload};

        case ReportUserActionType.EDIT_REPORT:
            return {...state, selectedItem: action.selectedItem, isEditReport: action.isEditReport , listAccount: []};

        case ReportUserActionType.DELETE_REPORT_SUCCESS:
            return {...state, deleteStatus: action.saveStatus, reload: action.deleteStatus}
        case ReportUserActionType.DELETE_REPORT_FAIL:
            return {...state, errors: action.payload};

        case ReportUserActionType.CHANGE_EDIT_ITEM:
            console.log('action----', action)
            return {...state, selectedItem: action.payload.updateItem};
        case ReportUserActionType.ADD_EXTRA_ITEM:
            let listAccUpdate = [...state.listAccount]
            let extraItem = {
                acc_group_name: "",
                acc_name: "",
                banker_id: "",
                banker_name: "",
                book_id: state.extraCostInfo.id,
                book_name: state.extraCostInfo.book_name,
                credit_wl: "",
                note_cycle: "",
                sub_code: "",
                sub_pass: "",
                sub_user: "",
            }
            listAccUpdate.unshift(extraItem)
            return {...state, listAccount: listAccUpdate};

        case ReportUserActionType.CHANGE_INPUT:
            let index =  action.payload.index
            let list =  action.payload.list
            let newlist = [...state.listAccount]
            newlist[index] = list
            let isChangeInput = false
            let total_credit_wl = 0
            newlist.forEach(item => {
                total_credit_wl +=  parseFloat(item.credit_wl.replace(/,/g,'')) || 0
                if ( !isChangeInput && parseFloat(item.credit_wl.replace(/,/g,''))) {
                    isChangeInput = true
                }
            })
            return { ...state,  listAccount : newlist, total_credit_wl: total_credit_wl, isChangeInput: isChangeInput};

        default:
            return {...state};
    }

}

export default ReportUserReducer
