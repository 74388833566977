import { get } from 'lodash';

import { UserImageActionType,  GroupUserActionType} from 'my-constants/action-types';
import { UserImageService, GroupUserService } from 'my-services/manage';
import _get from "lodash/get";
import {toast} from "react-toastify";

export const initGroupUser = () => {
    return dispatch => {
        return GroupUserService.getGroupUser()
            .then(res => {
                if(res.status) {
                    const listGroupUser = res.res.data.List.map(item => ({value: item.id, label: item.group_name.toUpperCase()}))
                    // listGroupUser.unshift({
                    //     value: null,
                    //     label: 'None'
                    // })
                    // const listUser = res.res.data.List
                    //Dispatch data to reducer
                    dispatch({
                        type: GroupUserActionType.INIT_GROUP_USER,
                        listGroupUser: listGroupUser

                    })
                }
            })
    }
}

export const initUsers = (params) => {
    return (dispatch) => {

        UserImageService.getUser(params).then(res => {
            if (res.status) {
                const optUsers = res.res.data.ListUser.map(item => ({ value: item.id, label: item.username.toUpperCase() }))

                // Dispatch data to reducer
                dispatch({
                    type: UserImageActionType.INIT_USERS_SUCCESS,
                    optUsers: optUsers
                });
            }
        })
    }
}

export const saveUser = (post) => {
    return dispatch => {
        return UserImageService.saveUser(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: UserImageActionType.SAVE_USER_SUCCESS,
                        saveStatus: res.status
                    })
                    toast.success("SUCCESS !!!");


                } else {
                    dispatch({
                        type: UserImageActionType.SAVE_USER_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: UserImageActionType.SAVE_USER_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const getUser = (post) => {
    return dispatch => {
        return UserImageService.getUser(post)
            .then(res => {
                if (res.status) {
                    return dispatch({
                        type: UserImageActionType.GET_USERS_SUCCESS,
                        payload: res,
                    });
                } else {
                    dispatch({
                        type: UserImageActionType.GET_USERS_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        },
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: UserImageActionType.GET_USERS_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};

export const deleteUser = (post) => {
    return dispatch => {
        return UserImageService.deleteUser(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: UserImageActionType.DELETE_USER_SUCCESS,
                        deleteStatus: res.status,
                    });
                    toast.success("SUCCESS !!!");
                } else {
                    dispatch({
                        type: UserImageActionType.DELETE_USER_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch(e => {
                dispatch({
                    type: UserImageActionType.DELETE_USER_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};

export const editUser = (post) => {
    return dispatch => {
        dispatch({
            type: UserImageActionType.EDIT_USER_SELECTED,
            selectedItem: post.selectedItem
        });
    }
};

export const resetUser = (post) => {
    return dispatch => {
        dispatch({
            type: UserImageActionType.RESET_USER_SELECTED,
            selectedItem: post.selectedItem
        });
    }
};

export const backToCreate = () => {
    return dispatch => {
        dispatch({
            type: UserImageActionType.BACK_TO_CREATE,
        });
    }
};

export const toggleModalUser = (params) => {
    return (dispatch) => {
        dispatch({
            type: UserImageActionType.USER_TOGGLE_MODAL_FORM,
            selectedItem: _get(params, 'selectedItem', {}),
            editStatus: _get(params, 'editStatus', {})
        })
    }
};

export const toggleModalDeleteUser = (params) => {

    return (dispatch) => {
        dispatch({
            type: UserImageActionType.USER_TOGGLE_MODAL_FORM_CONFIRM_DELETE,
            selectedItem: _get(params, 'selectedItem', {}),
            isOpenModalDelete: _get(params, 'isOpenModalDelete', {}),
        })
    }
};
