import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form';
import { CookieService } from 'my-utils/core';
import $ from "jquery";
import {get as _get} from 'lodash';
import { changeLanguage } from 'my-actions/systems/LanguageAction';

import { login } from 'my-actions/systems/AuthAction';
import { RoutesService } from 'my-routes';
import { AppConfig } from "my-constants"
import { TransComponent } from 'my-components'

class FormLoginContainer extends React.Component {
    constructor(props) {
        super(props);
        // this.props.initialize({ ...(this.props.initialValues || {}), lang_code: AppConfig.DEFAULT_LANG });
        this.state = {
            lang_code: AppConfig.DEFAULT_LANG
        }

        this.props.initialize({ ...(this.props.initialValues || {}), lang_code: AppConfig.DEFAULT_LANG });
    }

    handleSubmit = e => {
        this.props.login(this.props.initialValues);
        e.preventDefault();
    };

    handleKeyDown = e => {
        if (e.key === 'Enter' && e.shiftKey === false) {
            return this.handleSubmit(e);
        }
    };

    handleChangeLanguage = (e, newValue) => {
        this.setState({lange_code: newValue}, () => {
            this.props.changeLanguage(newValue)
        })

    };

    render() {
        var password = _get(this.props.initialValues, 'password');
        var username = _get(this.props.initialValues, 'username');
        var onSubmit = false;
        if(password && username){
            onSubmit = true
        }

        const { auth } = this.props;
        if (Number(CookieService.get('isLogin')) && auth.login_status) {
            $('div.alert').fadeIn();

            const redirect = CookieService.get('isReset') === '0'
                ? RoutesService.getPath('ADMIN', 'AUTH_LOGIN', { type: 'reset' })
                        : RoutesService.getPath('ADMIN', 'DASHBOARD');

            if (redirect.indexOf('dashboard') !== -1) {
                CookieService.set('byPassDashboard', '1');
                window.location.href = redirect;

                return null;
            }

            return  <Redirect to={redirect} />
        } else if (auth.login_status === false && _get(this.props, 'auth.errors.error_description', null)) {
            $('div.alert').fadeIn();
        }

        return (
            <div className="login" style={{ backgroundColor : 'transparent' }}>
                <div className="content">
                    <div className="text-center">
                        <img src="/assets/images/vscan.jpg" alt="logo win4all"  style={{maxWidth:"250px"}}/>
                    </div>

                    <form className="login-form" onSubmit={this.handleSubmit} onKeyDown={e => this.handleKeyDown(e)}>
                        <h3 className="form-title font-red">Services</h3>
                        <div className="alert alert-danger display-hide">
                            <button className="close" data-close="alert" />
                            <span> <TransComponent i18nKey={this.props.auth.errors.error_description} /> </span>
                        </div>
                        <div className="form-group">
                            <label className="control-label visible-ie8 visible-ie9"><TransComponent i18nKey="Username" /></label>
                            <Field
                                name="username"
                                type="text"
                                component="input"
                                className="form-control form-control-solid placeholder-no-fix"
                                autoComplete="off"
                                placeholder={this.props.t("Username")}
                            />
                        </div>
                        <div className="form-group">
                            <label className="control-label visible-ie8 visible-ie9"><TransComponent i18nKey="Password" /></label>
                            <Field
                                name="password"
                                type="password"
                                component="input"
                                className="form-control form-control-solid placeholder-no-fix"
                                autoComplete="off"
                                placeholder={this.props.t("Password")}
                            />
                        </div>
                        {/*<div className="form-group">*/}
                            {/*<Field name="lang_code" component="select" className="form-control" onChange={this.handleChangeLanguage}>*/}
                                {/*<option value="en">English</option>*/}
                                {/*<option value="vi">Tiếng Việt</option>*/}
                            {/*</Field>*/}
                        {/*</div>*/}
                        <div className="form-actions text-center">
                            <button
                                type="submit"
                                className="btn red uppercase"
                                disabled={!onSubmit || _get(this.props, 'auth.isFetching', false)}>
                                <TransComponent i18nKey="Login" />&nbsp;
                                { _get(this.props, 'auth.isFetching', false) ? <i className="fa fa-spinner fa-spin" /> : null }
                            </button>
                        </div>
                        <a className="pointer text-danger "  href="https://vscan.cc"  target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-home"  ></i>
                                <span><TransComponent i18nKey="Mainpage" /></span>
                        </a>
                    </form>
                </div>
                <div className="copyright"> 2023 © Vscan Application </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    /** Auto pass query value "username" from url to form login */
    const params = new URLSearchParams(window.location.search);

    return {
        initialValues: _get(state, 'form.form_login.values', {username: params.get('user')}),
        auth : state.AuthReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: params => {dispatch(changeLanguage(params))},
        login: params => {dispatch(login(params))}
    }
};

export default compose(
    reduxForm({form: 'form_login'}),
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
)(FormLoginContainer);
