import { AppConfig } from 'my-constants';
import { BaseService, HttpService, HttpServiceMultipart } from 'my-utils/core';

class ServersService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get servers list
    |--------------------------------------------------------------------------
    */
    getServers(post){
        console.log('posting data...', post);
        return HttpService.post(`${this.serviceUrl}/servers/get`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save server
    |--------------------------------------------------------------------------
    */
    saveServer(post){
        return HttpServiceMultipart.post(`${this.serviceUrl}/servers/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete server
    |--------------------------------------------------------------------------
    */
    deleteServer(post){
        return HttpService.post(`${this.serviceUrl}/servers/delete`, post);
    };

    /*
   |--------------------------------------------------------------------------
   | @content: Do action on servers
   |--------------------------------------------------------------------------
   */
    doAction(post){
        return HttpService.post(`${this.serviceUrl}/servers/do_action`, post);
    };
}

export default new ServersService()