import { TrickConfigActionType } from 'my-constants/action-types';

import { get } from 'lodash';
import moment from "moment/moment";

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    listTrick: [],
}

const TrickConfigReducer = (state = defaultState, action) => {
    switch(action.type) {
        case TrickConfigActionType.GET_TRICK_SUCCESS:
            console.log('action.payload---', action.payload)
            return {...state, listTrick: get(action.payload, 'res.data.List', {}), errors: {}, reload: false}
        case TrickConfigActionType.INIT_LIST_TRICK:
            return {...state, listTrickInit: action.listTrickInit}

        case TrickConfigActionType.SAVE_TRICK_SUCCESS:
            return {...state, saveStatus: action.saveStatus, reload: action.saveStatus , selectedItem: {id: null}}
        case TrickConfigActionType.SAVE_TRICK_FAIL:
            return {...state, errors: action.payload};

        case TrickConfigActionType.DELETE_TRICK_SUCCESS:
            return {...state, deleteStatus: action.deleteStatus, reload: action.deleteStatus }
        case TrickConfigActionType.DELETE_TRICK_FAIL:
            return {...state, errors: action.payload};

        case TrickConfigActionType.EDIT_TRICK:
            console.log('action-edit--', action)
            return {...state, selectedItem: action.selectedItem}

        default:
            return {...state};
    }

}

export default TrickConfigReducer
