import { get } from 'lodash'

import { PolicyActionType } from 'my-constants/action-types';
import { PolicyService } from 'my-services/policy';
import {toast} from "react-toastify";


export const saveImage = (post) => {
    return dispatch => {
        return PolicyService.saveImage(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: PolicyActionType.SAVE_IMAGE_SUCCESS,
                        saveStatus: res.status
                    })
                    toast.success("SUCCESS !!!");

                } else {
                    dispatch({
                        type: PolicyActionType.SAVE_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: PolicyActionType.SAVE_IMAGE_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const deleteImage = (post) => {
    return dispatch => {
        return PolicyService.deleteImage(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: PolicyActionType.DELETE_IMAGE_SUCCESS,
                        deleteStatus: res.status
                    })
                    toast.success("SUCCESS !!!");
                } else {
                    dispatch({
                        type: PolicyActionType.DELETE_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: PolicyActionType.DELETE_IMAGE_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const getLisPolicy = () => {
    return dispatch => {
        return PolicyService.getList()
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: PolicyActionType.GET_LIST_POLICY_SUCCESS,
                        payload: res
                    })
                } else {
                    dispatch({
                        type: PolicyActionType.GET_LIST_POLICY_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                }

            })
            .catch( e => {
                dispatch({
                    type: PolicyActionType.GET_LIST_POLICY_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                })
            })
    }
}


