import React, { Component } from 'react';
import {TransComponent} from 'my-components'
import { PanelAlert, OverviewContainer, AgentOP6Container } from 'my-containers/dashboard'
import { CookieService } from 'my-utils/core';
import { RolesConfig} from 'my-constants'

class DashboardPage extends Component {
    
    render() {
        // const status = CookieService.get("status");
        let isAgentOP6 = CookieService.get("roles") == RolesConfig.AGENTOP6
        let isAdminOP6 = CookieService.get("roles") == RolesConfig.ADMINOP6
        return (
            <div className='portlet light'>
                <div className="portlet-title">
                    <div className="caption font-red-sunglo">
                        <span className="caption-subject bold uppercase">
                            <TransComponent i18nKey="Dashboard" />
                        </span>
                    </div>
                </div>
                <div className="portlet-body">
                    {(!isAdminOP6 && !isAgentOP6) && <OverviewContainer />}
                    {
                        CookieService.get("roles") == RolesConfig.AGENT && <PanelAlert />
                    }
                    {isAgentOP6 && <AgentOP6Container/>}
                </div>
            </div>
        );
    }
}

export default DashboardPage