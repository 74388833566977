export const GET_LIST_REPORT_IMAGE = 'GET_LIST_REPORT_IMAGE'
export const GET_LIST_REPORT_IMAGE_SUCCESS = 'GET_LIST_REPORT_IMAGE_SUCCESS'
export const GET_LIST_REPORT_IMAGE_FAIL = 'GET_LIST_REPORT_IMAGE_FAIL'


export const GET_LIST_IMAGE_ACCOUNT_SUCCESS = 'GET_LIST_IMAGE_ACCOUNT_SUCCESS'
export const GET_LIST_IMAGE_ACCOUNT_FAIL = 'GET_LIST_IMAGE_ACCOUNT_FAIL'


export const TOGGLE_IMAGE = 'TOGGLE_IMAGE'
export const CLOSE_IMAGE = 'CLOSE_IMAGE'

export const TOGGLE_MODAL_REPORT = 'TOGGLE_MODAL_REPORT'
export const ADD_MORE_IMG = 'ADD_MORE_IMG'

export const SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS'
export const SAVE_IMAGE_FAIL = 'SAVE_IMAGE_FAIL'

export const RESET_SELECT_DAY_REPORT = 'RESET_SELECT_DAY_REPORT'
export const CHANGE_DAY_REPORT = 'CHANGE_DAY_REPORT'



