/*========INIT USERS OPTIONS========*/
export const INIT_USERS_SUCCESS   = 'INIT_USERS_SUCCESS';

/*========GET USERS LIST========*/
export const GET_USERS_SUCCESS   = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL      = 'GET_USERS_FAIL';

/*========INIT FORM USER ========*/
export const USER_INIT_USERS   = 'USER_INIT_USERS';
export const USER_INIT_USERS_SUCCESS      = 'USER_INIT_USERS_SUCCESS';

/*========SAVE USER ========*/
export const SAVE_USER_SUCCESS   = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAIL      = 'SAVE_USER_FAIL';

/*========DELETE USER ========*/
export const DELETE_USER_SUCCESS   = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL      = 'DELETE_USER_FAIL';

/*========EDIT USER ========*/
export const EDIT_USER_SELECTED   = 'EDIT_USER_SELECTED';

/*========EDIT USER ========*/
export const RESET_USER_SELECTED   = 'RESET_USER_SELECTED';

export const BACK_TO_CREATE   = 'BACK_TO_CREATE';

export const USER_TOGGLE_MODAL_FORM   = 'USER_TOGGLE_MODAL_FORM';

export const USER_TOGGLE_MODAL_FORM_CONFIRM_DELETE   = 'USER_TOGGLE_MODAL_FORM_CONFIRM_DELETE';

