import React,{ Component } from 'react';

import { TransComponent } from 'my-components'
import { ListUserContainer } from 'my-containers/manage/user_image'

class ListUserPage extends Component {
    render() {
        return (
            <div className="portlet light bordered">
                <div className="portlet-title">
                    <div className="caption font-red-sunglo bold uppercase font-size-15"><TransComponent i18nKey="User" /></div>
                </div>
                <div className="portlet-body">
                    <ListUserContainer/>
                </div>
            </div>
        );
    }
}

export default ListUserPage;