import { AccountGroupActionType } from 'my-constants/action-types';

import { get } from 'lodash';

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    lstAccountGroup: [],
};

const AccountGroupReducer = (state = defaultState, action) => {
    switch(action.type){
        case AccountGroupActionType.GET_ACCOUNT_GROUPS_SUCCESS:
            return {...state, lstAccountGroup: get(action.payload, 'res.data.List', {}), errors: {}, reload: false};
        case AccountGroupActionType.GET_ACCOUNT_GROUPS_FAIL:
            return {...state, errors: action.payload};

        case AccountGroupActionType.ACCOUNT_GROUP_INIT_GROUPS_SUCCESS:
            return {...state, optGroups: action.optGroups, errors: {}, reload: false};

        case AccountGroupActionType.SAVE_ACCOUNT_GROUP_SUCCESS:
            return {...state, projectName: null, saveStatus: action.saveStatus, reload: action.saveStatus}
        case AccountGroupActionType.SAVE_ACCOUNT_GROUP_FAIL:
            return {...state, errors: action.payload};

        case AccountGroupActionType.DELETE_ACCOUNT_GROUP_SUCCESS:
            return {...state, deleteStatus: action.deleteStatus, reload: action.deleteStatus}
        case AccountGroupActionType.DELETE_ACCOUNT_GROUP_FAIL:
            return {...state, errors: action.payload};

        case AccountGroupActionType.EDIT_ACCOUNT_GROUP_SELECTED:
            return {...state, selectedItem: action.selectedItem}

        default:
            return {...state};
    }
};

export default AccountGroupReducer;