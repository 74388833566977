import { ManageImageActionType } from 'my-constants/action-types';

import { get, sortBy } from 'lodash';

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    listUserImage: [],
    listUserImageOP6: [],
    editStatus: false,
    clearInput: false,
    disabledEdit: false,
    reload: false
}

const ManageImageReducer = (state = defaultState, action) => {
    switch(action.type) {
        case ManageImageActionType.INIT_LIST_USER_IMAGE:
            let listUser = sortBy(action.listUser, 'created').reverse()
            let listUserOP6 = sortBy(action.listUserOP6, 'created').reverse()
            return {...state, listUser: listUser, listUserOP6: listUserOP6,  listAcc: action.listAcc , listTrick: action.listTrick, errors:  {}, reload: false, editStatus: false}
        case ManageImageActionType.INIT_LIST_ACCOUNT_IMAGE:
            return {...state, listAccount: action.listAccount, errors:  {}, reload: false, editStatus: false}
        case ManageImageActionType.GET_LIST_USER_IMAGE_SUCCESS:
            return {...state, listUserImage: get(action.payload, 'res.data.ListUserImage', []), listUserImageOP6: get(action.payload, 'res.data.ListUserImageOP6', []), errors: {}, reload: false, editStatus: false}
        case ManageImageActionType.GET_LIST_USER_IMAGE_FAIL:
            return {...state, errors: action.payload};

        case ManageImageActionType.SAVE_IMAGE_SUCCESS:
            //addMoreItem
            return {...state, saveStatus: action.saveStatus, selectedItem: {id: null}, addMoreItem: {id: null}, reload: action.saveStatus, editStatus: false, selectedDay: {}}
        case ManageImageActionType.SAVE_IMAGE_FAIL:
            return {...state, errors: action.payload};

        case ManageImageActionType.DELETE_IMAGE_SUCCESS:
            return {...state, deleteStatus: action.saveStatus, reload: action.deleteStatus}
        case ManageImageActionType.DELETE_IMAGE_FAIL:
            return {...state, errors: action.payload};


        default:
            return {...state};
    }

}

export default ManageImageReducer
