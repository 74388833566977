import $ from 'jquery';

//Show global loading
export function showLoading() {
    $('.wrap-loader').show();
}

//Hide global loading
export function hideLoading(delay = 200) {
    $('.wrap-loader').delay(delay).hide(0);
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function formatInputWithCommas(number, decimals, dec_point, thousands_sep) {
    // var n = !isFinite(+number) ? 0 : +number,
    if(number == undefined) return
    var n = number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        // s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
        s = (prec ? toFixedFix(n, prec) : n).toString().split('.');
    // s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString();
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    // if ((s[1] || '').length < prec) {
    //     s[1] = s[1] || '';
    //     s[1] += new Array(prec - s[1].length + 1).join('0');
    // }
    var rs = s.join(dec)
    // var rs = s
    // if (number.toString()[number.length - 1] == '.') {
    //     return rs + '.'
    // }
    // if (number.toString()[0] == '-') {
    //     return rs + '-'
    // }
    return rs
}



export function formatFormulaName(formulaName) {
    try {
        return formulaName.replace(/^([^-]*-)/gi, "")
    } catch (e) {
        console.log(e)
    }
}

/*
|--------------------------------------------------------------------------
| Promise function
|--------------------------------------------------------------------------
*/
export function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

/*
|--------------------------------------------------------------------------
| Convert Form data
|--------------------------------------------------------------------------
*/
export function convertFormData(data) {
    // console.log('object---', object)
    // let result = new FormData()
    // Object.keys(object).forEach(key =>{
    //         if(Array.isArray(object[key])) {
    //             object[key].forEach(item => result.append(key, item) )
    //         }
    //         if(typeof object[key] === 'object') {
    //             console.log('object[key]11---', object[key])
    //             convertFormData(object[key])
    //             // object[key].forEach(item => result.append(key, item) )
    //         } else {
    //             result.append(key, object[key])
    //         }
    //         // console.log('key----', key)
    //         // console.log('object[key]----', object[key])
    // })
    // return result
    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object') {
            Object.keys(data).forEach(key => {
                if(Array.isArray(data[key])) {
                    data[key].forEach(item => formData.append(key, item) )
                }
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}

// export function convertFormData2(object) {
//     let result = new FormData()
//     Object.keys(object).forEach(key =>
//         result.append(key, object[key])
//     )
//     return result
// }