import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class ProjectsService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Projects list
    |--------------------------------------------------------------------------
    */
    getProjects(){
        return HttpService.post(`${this.serviceUrl}/projects/get`);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveProject(post){
        return HttpService.post(`${this.serviceUrl}/projects/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteProject(post){
        return HttpService.post(`${this.serviceUrl}/projects/delete`, post);
    };
}

export default new ProjectsService()