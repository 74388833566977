/*========INIT USERS OPTIONS========*/
export const INIT_LIST_TRICK = 'INIT_LIST_TRICK'
/*========GET USERS LIST========*/
export const GET_TRICK_SUCCESS   = 'GET_TRICK_SUCCESS';
// export const GET_USERS_FAIL      = 'GET_USERS_FAIL';

/*========SAVE USER ========*/
export const SAVE_TRICK_SUCCESS   = 'SAVE_TRICK_SUCCESS';
export const SAVE_TRICK_FAIL      = 'SAVE_TRICK_FAIL';

/*========DELETE USER ========*/
export const DELETE_TRICK_SUCCESS   = 'DELETE_TRICK_SUCCESS';
export const DELETE_TRICK_FAIL      = 'DELETE_TRICK_FAIL';

/*========EDIT TRICK ========*/
export const EDIT_TRICK   = 'EDIT_TRICK';



