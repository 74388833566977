import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { TransComponent } from 'my-components';
import { ButtonCheckLoginContainer, ButtonMoveToScanIPContainer } from 'my-containers/account';
import { toggleModalAccount, toggleImage, closeImage } from 'my-actions/AccountAction';
import { CookieService } from 'my-utils/core';
import { RolesConfig} from 'my-constants'

class AccountItemContainer extends Component {
    static propTypes = {
        account: PropTypes.object,
        isCheckLogin: PropTypes.bool,
        isMoveToScanIP: PropTypes.bool,
        onChangeProps: PropTypes.func,
    };

    static defaultProps = {
        account: {},
        isCheckLogin: false,
        isMoveToScanIP: false,
        onChangeProps: () => void(0),
    };

    state = {
        tooltipOpen: false,
        toggleIds: {},
        isOpenModalDelete: false,
        roles: CookieService.get("roles")
    };

    toggleChildAccount = id => () => {
        const toggleIds = this.state.toggleIds;

        toggleIds[id] = !toggleIds[id];

        this.setState({ toggleIds });
    };

    handleOpenDeleteModal = (id, name) => () => this.props.onChangeProps({ deleteState: { id, name, isOpenModal: true } });

    handleOpenUpdateModal = selectedItem => () => this.props.toggleModalAccount({ selectedItem });

    handleOpenImage = selectedItem => () => this.props.toggleImage({ selectedItem, visibleImage: true });

    renderTR(item) {
        const toggleIds = this.state.toggleIds;
        const isOpen = toggleIds[item.id] !== undefined
            ? toggleIds[item.id]
            : !!item.isOpen;
        const iconChildToggle = (item.child && item.child.length)
            ? <i className={`fa ${ isOpen ? 'fa-chevron-down' : 'fa-chevron-right' }`} />
            : null;
        const marginLeft = item.__level ? { marginLeft: `${15*item.__level}px`} : {};
        const toggleChildEvt = (item.child && item.child.length) ? { onClick: this.toggleChildAccount(item.id) } : {};
        const cursorClass = (item.child && item.child.length) ? 'cursor-pointer' : '';
        const status = ['waiting', 'confirm', 'scanip']
        const cls = ['text-center text-warning', 'text-center text-primary', 'text-center text-success']
        const is_admin = this.state.roles == RolesConfig.ADMIN || this.state.roles == RolesConfig.SALE || this.state.roles == RolesConfig.FINANCIAL
        const is_agent = this.state.roles == RolesConfig.AGENT
        return (
            <tr key={item.id}>
                <td>{!item.__level ?  item.__order : null}</td>
                {
                    is_admin ?
                        <td className="text-center">
                            { item.username }
                        </td> : null
                }
                <td><span className="text-uppercase">{ item.banker_name || '' }</span></td>
                <td className={"text-uppercase " + cursorClass} { ...toggleChildEvt }>
                    <span style={{ ...marginLeft }}>
                        <span style={{ marginLeft: `${10*item.__level}px` }}>{ item.__level ? item.__order : null }</span>
                        &nbsp;&nbsp;{ iconChildToggle }
                        &nbsp;{ item.acc_name }
                    </span>
                </td>
                <td>{ item.sub_user }</td>
                <td className="text-center">
                    { item.sub_pass }
                </td>
                <td className="text-center">
                    { item.sub_code }
                </td>
                {
                    is_admin ?
                        <td className="text-center">
                            <ButtonCheckLoginContainer isCheck={this.props.isCheckLogin} id={item.id} />
                        </td> : null
                }
                {
                    is_admin  ?
                        <td className="text-center">
                            <ButtonMoveToScanIPContainer isMove={this.props.isMoveToScanIP} item={item} />
                        </td> : null
                }
                <td className="text-center">
                    <span className="text-uppercase">{ item.acc_group_name }</span>
                </td>
                <td className="text-center">
                    <span className="text-uppercase">{ item.created }</span>
                </td>
                <td className="text-center">
                    <span className="text-uppercase">{ item.updated }</span>
                </td>
                {/*<td className="text-center">*/}
                    {/*{item.image_arr.length > 0 &&<i className="fa fa-info-circle font-green cursor-pointer" onClick={this.handleOpenImage(item)} />}*/}
                {/*</td>*/}
                <td className="text-center">
                    <span>{ item.note }</span>
                </td>
                <td className={cls[item.status]}>
                    <TransComponent i18nKey={(status[item.status] || '').toString()} />
                </td>
                <td className="text-center">
                    <i className="fa fa-edit font-green cursor-pointer" onClick={this.handleOpenUpdateModal(item)} />
                </td>
                {
                    is_agent ?
                        <td className="text-center">
                            <i onClick={this.handleOpenDeleteModal(item.id, item.acc_name)} className="fa fa-times-circle font-red cursor-pointer" />
                        </td> : null
                }
            </tr>
        );
    }

    renderRecursiveItem(item) {
        const toggleIds = this.state.toggleIds;

        const result = [];
        const loopItem = (itemLoop) => {
            result.push(this.renderTR(itemLoop));

            const isOpen = toggleIds[itemLoop.id] !== undefined
                ? toggleIds[itemLoop.id]
                : !!itemLoop.isOpen;

            if (itemLoop.child && itemLoop.child.length && isOpen) itemLoop.child.forEach(child => loopItem(child));
        };

        if (!_isEmpty(item)) loopItem(item);

        return result;
    }

    render() {
        console.log('aaaaaaaaaa')
        return this.renderRecursiveItem(this.props.account);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleModalAccount: params => dispatch(toggleModalAccount(params)),
        toggleImage: params => dispatch(toggleImage(params)),
        closeImage: () => dispatch(closeImage()),
    };
};

export default connect(null, mapDispatchToProps)(AccountItemContainer);
