import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
    debounce as _debounce,
    sortBy as _sortBy,
    cloneDeep as _cloneDeep,
    isEqual as _isEqual
} from 'lodash';

import { TransComponent, LoadingComponent } from 'my-components';
import { BookTabContentContainer, ModalByActionContainer } from 'my-containers/account';
import { getTab, getAccount, toggleModalAccount, closeImage } from 'my-actions/AccountAction';
import { CookieService } from 'my-utils/core';
import { RolesConfig} from 'my-constants'

import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'


class AccountListContainer extends Component {
    componentDidMount() {
        this.props.getTab();

        if (!this.props.lstAccount.length) {
            this.props.getAccount();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.formSaveStatus && !prevProps.formSaveStatus) {
            this.props.getAccount();
        }
    }

    state = {
        isOpenModal: false,
        keySearch: '',
        statusFilters : []
    };

    _mapLstAccount(lst, result = {}) {
        lst.forEach(item => {
            result[item.id] = item;

            if (item.child && item.child.length) return this._mapLstAccount(item.child, result);
        });

        return result;
    }

    filterAccountByBook(lstAccount, id) {
        const keySearch = this.state.keySearch.toLowerCase().trim();

        lstAccount = _cloneDeep(lstAccount);
        lstAccount = id === 'all' ? lstAccount : lstAccount.filter(account => account.book_id === id);

        if (keySearch) {
            lstAccount = lstAccount.filter( function findChild(item) {
                item.isOpen = true;

                if ((item.acc_name || '').toLowerCase().indexOf(keySearch) !== -1 ||
                    (item.sub_user || '').toLowerCase().indexOf(keySearch) !== -1 ||
                    (item.banker_name || '').toLowerCase().indexOf(keySearch) !== -1 ||
                    (item.username || '').toLowerCase().indexOf(keySearch) !== -1) {
                    item.isOpen = false;

                    return true;
                }

                if (item.child) return (item.child = item.child.filter(findChild)).length;

                return false;
            });
        }
        // else {
        //     if ( Number(CookieService.get("roles")) === RolesConfig.SALE ) {
        //         lstAccount = []
        //     }
        // }

        return lstAccount;
    }

    handleOpenCreateNewModal = () => this.props.toggleModalAccount();

    handleSearch = _debounce(key => {
        this.setState({ keySearch: key });
    }, 200);

    // handleExport = () => {
    //     console.log('lstAccount---', this.props.lstAccount)
    //
    // }

    handleExport = () => {
        let data_export = []
        let data =  this.props.lstAccount;
        data.filter(item => item.status == 2 && _isEqual(item.is_change_info, false) )
            .forEach(item => {
                console.log()
                const must = {}
                const myItem = []

                myItem['User'] = item.username;
                myItem['Banker'] = item.banker_name;
                myItem['Account'] = item.acc_name;
                myItem['Sub_user'] = item.sub_user ;
                myItem['Sub_pass'] = `"${item.sub_pass}"`;
                myItem['Sub_code'] = item.sub_code;
                myItem['Type'] = item.acc_group_name == 'thường' ? 'Thuong' :'Vip';
                myItem['Created'] = item.created;
                myItem['Updated'] = item.updated;
                myItem['Note'] = item.note;
                myItem['Status'] = item.status == 0 ? 'Waiting' : item.status == 1 ? 'Confirm' : 'Protected' ;

                const header = [ "User", "Banker", "Account", "Sub_user", "Sub_pass", "Sub_code", "Type", "Created", "Updated", "Note", "Status"];

                header.forEach(function (field) {
                    if (myItem[field]) {
                        must[field] = myItem[field];
                    }else {
                        must[field] = "";
                    }
                });

                data_export.push(must)
            })
        // data_export = _sortBy(data_export, 'User')
        console.log('data-export--', data_export)
        let result, ctr, keys, columnDelimiter, lineDelimiter;

        if (data_export == null || !data_export.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        keys = Object.keys(data_export[0]);

        for (let i of data_export) {
            const keyArr = Object.keys(i);

            if (keyArr.length > keys.length) {
                keys = keyArr;
            }
        }

        // for (let j of bankerResults) {
        //     const keyArr = Object.keys(j);
        //
        //     for (let key of keyArr) {
        //         if (keys.indexOf(key) < 0) {
        //             keys.splice(-4, 0, key);
        //         }
        //     }
        // }

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data_export.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;

                if (item[key]) {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        let blob = new Blob([result], {type: "text/csv;charset=utf-8;"});

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "export_vscan.csv")
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection, Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "export_vscan.csv");
                // link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }



    }

    renderTabContent() {
        let  { lstTab, lstAccount } = this.props;
        let { statusFilters } = this.state;


        lstAccount = _sortBy(lstAccount, ['banker_name', 'acc_name']);

        if(statusFilters.length > 0) {
            let filter_value = statusFilters.map(item => item.value)
            lstAccount = lstAccount.filter(item => filter_value.indexOf(item.status) != -1 || filter_value.indexOf(item.acc_group_name.toLowerCase()) != -1)
        }

        return (
            <>
                <BookTabContentContainer lstAccount={this.filterAccountByBook(lstAccount, 'all')} id="all" isActive={true} data={{}} />
                {
                    lstTab.map((item, idx) => (
                        <BookTabContentContainer lstAccount={this.filterAccountByBook(lstAccount, item.id)} key={idx} id={item.id} data={{}} />
                    ))
                }
            </>
        );
    }

    handleChangeFilter = (item) => {
        this.setState({statusFilters: item})
    }

    render() {
        const { t, lstTab, isFetchingAccount } = this.props;
        const statusArr = [{value: 0, label:'Đang chờ'}, {value: 1, label:'Xác nhận'}, {value: 2, label:'Bảo vệ'}, {value: 'vip', label:'VIP'}, {value: 'thường', label:'Thường'}]
        return (
            <div className="portlet box blue-hoki position-relative min-height-60">
                { isFetchingAccount ? <LoadingComponent /> : null }
                { Number(CookieService.get("roles")) === RolesConfig.AGENT ?
                    <button onClick={this.handleOpenCreateNewModal} className="btn btn-danger btn-add-formula"><TransComponent i18nKey="Add new" /></button> : null }
                {Number(CookieService.get("roles")) !== RolesConfig.AGENT &&
                <button onClick={this.handleExport} className="btn btn-danger btn-add-formula"><TransComponent i18nKey="Export CSV" /></button>}

                <div className="portlet-title tabbable-line padding-top-0">
                    <div className="caption">
                        <span className="caption-subject bold uppercase font-size-15"><TransComponent i18nKey="Account list" /></span>
                    </div>
                    <ul className="nav nav-tabs nav-tab-account">
                        <li className="text-capitalize active">
                            <a href="#all" data-toggle="tab"><strong><TransComponent i18nKey="All" /></strong></a>
                        </li>
                        {
                            lstTab.map((item) => (
                                <li key={item.id} className="text-capitalize">
                                    <a href={`#${item.id}`} data-toggle="tab"><strong>{item.book_name}</strong></a>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="float-right padding-tb-6 margin-right-10">
                        <div className="input-icon right">
                            <i className="fa fa-search" />
                            <input className="form-control" onChange={e => this.handleSearch(e.target.value)} placeholder={t('search')} type="text" />
                        </div>
                    </div>
                    <div className="float-right padding-tb-6 margin-right-10" style={{width: '200px'}}>
                        <Multiselect
                            placeholder={`${t('status')}/${t('type')}`}
                            data={statusArr}
                            value={this.state.statusFilter}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeFilter}
                        />
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="tab-content">
                        { this.renderTabContent() }
                    </div>
                </div>
                <ModalByActionContainer />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lstTab: state.AccountReducer.lstTab || [],
        lstAccount: state.AccountReducer.lstAccount || [],
        isFetchingAccount: state.AccountReducer.isFetchingAccount || false,
        formSaveStatus: state.AccountReducer.formSaveStatus || false,
        formDeleteStatus: state.AccountReducer.formDeleteStatus || false,
        imageListEdit: state.AccountReducer.imageListEdit,
        visibleImage: state.AccountReducer.visibleImage,
        closeImage: state.AccountReducer.closeImage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTab: () => dispatch(getTab()),
        getAccount: () => dispatch(getAccount()),
        toggleModalAccount: () => dispatch(toggleModalAccount()),
        closeImage: () => dispatch(closeImage()),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(AccountListContainer);
