import React, { Component } from 'react'

import { TransComponent } from 'my-components'
import { ReportUserContainer } from 'my-containers/report_user'

export default class ReportPage extends Component {
    render() {
        return (
            <div className="portlet light bordered">
                <div className="portlet-body">
                    <ReportUserContainer />
                </div>
            </div>
        )
    }
}
