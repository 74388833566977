import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { TransComponent } from 'my-components'
import { getUser, deleteUser, editUser, resetUser, toggleModalUser, toggleModalDeleteUser } from 'my-actions/manage/UserImageAction';
import { ModalByActionContainer } from 'my-containers/manage/user_image';
import ReactPaginate from 'react-paginate';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
import PropTypes from "prop-types";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'
import {debounce as _deBounce} from 'lodash'
import { Helpers } from 'my-utils';
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { GetQRCode } from "my-components/manage/user_image";

const statusList = [<TransComponent i18nKey='Offline' />, <TransComponent i18nKey='Online' />]

class ListUserContaner extends Component {
    static propTypes = {
        onChangeProps: PropTypes.func,
    };
    static defaultProps = {
        onChangeProps: () => void(0),
    };

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            offset: 0,
            data: [],
            perPage: 20,
            currentPage: 0,
            statusFilter: [],
            keySearch : ''
        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        this.props.getUser();
    }

    componentDidUpdate() {
        // refresh data

        if(this.props.reload) {
            this.props.getUser();

        }
    }

    handleFilterUser = e => {
        const { value } = e.target;
        if(!value) {
            this.setState({ offset: 0, currentPage: 0})
        }
        this.setState({filter: value, currentPage: 0, offset: 0})


    }
    handleFilterGroup = e => {
        const { value } = e.target;
        this.setState({filter: value, currentPage: 0})
    }


    handleEditUser = item => {
        const params = {
            selectedItem: item.selectedItem,
        }
        this.props.editUser(params)
    }

    handleOpenUpdateModal = selectedItem => () => this.props.toggleModalUser({ selectedItem });

    handleOpenDeleteModal = (selectedItem) => () => this.props.toggleModalDeleteUser({selectedItem, isOpenModalDelete: true});

    handleOpenCreateNewModal = () => this.props.toggleModalUser({editStatus: false});

    handleResetUser = item => {
        const params = {
            selectedItem: item.selectedItem,
        }
        // console.log('Params Edit == ', params)
        this.props.resetUser(params)
    }

    handleDeleteUser = item => {
        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteUser(params)
    }

    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    handleChangeFilter = (item) => {
        this.setState({statusFilter: item})
    }

    handleSearch = _deBounce( key => {
        this.setState({keySearch: key})
    }, 200)


    handleExportCsv = () => {
        let data_export = []
        let data =  this.props.listUser;
        data.forEach(item => {
            const must = {}
            const myItem = []

            myItem['Username'] = item.username;
            myItem['Fullname'] = item.fullname;
            myItem['Credit'] = item.credit;
            myItem['Status'] = item.status == 1 ? 'On' : 'Off';
            myItem['Limit_subs'] = item.limit_subs;
            myItem['Day_created'] = item.day_created || "0";
            myItem['Ott'] = item.ott ;
            myItem['Phone'] = item.phone;
            myItem['Address'] = item.address;

            const header = [ "Username", "Fullname", "Credit", "Status", "Limit_subs", "Day_created", "Ott", "Phone", "Address"];

            header.forEach(function (field) {
                if (myItem[field]) {
                    must[field] = myItem[field];
                }else {
                    must[field] = "";
                }
            });

            data_export.push(must)
        })
        // data_export = _sortBy(data_export, 'User')
        console.log('data_export---', data_export)
        let result, ctr, keys, columnDelimiter, lineDelimiter;

        if (data_export == null || !data_export.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        keys = Object.keys(data_export[0]);

        for (let i of data_export) {
            const keyArr = Object.keys(i);

            if (keyArr.length > keys.length) {
                keys = keyArr;
            }
        }

        // for (let j of bankerResults) {
        //     const keyArr = Object.keys(j);
        //
        //     for (let key of keyArr) {
        //         if (keys.indexOf(key) < 0) {
        //             keys.splice(-4, 0, key);
        //         }
        //     }
        // }

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data_export.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;

                if (item[key]) {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        let blob = new Blob([result], {type: "text/csv;charset=utf-8;"});

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "export_user.csv")
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection, Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "export_user.csv");
                // link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }



    }

    onCopyLinkSuccess = () => {
        toast.success("Đã copy link")
    }

    render() {
        let roles = Number(CookieService.get('roles'))
        const isAdminOP6 = Number(CookieService.get('roles')) == RolesConfig.ADMINOP6
        let {listUser, optUsers} = this.props
        let {statusFilter, keySearch} = this.state

        const statusValue = [{value:1 , label: 'On'}, {value:0 , label: 'Off'}]
        //filter Multiselect
        if(statusFilter.length > 0) {
            let filter_value = statusFilter.map(item => item.value)
            listUser = listUser.filter(item => filter_value.indexOf(item.status) > -1)
        }

        if(keySearch) {
            //fullname
            // listUser = listUser.filter(item => item.username.indexOf(keySearch) > -1 || item.fullname.indexOf(keySearch) > -1)
            listUser = listUser.filter(item => {
                if(item.username.toLowerCase().indexOf(keySearch.toLowerCase().trim()) > -1 ||
                    item.fullname.toLowerCase().trim().indexOf(keySearch.toLowerCase().trim()) > -1 ) {
                    return true
                }
                return false
            })
        }

        let pageCount = Math.ceil(listUser.length / this.state.perPage)
        listUser  = listUser.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        return (
            <div className="portlet box blue-hoki position-relative min-height-60">
                <button onClick={this.handleOpenCreateNewModal} className="btn btn-danger btn-add-formula"><TransComponent i18nKey="Add new" /></button>
                {!isAdminOP6 && <button onClick={this.handleExportCsv} className="btn btn-danger btn-add-formula-2"><TransComponent i18nKey="Export CSV" /></button>}

                <div className="portlet-title tabbable-line padding-top-0">
                    <div className="caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="List Users" /></span>
                    </div>
                    <div className="float-right padding-tb-6 margin-right-10">
                        <div className="input-icon right">
                            <i className="fa fa-search" />
                            <input className="form-control" onChange={e => this.handleSearch(e.target.value)} placeholder={'Tìm kiếm'} type="text" />
                        </div>
                    </div>
                    <div className="float-right padding-tb-6 margin-right-10" style={{width: '200px'}}>
                        <Multiselect
                            placeholder={'Trạng Thái '}
                            data={statusValue}
                            value={statusFilter}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeFilter}
                        />
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr className="font-red-sunglo">
                                    <th>#</th>
                                    <th><TransComponent i18nKey="UserName"/></th>
                                    <th><TransComponent i18nKey="Agent"/></th>
                                    <th><TransComponent i18nKey="Ngày tạo"/></th>
                                    {!isAdminOP6 && <th><TransComponent i18nKey="Phone"/></th>}
                                    {!isAdminOP6 && <th><TransComponent i18nKey="Ott"/></th>}
                                    {!isAdminOP6 && <th><TransComponent i18nKey="Address"/></th>}
                                    {!isAdminOP6 && <th><TransComponent i18nKey="Credit"/></th>}
                                    <th><TransComponent i18nKey="Status"/></th>
                                    {!isAdminOP6 && <th><TransComponent i18nKey="Litmit Subs"/></th>}
                                    {!isAdminOP6 && <th><TransComponent i18nKey="Note"/></th>}
                                    {isAdminOP6 && <th><TransComponent i18nKey="LifeTime(h)"/></th>}
                                    {isAdminOP6 && <th><TransComponent i18nKey="Available(h)"/></th>}
                                    {isAdminOP6 && <th>Copy Link</th>}
                                    {isAdminOP6 && <th>Copy QRCode</th>}
                                    <th><TransComponent i18nKey="Edit"/></th>
                                    <th><TransComponent i18nKey="Change password"/></th>
                                    {isAdminOP6 && <th>KTV tạo</th>}
                                    <th><TransComponent i18nKey="Delete"/></th>
                                    {/*{*/}
                                        {/*(roles === RolesConfig.SALE || roles === RolesConfig.FINANCIAL) &&*/}
                                           {/**/}
                                    {/*}*/}
                                    {/*{*/}
                                        {/*(roles === RolesConfig.SALE || roles === RolesConfig.FINANCIAL) &&*/}
                                            {/*<th><TransComponent i18nKey="Change password"/></th>*/}
                                    {/*}*/}
                                    {/*{*/}
                                        {/*(roles === RolesConfig.SALE || roles === RolesConfig.FINANCIAL) &&*/}
                                            {/*<th><TransComponent i18nKey="Delete" /></th>*/}

                                    {/*}*/}
                                </tr>
                            </thead>
                            <tbody>
                            {
                                listUser.length ?
                                    listUser.map( (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {this.state.currentPage * this.state.perPage + index + 1} </td>
                                                <td className="uppercase">{item.username}</td>
                                                <td className="uppercase">{item.fullname}</td>
                                                <td>{item.day_created}</td>
                                                {!isAdminOP6 && <td>{item.phone}</td>}
                                                {!isAdminOP6 && <td>{item.ott}</td>}
                                                {!isAdminOP6 && <td>{item.address}</td>}
                                                {!isAdminOP6 && <td>{Helpers.formatMoney(item.credit, Number.isInteger(+item.credit) ? 0 : 2)}</td>}
                                                <td>{statusList[item.status]}</td>
                                                {!isAdminOP6 && <td>{item.limit_subs}</td>}
                                                {!isAdminOP6 && <td>{item.note}</td>}
                                                {isAdminOP6 && <td>{item.time_to_live}</td>}
                                                {isAdminOP6 && <td>{item.life_time1}</td>}
                                                {isAdminOP6 && (
                                                    <td>
                                                        {/* Copy link button */}
                                                        <CopyToClipboard text={item.link} onCopy={this.onCopyLinkSuccess}>
                                                            <i className='fa fa-copy fa-2x' style={{cursor: 'pointer'}}/>
                                                        </CopyToClipboard>
                                                    </td>
                                                )}
                                                {isAdminOP6 && (
                                                    <td>
                                                        <GetQRCode username={item.username}/>
                                                    </td>
                                                )}
                                                <td>
                                                    <a href="javascript:void(0)" className="text-success btn btn-link" onClick={this.handleOpenUpdateModal(item)}>
                                                        <i className="fa fa-edit" />
                                                    </a>
                                                </td>

                                                <td>
                                                    <a href="javascript:void(0)" className="text-success btn btn-link" onClick={ () => this.handleResetUser({selectedItem:item})}>
                                                        <i className="fa fa-lock" />
                                                    </a>
                                                </td>

                                                {
                                                    isAdminOP6 && (
                                                        <td>{item.username_parent || ''}</td>
                                                    )
                                                }

                                                <td>
                                                    <button className="text-success btn btn-link font-red" onClick={this.handleOpenDeleteModal(item)}>
                                                        <i className="fa fa-close" />
                                                    </button>
                                                </td>

                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            </tbody>
                        </table>
                        {pageCount > 1 &&
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                        }

                    </div>
                </div>
                <ModalByActionContainer />
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                />
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        lstGroups: state.GroupsReducer.groups,
        reload: state.UserImageReducer.reload,
        listUser: state.UserImageReducer.listUser,
        listUserOP6: state.UserImageReducer.listUserOP6 ,
        optUsers: state.UserImageReducer.optUsers,
        optUsersOP6: state.UserImageReducer.optUsersOP6,
    };
};

const mapDispatchToProps = dispatch => {
    // console.log('Dispatch==', dispatch)
    return {
        getUser: (params) => dispatch(getUser(params)),
        deleteUser: (params) => dispatch(deleteUser(params)),
        editUser: (params) => dispatch(editUser(params)),
        resetUser: (params) => dispatch(resetUser(params)),
        toggleModalUser: (params) => dispatch(toggleModalUser(params)),
        toggleModalDeleteUser: (params) => dispatch(toggleModalDeleteUser(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListUserContaner);