import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import {  renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { saveUser, getUser, backToCreate } from 'my-actions/manage/UserImageAction';
import { saveImage } from 'my-actions/manage/ManageImageAction';
import { RoutesService } from 'my-routes';
import {Field, reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Helpers } from 'my-utils';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'

/** Import copy to clip board components */
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

const optUserStatus = [{value: 1, label: <TransComponent i18nKey='Online' />},
    {value: 0, label: <TransComponent i18nKey='Offline' />}]

class FormCreateUserContainer extends Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef();
        this.imageFile = null;
        this.imageFileName = null;
        this.state = {
            id : null,
            credit : null,
            imagePreviewUrl: '',
        }
    }


    componentWillMount() {
        this.props.initialize({...this.props.initialValues})
        // if(this.props.selectedItem) {
        //     window.location.href = RoutesService.getPath('ADMIN', 'CREATE_USER');
        // }


    }

    componentDidMount() {

    }


    componentWillReceiveProps (nextProps, prevProps) {
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            let selectedItem = nextProps.selectedItem
            const { status } = selectedItem;
            this.props.initialize({
                ...nextProps.selectedItem,
                credit:Helpers.formatInputWithCommas(nextProps.selectedItem.credit),
                status: optUserStatus.find(item => item.value === status),
                time: 360
            })

            this.setState(
                {
                    id: nextProps.selectedItem.id
                })

        }


        if(nextProps.initialValues.credit && this.state.credit !== nextProps.initialValues.credit) {
            this.props.initialize({
                ...nextProps.initialValues,
                credit: Helpers.formatInputWithCommas(nextProps.initialValues.credit.replace(/,/g,''), 0)
            })
            this.setState({credit: nextProps.initialValues.credit})
        }



    }

    componentDidUpdate() {

    }

    handleSubmitCreate = (e, isAdminOP6) => {
        e.preventDefault();
        let params = null
        let imgCreatedParams = null
        if(isAdminOP6) {
            params = {
                id: this.state.id,
                username: _get(this.props.initialValues, 'username'),
                password: _get(this.props.initialValues, 'password'),
                fullname: _get(this.props.initialValues, 'fullname'),
                status: _get(this.props.initialValues, 'status.value', 1),
                time: _get(this.props.initialValues, 'time', null),

            }
        } else {
            params = {
                id: this.state.id,
                username: _get(this.props.initialValues, 'username'),
                password: _get(this.props.initialValues, 'password'),
                fullname: _get(this.props.initialValues, 'fullname'),
                credit: _get(this.props.initialValues, 'credit') ? Number(_get(this.props.initialValues, 'credit').replace(/,/g,'')) : 0,
                phone: _get(this.props.initialValues, 'phone'),
                ott: _get(this.props.initialValues, 'ott'),
                address: _get(this.props.initialValues, 'address'),
                note: _get(this.props.initialValues, 'note'),
                status: _get(this.props.initialValues, 'status.value', 1),
                limit_subs: _get(this.props.initialValues, 'limit_subs', 20),

            }
        }

        console.log('handleSubmitUpdate===', params)
        if (this.props.editStatus) {
            this.props.saveUser(params)
        } else {
            console.log('handleSubmitCreate===', params)
            this.props.saveUser(params).then( () => {
                if(this.props.reload) {
                    this.props.getUser().then( (res) => {
                        const filteredData = res.payload.res.data.ListUser.filter(u => u.username.toLowerCase() === params.username.toLowerCase());
                        var createdUser;
                        if (filteredData.length > 0) {
                            createdUser = filteredData[0];
                        }

                        if (createdUser && isAdminOP6 && this.state.files) {
                            let img = this.state.files;
                            let img_arr = []
                            for (const key of Object.keys(img)) {
                                img_arr.push(img[key])
                            }
                            imgCreatedParams = {
                                user: {
                                    _id: createdUser.id,
                                    name: createdUser.username
                                },
                                imageFile: img_arr,
                            }

                            this.props.saveImage(Helpers.convertFormData( imgCreatedParams ))
                            try {
                                this.imageRef.current.value = null
                                this.setState({
                                    imagePreviewUrl: '',
                                })
                            } catch (e) {

                            }
                        }
                    });
                }
            })
        }

        this.props.initialize({
                credit: 0,
                time: 360,
            })
    }
    handleBackToCreate = () => {
        this.props.backToCreate()
        this.setState({
            id: null
        })

    }

    handleOnChangeFile = e => {
        e.preventDefault();

        this.setState({
            files: e.target.files
        })

        let fileReader = new FileReader();
        const image = this.imageRef.current.files[0];

        this.imageFile = image;
        this.imageFileName = image ? image.name : '';
        fileReader.onloadend = () => {
            this.setState({
                file: image,
                imagePreviewUrl: fileReader.result
            });
        }
        image ? fileReader.readAsDataURL(image) : this.setState({imagePreviewUrl: null})
    }

    handleClearInput = e => {
        if(this.props.editStatus) {
            this.setState({
                imagePreviewUrl: '',
            })
        }

    }

    handleSubmitSave = e => {
        const params = {
            id: this.state.id,
            fullname: _get(this.props.initialValues, 'fullname'),
            credit: Number(_get(this.props.initialValues, 'credit').replace(/,/g,'')),
            phone: _get(this.props.initialValues, 'phone'),
            ott: _get(this.props.initialValues, 'ott'),
            address: _get(this.props.initialValues, 'address'),
            status: _get(this.props.initialValues, 'status.value', ""),
            note: _get(this.props.initialValues, 'note'),
            limit_subs: _get(this.props.initialValues, 'limit_subs'),

        }
        this.setState({
            id: ''
        })
        this.props.saveUser(params)
        this.props.initialize({
            credit: 0,
        })



        e.preventDefault();
    }

    onCopyPasswordSuccess = (e) => {
        toast.success("Đã copy mật khẩu")
    }

    render() {
        let resetPassStatus = this.props.resetPassStatus
        let editStatus = this.props.editStatus
        let hasId = this.state.id ?  true : false
        let {imagePreviewUrl} = this.state;
        const isAminOP6 = Number(CookieService.get('roles')) == RolesConfig.ADMINOP6
        let imageShow = null;
        if (imagePreviewUrl) {
            imageShow = (<img src={imagePreviewUrl} width='100%' height='250px' alt={"image1"}/>);
        } else {
            imageShow = (<div className="previewText">Please select an Image for Preview</div>);
        }

        return (
            <div className="portlet light bordered">
                {/*<div>*/}
                    {/*{hasId &&  <button type="button" className=" btn btn-primary"   onClick={this.handleBackToCreate}><TransComponent i18nKey="Quay lại tạo mới" /></button>}*/}
                {/*</div>*/}
                <div className="portlet-body form">
                    <div className="row">
                        <div className="col-md-12">
                            <form name="form_group">
                                <div className="form-body">
                                    { (!editStatus) &&
                                    <div className="form-group">
                                        <label><TransComponent i18nKey="Username" /></label>
                                        <Field
                                            name="username"
                                            type="text"
                                            component="input"
                                            className="form-control form-control-solid placeholder-no-fix"
                                            autoComplete="off"
                                            disabled={resetPassStatus}
                                        />
                                        <Field name="username"component={renderError} />
                                    </div> }
                                    {(!editStatus) && <div className="form-group" style={{position: 'relative'}}>
                                        <label><TransComponent i18nKey="Password" /></label>
                                        <Field
                                            name="password"
                                            type="text"
                                            component="input"
                                            className="form-control form-control-solid placeholder-no-fix"
                                            autoComplete="off"
                                        />
                                        <Field name="password"component={renderError} />


                                        {/* Copy password button */}
                                        <CopyToClipboard
                                            text={_get(this.props.initialValues, 'password')}
                                            onCopy={this.onCopyPasswordSuccess}
                                            style={{position: 'absolute', top: '5px', right: 0}}
                                        >
                                            <i className="fa fa-copy" style={{fontSize: '24px', color: 'red', cursor: 'pointer'}}></i>
                                        </CopyToClipboard>
                                    </div>}


                                    {
                                        !resetPassStatus && <>
                                            <div className="form-group">
                                                <label><TransComponent i18nKey="Name" /></label>
                                                <Field
                                                    name="fullname"
                                                    type="text"
                                                    component="input"
                                                    className="form-control form-control-solid placeholder-no-fix"
                                                    autoComplete="off"
                                                />
                                                <Field name="fullname"component={renderError} />
                                            </div>
                                            {isAminOP6 &&
                                            <div className="form-group">
                                                <label><TransComponent i18nKey="Time(Hour)" /></label>
                                                <Field
                                                    name="time"
                                                    type="number"
                                                    component="input"
                                                    className="form-control form-control-solid placeholder-no-fix"
                                                    autoComplete="off"
                                                    style={{width: '20%'}}
                                                />
                                                {/*<Field name="time" component={renderError} />*/}
                                            </div>
                                            }
                                            {(!editStatus) && isAminOP6 && <div className="form-group">
                                                <label><TransComponent i18nKey="Select Image" /></label>
                                                <div className={"input_file_wrapper"}>
                                                    <input name="image" type="file" multiple className="form-control input_file" placeholder='Choose a file...'
                                                           ref={this.imageRef} onChange={ e => this.handleOnChangeFile(e)} onClick={e => this.handleClearInput(e)}/>
                                                </div>
                                            </div>
                                            }

                                            {(!editStatus) && isAminOP6 && <div className="imgPreviewModal">
                                                {imageShow}
                                            </div>
                                            }
                                            {!isAminOP6 &&
                                                <div>
                                                    <div className="form-group text-uppercase">
                                                        <label><TransComponent i18nKey="Credit" /></label>
                                                        <Field
                                                            name="credit"
                                                            type="text"
                                                            component="input"
                                                            className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label><TransComponent i18nKey="Phone" /></label>
                                                        <Field
                                                            name="phone"
                                                            type="text"
                                                            component="input"
                                                            className="form-control form-control-solid placeholder-no-fix"
                                                            autoComplete="off"
                                                        />
                                                        <Field name="phone"component={renderError} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label><TransComponent i18nKey="Ott" /></label>
                                                        <Field
                                                            name="ott"
                                                            type="text"
                                                            component="input"
                                                            className="form-control form-control-solid placeholder-no-fix"
                                                            autoComplete="off"
                                                        />
                                                        <Field name="ott"component={renderError} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label><TransComponent i18nKey="Address" /></label>
                                                        <Field
                                                            name="address"
                                                            type="text"
                                                            component="input"
                                                            className="form-control form-control-solid placeholder-no-fix"
                                                            autoComplete="off"
                                                        />
                                                        <Field name="address"component={renderError} />
                                                    </div>

                                                </div>
                                            }
                                            {editStatus  && <div className="form-group">
                                                <label><TransComponent i18nKey="Status" /></label>
                                                <Field
                                                    name="status"
                                                    className="basic-single"
                                                    component={renderSelectField}
                                                    options={optUserStatus}
                                                />
                                            </div>}
                                            {!isAminOP6 &&
                                            <div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="Limit Subs" /></label>
                                                    <Field
                                                        name="limit_subs"
                                                        type="number"
                                                        component="input"
                                                        className="form-control form-control-solid placeholder-no-fix"
                                                        autoComplete="off"
                                                        style={{width: '20%'}}
                                                    />
                                                    <Field name="note" component={renderError} />
                                                </div>

                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="note" /></label>
                                                    <Field
                                                        name="note"
                                                        type="text"
                                                        component="textarea"
                                                        className="form-control form-control-solid placeholder-no-fix"
                                                        autoComplete="off"
                                                    />
                                                    <Field name="note" component={renderError} />
                                                </div>
                                            </div>
                                            }
                                        </>
                                    }


                                    <div className="form-actions text-right">
                                        {/* Submit & Copy password button */}
                                        <CopyToClipboard
                                            text={_get(this.props.initialValues, 'password')}
                                            onCopy={this.onCopyPasswordSuccess}
                                        >
                                            <button type="button" className="btn red" disabled={this.props.invalid}
                                                    onClick={e => this.handleSubmitCreate(e,isAminOP6)}><TransComponent i18nKey="Save" /></button>
                                        </CopyToClipboard>

                                        {/*{ (!resetPassStatus && !editStatus) &&*/}
                                        {/*<button type="button" className="btn red" disabled={this.props.invalid}*/}
                                                {/*onClick={e => this.handleSubmitCreate(e,isAminOP6)}><TransComponent i18nKey="Create New" /></button> }*/}
                                        {/*{ resetPassStatus &&*/}
                                        {/*<button type="button" className="btn red" disabled={this.props.invalid}*/}
                                                {/*onClick={this.handleSubmitCreate}><TransComponent i18nKey="Update" /></button> }*/}
                                        {/*{ editStatus &&*/}
                                        {/*<button type="button" className="btn red"*/}
                                                {/*disabled={this.props.invalid}*/}
                                                {/*onClick={this.handleSubmitSave}><TransComponent i18nKey="Save" /></button> }*/}
                                    </div>

                                    <ToastContainer
                                        position="top-right"
                                        autoClose={4000}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group.values'),
        listGroupUser: state.UserImageReducer.listGroupUser,
        selectedItem: state.UserImageReducer.selectedItem,
        resetPassStatus: state.UserImageReducer.resetPassStatus,
        editStatus: state.UserImageReducer.editStatus,
        saveStatus: state.UserImageReducer.saveStatus,
        reload: state.UserImageReducer.reload,
        listUser: state.UserImageReducer.listUser,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        getUser: (param) => dispatch(getUser(param)),
        saveUser: params => dispatch(saveUser(params)),
        backToCreate: () => dispatch(backToCreate()),
        saveImage: params => dispatch(saveImage(params)),
    };
};

const validate = values => {
    const errors = {}
    if (!values.username) {
        errors.username = '"username" is required'
    }
    if (!values.password ) {
        errors.password = '"password" is required'
    }
    if (!values.fullname) {
        errors.fullname = '"name" is required'
    }

    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate,
        initialValues: {
            credit: 0,
            time: 360
        },
        enableReinitialize: false
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(FormCreateUserContainer);
