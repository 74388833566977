import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { TransComponent } from 'my-components'
import { getConfig, saveConfig } from 'my-actions/ConfigAction';
import { CreditItemContainer } from 'my-containers/admin/config';

class ConfigListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            showMessage: false,
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.props.getConfig();
    }

    componentWillReceiveProps (nextProps, prevProps) {
        if (this.props.rand !== nextProps.rand){
            // flash message
            this.setState({
                showMessage: nextProps.showMessage
            })
            setTimeout(() => {
                this.setState({showMessage: false})
            }, 8000);
        }
    }

    handleSaveConfig = (e) => {

        // this.props.saveConfig(Helpers.convertFormData(this.props.updateData))
        // this.props.saveConfig(this.props.lstConfig)
        this.props.saveConfig({'UpdateData': JSON.stringify(this.props.lstConfig)})

    };

    renderAlert = _ => {
        const { saveStatus, errors } = this.props
        console.log('saveStatus...', saveStatus, errors)
        if(saveStatus === false) {
            return ( this.state.showMessage &&
                <div className="alert alert-danger">
                    <span><b> <TransComponent i18nKey="Failure!"/> { errors.error_description }<span className={"display-none"}>{this.props.rand}</span></b></span>
                </div>
            )
        } else if(saveStatus === true) {
            return (
                this.state.showMessage &&
                <div className="alert bg-success">
                    <span><b> <TransComponent i18nKey="Successful!"/> <span className={"display-none"}>{this.props.rand}</span></b></span>
                </div>
            )
        }
        return null
    }

    render() {
        let { lstConfig } = this.props
        // console.log('lstConfig...', lstConfig)

        return (
            <>
                <div className="portlet-body">
                    {this.renderAlert()}
                    <div className="table-responsive">
                        <button style={{marginTop: "50px"}} onClick={this.handleSaveConfig}
                                className="btn btn-danger btn-add-formula"><TransComponent i18nKey="Save" /></button>
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th><TransComponent i18nKey="Type"/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lstConfig.length ?
                                    lstConfig.map( (item, index) => {
                                        return (
                                            <CreditItemContainer item={item} index={index}/>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        reload: state.ConfigReducer.reload,
        saveStatus: state.ConfigReducer.saveStatus,
        showMessage: state.ConfigReducer.showMessage,
        lstConfig: state.ConfigReducer.lstConfig,
        rand: state.ConfigReducer.rand,
        errors: state.ConfigReducer.errors,
        updateData: state.ConfigReducer.updateData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getConfig: (params) => dispatch(getConfig(params)),
        saveConfig: (params) => dispatch(saveConfig(params))

    }
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ConfigListContainer);