import React,{ Component } from 'react';

// import { TransComponent } from 'my-components'

import { PolicyImageContainer } from 'my-containers/admin/policy_image'

class PolicyPage extends Component {
    render() {
        return (
            <PolicyImageContainer/>
        );
    }
}

export default PolicyPage;