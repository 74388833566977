import React, {Component} from 'react';

import { ChangeSecureCodeContainer } from 'my-containers/navigation';

class ChangeSecureCodePage extends Component {
    render() {
        return <ChangeSecureCodeContainer />;
    }
}

export default ChangeSecureCodePage;