import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { TransComponent, LoadingComponent } from 'my-components';
import ReactPaginate from 'react-paginate';
import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'
import {getListReportImage, getListImageAccount} from 'my-actions/manage/ReportImageAction'
// import {getListReportImage, toggleImage, closeImage, toggleModelReport, getListImageAccount} from 'my-actions/manage/ReportImageAction'
import { initListUser } from 'my-actions/manage/ManageImageAction';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
import { DayReportImageContainer, AddMoreImageContainer } from 'my-containers/manage/report_image';

import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {isEqual, cloneDeep } from "lodash";


import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

class ReportImageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            perPage: 20,
            currentPage: 0,
            filterUsers: [],
            filterAccounts: [],
            isOpenModalState: false,
            isOpenImage: false,
            toggleItem: null,
            toggleIndexItem: undefined,
            activeIndexImage: undefined,
            listImageToggle: [],
            listReportImageClone: [],
            addMoreImage: false,
            selectDay: null,
        }

    }

    componentDidMount() {
        this.props.getListReportImage()
        this.props.initListUser()

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.reload !== this.props.reload) {
           this.setState({addMoreImage: false}, () => {
               this.props.getListReportImage()
               this.props.getListImageAccount(this.state.toggleItem)
           })

        }
    }


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });

    };

    handleChangeUsers = item => {
        this.setState({
            filterUsers: item ,
            currentPage: 0,
        })
    }

    handleChangeAccounts = item => {
        this.setState({
            filterAccounts: item ,
            currentPage: 0,
        })
    }
    handleToggleImage = (item, index) => {
        const state = {...this.state}
        const {listImageAccount} = this.props
        let listImageToggle = []
        if(listImageAccount.length > 0) {
            listImageAccount.forEach(item => {
                listImageToggle.push({
                    src: item.image_url,
                    alt: item.image_name,
                    index: index
                })
            })
        }

        if(state.isOpenModalState) {
            state.isOpenImage = !state.isOpenImage
            state.activeIndexImage = index
            state.listImageToggle = listImageToggle
        }
        this.setState(state)
    }

    handleAddImage = (item) => {
        this.setState({addMoreImage: true})
    }

    handleSelectDaySearch = (data) => {
        this.setState({selectDay: data.selectDay})
    }

    toggleModal = (item, index, isAgent) => {
        const state = {...this.state}
        state.isOpenModalState = !state.isOpenModalState

        if(state.addMoreImage) {
            state.addMoreImage = false
        }
        if(item && isEqual(isNaN(index), false) ) {
            state.toggleItem = item
            state.toggleIndexItem = index
            this.props.getListImageAccount(item)
            setTimeout(() => this.props.getListReportImage(), 100)


        }

        this.setState(state)
    }

    renderBodyModal() {

        let {listImageAccount} = this.props

        return (
            <>
                {listImageAccount.length > 0 ?
                    listImageAccount.map((item, index) => {
                        return (
                            <div className="imgPreview-2" key={index}>
                                <img style={{cursor: 'pointer'}} src={ item.image_url} width='100px' height='100px' alt={"image1"} onClick={ () => this.handleToggleImage(item, index)}/>
                                <div style={{paddingTop: '5px'}}>
                                    <button  style={{paddingTop: '5px'}} className="btn btn-primary" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.handleToggleImage(item, index)}>
                                        {/*<span className="">{`Hình số ${index + 1}`}</span>*/}
                                        <span className="">{item.image_name}</span>
                                    </button>
                                </div>

                            </div>
                        )
                    })
                    : null
                }
                <ToastContainer
                    position="top-right"
                    autoClose={4000}
                />
            </>

        )
    }

    renderModal() {

        let {  listImageAccount} = this.props
        let {isOpenModalState, toggleItem, activeIndexImage, isOpenImage, listImageToggle, addMoreImage} = this.state

        let permission = Number(CookieService.get('roles')) === RolesConfig.ADMIN
        const modalTitle = toggleItem ? <span >{toggleItem.account.toUpperCase()} - {toggleItem.trick_name} - {toggleItem.day_format} </span> : <TransComponent i18nKey="Create account" />
        return (
            <Modal isOpen={isOpenModalState} toggle={() => this.toggleModal()} scrollable={true} zIndex={isOpenImage ? -1 : 9999} className={'modal-image'} size={'lg'} style={{maxWidth: '800px'}}>
                <ModalHeader toggle={() => this.toggleModal()}>
                    {modalTitle}&nbsp;
                    {permission && <button className="fa fa-plus font-blue cursor-pointer" onClick={() => this.handleAddImage(toggleItem)} > &nbsp;<span>Thêm hình </span></button>}
                </ModalHeader>
                <ModalBody className={'flex-body'}>
                    {listImageAccount.length > 0 && !addMoreImage ?
                        listImageAccount.map((item, index) => {
                            return (
                                <div className="imgPreview-2" key={index}>
                                    <img style={{cursor: 'pointer'}} src={ item.image_url} width='100px' height='100px' alt={"image1"} onClick={ () => this.handleToggleImage(item, index)}/>
                                    <div style={{paddingTop: '5px'}}>
                                        <button  style={{paddingTop: '5px'}} className="btn btn-primary" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.handleToggleImage(item, index)}>
                                            {/*<span className="">{`Hình số ${index + 1}`}</span>*/}
                                            <span className="">{item.image_name}</span>
                                        </button>
                                    </div>

                                </div>
                            )
                        })
                        : <AddMoreImageContainer toggleItemImage={toggleItem}/>
                    }
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                    />

                </ModalBody>
                <ModalFooter>
                </ModalFooter>
                <Viewer
                    visible={isOpenImage}
                    images={listImageToggle}
                    onClose={() => this.handleToggleImage()}
                    zoomSpeed='0.3'
                    rotatable={ false}
                    scalable={ false}
                    activeIndex={activeIndexImage}
                    onMaskClick={() => this.handleToggleImage()}
                />
            </Modal>
        )
    }


    render() {
        let  {listReportImage, listUser, listAcc} = this.props
        let  {filterUsers, filterAccounts, perPage, currentPage, selectDay } = this.state
        let isAgent = Number(CookieService.get('roles')) === RolesConfig.AGENT || false
        if (filterUsers.length > 0) {
            let filter_value = filterUsers.map(function (item) {
                return item.value
            })
            listReportImage = listReportImage.filter(function (item) {
                return filter_value.indexOf(item.user_id) > -1
            })

            listAcc = listAcc.filter(item => filter_value.indexOf(item.user_id) > -1)
        }

        if (filterAccounts.length > 0) {
            let filter_value = filterAccounts.map(function (item) {
                return item.value
            })
            listReportImage = listReportImage.filter(function (item) {
                return filter_value.indexOf(item.account_id) > -1
            })
        }
        if(selectDay) {
            let search_day = new Date(selectDay).getTime()
            listReportImage = listReportImage.filter(item => item.day == search_day)
        }
        let pageCount = Math.ceil(listReportImage.length / perPage)
        return (
            <div className="portlet light bordered report">
                <div className="row portlet-title">
                    <div className="col-md-6 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="Báo cáo phân tích" /></span>
                    </div>
                    {!isAgent &&
                    <div className=" col-md-2 form-group" >
                        <span>Chọn User</span>
                        <Multiselect
                            data={listUser}
                            value={filterUsers}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeUsers}
                        />
                    </div>
                    }

                    {!isAgent &&
                    <div className=" col-md-2 form-group" >
                        <span>Chọn Tài khoản</span>
                        <Multiselect
                            data={listAcc}
                            value={filterAccounts}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeAccounts}
                        />
                    </div>
                    }

                    {!isAgent &&
                    <div className=" col-md-1 form-group" style={{marginTop: '20px'}} >
                        {/*<span style={{paddingRight: '10px'}}>Chọn ngày</span>*/}
                        <DayReportImageContainer selectType={'single'} dataFromSelectDay={this.handleSelectDaySearch}/>
                    </div>}

                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                {!isAgent && <th className="uppercase"><TransComponent i18nKey="User"/></th>}
                                <th className="uppercase"><TransComponent i18nKey="Account"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Login name"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Password"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Code"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Company"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Account group"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Day"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Agent Code"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Type"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Status"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Created"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Updated"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Detail" /></th>
                                {/*<th className="uppercase"><TransComponent i18nKey="Add more" /></th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                listReportImage.length ?
                                    listReportImage.map( (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {currentPage * perPage + index + 1} </td>
                                                {!isAgent && <td className="uppercase">{item.user}</td>}
                                                <td className="uppercase">{item.account}</td>
                                                <td className="uppercase">{item.sub_user}</td>
                                                <td >{item.sub_pass}</td>
                                                <td className="uppercase">{item.sub_code}</td>
                                                <td className="uppercase">{item.banker_name}</td>
                                                <td className="uppercase">{item.acc_type}</td>
                                                <td className="uppercase">{item.day_format}</td>
                                                <td className="uppercase">{item.code}</td>
                                                <td className="uppercase">{item.trick_name}</td>
                                                <td >{item.status ? <span style={{color: 'blue'}}> <TransComponent i18nKey="seen" /> </span> : <span style={{color: 'red'}}><TransComponent i18nKey="unseen" /></span>}</td>
                                                <td className="uppercase">{item.created}</td>
                                                <td className="uppercase">{item.updated}</td>
                                                <td>
                                                    {/*<button className="btn btn-primary" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.handleToggleModel(item, index, isAgent)}>*/}
                                                    <button className="btn btn-primary" data-toggle="modal"  data-target="#exampleModal" onClick={() =>  this.toggleModal(item, index, isAgent)}>
                                                        <span className=""><TransComponent i18nKey="Detail" /></span>
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }

                            </tbody>
                        </table>
                        {pageCount > 1 &&
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                        }
                    </div>
                </div>
                {this.renderModal()}
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        reload: state.ReportImageReducer.reload,
        listReportImage: state.ReportImageReducer.listReportImage,
        listUser: state.ManageImageReducer.listUser,
        listAcc: state.ManageImageReducer.listAcc,
        listImageAccount: state.ReportImageReducer.listImageAccount,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getListReportImage: () => dispatch(getListReportImage()),
        initListUser: () => dispatch(initListUser()),
        getListImageAccount: (item) => dispatch(getListImageAccount(item))

    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ReportImageContainer);