import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class ScreensService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Screens list
    |--------------------------------------------------------------------------
    */
    getScreens(){
        return HttpService.post(`${this.serviceUrl}/screens/get`);
    };

}

export default new ScreensService()