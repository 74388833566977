import React, {Component} from 'react';
import { loadConfig, changeInput} from 'my-actions/ConfigAction';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";

class CreditItemContainer extends Component {

    // state = {
    //     lstConfig: {},
    //
    // };
    //
    // componentWillReceiveProps(nextProps, prevProps) {
    //     if ( nextProps.item ) {
    //         let updateData = []
    //         updateData['value_1'] = nextProps.item.value_1
    //         updateData['value_2'] = nextProps.item.value_2
    //         this.setState({
    //             lstConfig: updateData
    //         })
    //     }
    // }

    handleChangeConfig(item, event, index) {
        let {name, value} = event.target
        let updateList  = { ...item, [name]: parseInt(value)}
        let params = {
            index: index,
            list: updateList,

        }
        this.props.changeInput(params)
    }

    render() {

        let {item, index} = this.props
        return (
            <tr>
                <td>{this.props.index + 1}</td>
                <td>
                    <span>{this.props.item.type}</span>
                </td>
                <td>
                    <input type="number" name="value_1" value={this.props.item.value_1}
                           onChange={(e) => this.handleChangeConfig(item, e, index )}/> sub(s)
                </td>
                <td>
                    <input type="number" name="value_2" value={this.props.item.value_2}
                           onChange={(e) => this.handleChangeConfig(item, e, index )}/> sub(s)
                </td>
            </tr>
        );
    }
}

const mapStateToProps = state => {
    return {
        lstConfig: state.ConfigReducer.lstConfig,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeInput: (params) => dispatch(changeInput(params))

    }
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(CreditItemContainer);