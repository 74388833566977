import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class RestartService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;

    /*
   |--------------------------------------------------------------------------
   | @content: Get Restart list
   |--------------------------------------------------------------------------
   */
    getRestart(post){
        return HttpService.post(`${this.serviceUrl}/restart_group/get`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save restart
    |--------------------------------------------------------------------------
    */
    saveRestart(post){
        return HttpService.post(`${this.serviceUrl}/restart_group/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete restart
    |--------------------------------------------------------------------------
    */
    deleteRestart(post){
        return HttpService.post(`${this.serviceUrl}/restart_group/delete`, post);
    };


    /*
    |--------------------------------------------------------------------------
    | @content: Get Do Restart list
    |--------------------------------------------------------------------------
    */
    getDoRestart(post){
        return HttpService.post(`${this.serviceUrl}/restart_group/get_restart`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Do restart
    |--------------------------------------------------------------------------
    */
    doRestart(post){
        return HttpService.post(`${this.serviceUrl}/restart_group/do_restart`, post);
    };

}

export default new RestartService()