import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
// import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { getCreditLog } from 'my-actions/CreditLogAction';
import { initUsers } from 'my-actions/manage/UserImageAction';
import ReactPaginate from 'react-paginate';
import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'
import { sortBy as _sortBy } from 'lodash'
import { CookieService } from 'my-utils/core';
import { RolesConfig} from 'my-constants'

class CreditLogListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            offset: 0,
            data: [],
            perPage: 20,
            currentPage: 0
        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentWillMount() {
        this.props.initUsers()
    }

    componentDidMount() {
        this.props.getCreditLog();
    }

    componentDidUpdate() {
        // refresh data

        if(this.props.reload) {
            this.props.getUser();

        }
    }

    handleChangeFilter = users => {
        this.setState({filters: users})
    }

    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    handleExportCsv = () => {
        let data_export = []
        let data =  this.props.lstCreditLog;
        data.forEach(item => {
            const must = {}
            const myItem = []

            myItem['User'] = item.user;
            myItem['Role'] = item.user_created;

            myItem['Account'] = item.account;
            myItem['Banker'] = item.banker;
            myItem['Cycle'] = item.cycle;

            myItem['Old_Credit'] = item.old_value || "0";
            myItem['New_Credit'] = item.new_value || "0";
            myItem['Note'] = item.note;
            myItem['Date'] = item.created;

            const header = [ "User", "Role", "Account", "Banker", "Cycle", "Old_Credit", "New_Credit", "Note", "Date"];

            header.forEach(function (field) {
                if (myItem[field]) {
                    must[field] = myItem[field];
                }else {
                    must[field] = "";
                }
            });

            data_export.push(must)
        })
        data_export = _sortBy(data_export, 'User')
        console.log('data_export---', data_export)
        let result, ctr, keys, columnDelimiter, lineDelimiter;

        if (data_export == null || !data_export.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        keys = Object.keys(data_export[0]);

        for (let i of data_export) {
            const keyArr = Object.keys(i);

            if (keyArr.length > keys.length) {
                keys = keyArr;
            }
        }

        // for (let j of bankerResults) {
        //     const keyArr = Object.keys(j);
        //
        //     for (let key of keyArr) {
        //         if (keys.indexOf(key) < 0) {
        //             keys.splice(-4, 0, key);
        //         }
        //     }
        // }

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data_export.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;

                if (item[key]) {
                    result += item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        let blob = new Blob([result], {type: "text/csv;charset=utf-8;"});

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "export.csv")
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                // feature detection, Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "export.csv");
                // link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }



    }

    render() {
        let { lstCreditLog, optUsers } = this.props
        console.log('lstCreditLog...', lstCreditLog)
        if (this.state.filters.length > 0) {
            let filters = this.state.filters.map(function (item) {
                return item.value
            })
            lstCreditLog = lstCreditLog.filter(function (item) {
                return filters.indexOf(item.user_id) > -1
            })
        }

        let pageCount = Math.ceil(lstCreditLog.length / this.state.perPage)
        lstCreditLog  = lstCreditLog.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        return (
            <>
                <div className="row portlet-title">
                    {
                        Number(CookieService.get("roles")) !== RolesConfig.AGENT ?
                            <>
                                <div className="col-md-9 caption">
                                    <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="Report list" /></span>
                                </div>
                                <div className="col-md-2 form-group" >
                                    <Multiselect
                                        data={optUsers}
                                        value={this.state.filters}
                                        valueField='value'
                                        textField='label'
                                        onChange={this.handleChangeFilter}
                                    />
                                </div>
                                <div className="col-md-1 caption">
                                    <button className="btn red uppercase" onClick={this.handleExportCsv}>
                                        <TransComponent i18nKey="Export CSV" />&nbsp;
                                        {/*{ this.state.isLoading ? <i className={"fa fa-spin fa-spinner"} /> : null }*/}
                                    </button>
                                </div>
                            </> : <div className="col-md-12 caption">
                                <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="Report list" /></span>
                            </div>
                    }
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th><TransComponent i18nKey="User"/></th>
                                <th><TransComponent i18nKey="User created"/></th>
                                <th><TransComponent i18nKey="Account"/></th>
                                <th><TransComponent i18nKey="Banker"/></th>
                                <th><TransComponent i18nKey="Cycle"/></th>
                                <th><TransComponent i18nKey="New Credit"/></th>
                                <th><TransComponent i18nKey="Credit Winloss"/></th>
                                <th><TransComponent i18nKey="Note"/></th>
                                <th><TransComponent i18nKey="Date created"/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                lstCreditLog.length ?
                                    lstCreditLog.map( (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {this.state.currentPage * this.state.perPage + index + 1} </td>
                                                <td className="uppercase">{item.user}</td>
                                                <td className="uppercase">{item.user_created}</td>
                                                <td className="uppercase">{item.account}</td>
                                                <td className="uppercase">{item.banker}</td>
                                                <td className="uppercase">{item.cycle}</td>
                                                <td className="uppercase" style={{color: 'blue'}}>{item.new_value}</td>
                                                {item.change_value > 0 ?
                                                    <td className="uppercase" style={{color: 'green'}}>+{item.change_value}</td>
                                                    : <td className="uppercase" style={{color: 'red'}}> {item.change_value}</td>
                                                }
                                                <td className="uppercase">{item.note}</td>
                                                <td className="uppercase">{item.created}</td>
                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            </tbody>
                        </table>
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                    </div>
                </div>
            </>

        );
    }
}

const mapStateToProps = state => {
    return {
        reload: state.CreditLogReducer.reload,
        lstCreditLog: state.CreditLogReducer.lstCreditLog,
        optUsers: state.UserImageReducer.optUsers,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCreditLog: (params) => dispatch(getCreditLog(params)),
        initUsers: (params) => dispatch(initUsers(params))
    }
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(CreditLogListContainer);