import React from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import moment from 'moment';
import Helmet from 'react-helmet';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { selectDay, resetSelectDay } from 'my-actions/manage/ManageImageAction';
// import { selectDayReport, resetSelectDayReport } from 'my-actions/manage/ReportImageAction';

import { formatDate, parseDate } from 'react-day-picker/moment';



class DayPickerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleSingleDayChange = this.handleSingleDayChange.bind(this);
        this.state = {
            from: undefined,
            to: undefined,
            singleday: undefined,
        };
    }

    componentWillReceiveProps (nextProps, prevProps) {
        if ((nextProps.selectedItem && nextProps.isEditReport)) {
            this.setState({
                from: new Date(nextProps.selectedItem.from_date),
                to: new Date(nextProps.selectedItem.to_date)
            }, () => {
                this.props.selectDay({
                    from: nextProps.selectedItem.from_date,
                    to : nextProps.selectedItem.to_date
                })
            });



        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reload !== this.props.reload) {
            this.setState({
                from: undefined,
                to: undefined,
                singleday: undefined,
            });
        }
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    handleFromChange(from) {
        this.setState({ from });
        console.log('state-handleFromChange--', this.state)

    }

    handleToChange(to) {
        this.setState({ to }, this.showFromMonth);
        if(this.state.from) {
            this.props.selectDay({
                from: this.state.from,
                to: to
            })

        }

    }

    handleResetClick() {
        this.setState({
            from: undefined,
            to: undefined,
            singleday: undefined,
        }, () => {
            this.props.dataFromSelectDay({day: null})
        });




    }

    handleSingleDayChange(singleday, dayPickerInput) {
        this.setState({ singleday}, () => {
            this.props.dataFromSelectDay({day: this.state.singleday})
        });

    }

    singleInput(singleday) {

        return (
            <>
                <DayPickerInput
                    value={singleday}
                    onDayChange={this.handleSingleDayChange}
                    dayPickerProps={{selectedDays: singleday}}
                    format="DD/MM/YYYY"
                    placeholder="Chọn ngày"
                    formatDate={formatDate}
                    parseDate={parseDate}

                />
                {singleday && (
                    <button className="link" onClick={this.handleResetClick}>
                        Reset
                    </button>
                )}
            </>
        )
    }


    fromToInput(modifiers, from, to) {
        return (
            <div className="InputFromTo" style={{paddingRight: '10px'}}>
                <DayPickerInput
                    inputProps={{ style: { width: 100} }}
                    value={from}
                    placeholder="From"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 1,
                    onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                />{' '}
                —{' '}
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    inputProps={{ style: { width: 100 } }}
                    value={to}
                    placeholder="To"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 1,
                    }}
                    onDayChange={this.handleToChange}
                />
            {from && to && (
                <button className="link" onClick={this.handleResetClick}>
                Reset
                </button>
            )}
            </span>
            <Helmet>
                <style>{`
                    .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                    background-color: #f0f8ff !important;
                    color: #4a90e2;
                    }
                    .InputFromTo .DayPicker-Day {
                    border-radius: 0 !important;
                    }
                    .InputFromTo .DayPicker-Day--start {
                    border-top-left-radius: 50% !important;
                    border-bottom-left-radius: 50% !important;
                    }
                    .InputFromTo .DayPicker-Day--end {
                    border-top-right-radius: 50% !important;
                    border-bottom-right-radius: 50% !important;
                    }
                    .InputFromTo .DayPickerInput-Overlay {
                    width: 270px;
                    }
                    .InputFromTo-to .DayPickerInput-Overlay {
                    margin-left: -198px;
                    }
                `}</style>
            </Helmet>
        </div>
        )
    }



    render() {


        const { from, to, singleday  } = this.state;

        const modifiers = { start: from, end: to };

        let renderSelect = null
        if(this.props.selectType == 'single') {
            renderSelect = this.singleInput(singleday)
        } else {
            renderSelect = this.fromToInput(modifiers, from, to)
        }
        return (
            <>
                {renderSelect}
            </>


        );
    }
}
const mapStateToProps = state => {
    return {
        reload: state.ManageImageReducer.reload,

    };
};



export default compose(
    withTranslation(),
    connect(mapStateToProps, null),
)(DayPickerContainer);