import React, {Component} from 'react';
import { get as _get } from 'lodash'
import { TransComponent } from 'my-components'
import AgentOP6Service from 'my-services/manage/ManageImageService'

import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class AgentOP6Container extends Component{
    state = {
        data: [],
        listImageAgent: [],
        visible: false,
        isOpenModal: false,
        isOpenImage: false,
        activeIndexImage: null,
    }

    componentWillMount() {
        AgentOP6Service.getListAgentOP6().then(res => {
            if(res.status){
                let dataAgent = _get(res, 'res.data.listImageAgent', [])
                let listImageAgent = []
                if(dataAgent.length > 0) {
                    dataAgent.map((item , index)=> {
                        listImageAgent.push({
                            src: item.image_url,
                            alt: item.image_name,
                            index: index
                        })
                    })
                }
                this.setState({listImageAgent: listImageAgent})
            }
        })

    }



    toggleModal = () => {
        this.setState({isOpenModal: !this.state.isOpenModal})
    }

    downloadImages = async () => {
        if (!this.state || !this.state.listImageAgent || !this.state.listImageAgent.length) {
            return;
        }

        for (let i = 0; i< this.state.listImageAgent.length; i++) {
            const item = this.state.listImageAgent[i];
            await this.downloadImage(item);
        }
    }

    downloadImage = async (item, _) => {
        if (!item) {
            return;
        }

        const response = await fetch(item.src);
        response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = item.alt;
            a.click();
        });
    }

    toggleImage = (item, index) => {
        const state = {...this.state}
        state.isOpenImage = !state.isOpenImage
        if(state.isOpenImage) {
            state.activeIndexImage = index
        }

        this.setState(state)

    }



    renderModal() {
        let {isOpenModal, activeIndexImage, isOpenImage, listImageAgent} = this.state

        return (
            <Modal isOpen={isOpenModal} toggle={() => this.toggleModal()} scrollable={true} zIndex={isOpenImage ? -1 : 9999} className={'modal-image'} size={'lg'} style={{maxWidth: '800px'}}>
                <ModalHeader toggle={() => this.toggleModal()}>
                    <span className="Uppercase"><TransComponent i18nKey={'Analysis report'}/></span>
                </ModalHeader>
                <ModalBody className={'flex-body'}>
                    {listImageAgent.length > 0 ?
                        listImageAgent.map((item, index) => {
                            return (
                                <div className="imgPreview-2" key={index}>
                                    <div className="container">
                                        <img style={{cursor: 'pointer'}} src={item.src} width='100px' height='100px' alt={"image1"} onClick={ () => this.toggleImage(item, index)}/>
                                         <button className="btn" onClick={ () => this.downloadImage(item, index)}><TransComponent i18nKey="Download" /></button>
                                    </div>
                                    <div style={{paddingTop: '5px'}}>
                                        <button  style={{paddingTop: '5px'}} className="btn btn-primary" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.toggleImage(item, index)}>
                                            {/*<span className="">{`Hình số ${index + 1}`}</span>*/}
                                            <span className="">{item.alt}</span>
                                        </button>
                                    </div>

                                </div>
                            )
                        }) : null
                    }
                </ModalBody>
                {listImageAgent.length > 0 ? <button onClick={ () => this.downloadImages()} style={{marginLeft: '2rem', width: 'fit-content', marginBottom: '1rem'}} className="btn btn-primary" data-toggle="modal">
                    <span className=""><TransComponent i18nKey="Download all" /></span>
                </button>: null}
                <ModalFooter>
                </ModalFooter>
                <Viewer
                    visible={isOpenImage}
                    images={listImageAgent}
                    onClose={() => this.toggleImage()}
                    zoomSpeed='0.3'
                    rotatable={ false}
                    scalable={ false}
                    activeIndex={activeIndexImage}
                    onMaskClick={() => this.toggleImage()}
                />
            </Modal>
        )
    }




    render() {
        const { listImageAgent, visible } =  this.state
        // if(_isEmpty(data)) return null;
        return (
            <div className="row widget-row">
                <div className="col-xs-12">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-social-dribbble font-green hide"></i>
                                <span className="caption-subject font-dark bold uppercase"> <TransComponent i18nKey="Image" /></span>
                            </div>
                        </div>
                        <div className="portlet-body ">
                            <div className="">
                                {/*<span> <TransComponent i18nKey="Policy" />: </span>*/}
                                {listImageAgent.length > 0 ?  <button onClick={this.toggleModal} className="btn btn-primary">
                                    <TransComponent i18nKey="Detail" />
                                </button> : <span><TransComponent i18nKey="empty data" /></span>}
                                {this.renderModal()}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            )
    }
}



export default AgentOP6Container
