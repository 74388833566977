import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
// import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { getReport, deleteReport, editReport, addExtraItem } from 'my-actions/ReportUserAction';
import {Field} from "redux-form";
import ReactPaginate from 'react-paginate';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
import { DayPickerContainer } from 'my-containers/manage'
import { ReportItemContainer } from 'my-containers/report_user_2'
import { cloneDeep  } from 'lodash';
import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'

class ListUserContaner_2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            offset: 0,
            data: [],
            perPage: 20,
            currentPage: 0,
            filters: [],
            credit_winloss: 0,
            dayObj: null,
            id: null,
            editItem: null,

        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        // this.props.getReport();
    }


    componentWillReceiveProps(nextProps, prevProps) {
        if(nextProps && !nextProps.isSelectDay) {
            this.setState({dayObj: null})
        }
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            this.setState(
                {
                    id: nextProps.selectedItem.id,
                    editItem: nextProps.selectedItem
                })

        }



    }



    handleFilterUser = e => {
        const { value } = e.target;
        if(!value) {
            this.setState({ offset: 0, currentPage: 0})
        }
        this.setState({filter: value, currentPage: 0, offset: 0})


    }

    handleChangeFilter = users => {
        this.setState({filters: users})
    }



    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    handleSearch = () => {
        let dayObj = this.props.selectedDay
        this.setState({dayObj: dayObj})
        // console.log('from---', new Date(dayObj.from).getTime())


    }





    renderBody () {
        const {listAccount, isEditReport, selectedItem, t} = this.props
        if(listAccount.length > 0) {
            return listAccount.map( (item, index) => {
                return (
                    <ReportItemContainer key={index} item={item} index={index} t={t} />
                )
            })
        } else if(isEditReport) {
            return (
                <ReportItemContainer item={selectedItem} index={0}/>
                )

        } else {
            return (
                <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                )

        }
    }


    render() {
        let permission = Number(CookieService.get('roles')) === RolesConfig.SALE || Number(CookieService.get('roles')) === RolesConfig.FINANCIAL
        let {listReport, listUser, isSelectDay, listAccount, isEditReport, selectedItem, t} = this.props
        let {credit_winloss, editItem} = this.state

        // if (this.state.filters.length > 0) {
        //     let filters = this.state.filters.map(function (item) {
        //         return item.value
        //     })
        //     listReport = listReport.filter(function (item) {
        //         return filters.indexOf(item.user_id) > -1
        //     })
        // }
        // if (this.state.dayObj && isSelectDay ) {
        //     let date_from = new Date(this.state.dayObj.from).getTime()
        //     let date_to = new Date(this.state.dayObj.to).getTime()
        //     listReport = listReport.filter(function (item) {
        //         return  item.from_date >= date_from && item.to_date <= date_to
        //     })
        // }
        //
        let pageCount = Math.ceil(listAccount.length / this.state.perPage)
        // listReport  = listReport.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        return (
            <div className="portlet light bordered">
                <div className="row portlet-title">
                    <div className="col-md-5 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="List Account" /></span>
                        <span > </span>
                        { listAccount.length > 0 && <button className="fa fa-plus font-blue cursor-pointer" onClick={() => this.props.addExtraItem()} > <span>Thêm chi phí</span></button>}
                    </div>

                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th><TransComponent i18nKey="Banker"/></th>
                                <th><TransComponent i18nKey="Account"/></th>
                                <th><TransComponent i18nKey="Book"/></th>
                                <th><TransComponent i18nKey="Type"/></th>
                                <th><TransComponent i18nKey="Credit Winloss"/></th>
                                <th><TransComponent i18nKey="Note"/></th>
                                {/*{*/}
                                    {/*permission && <th><TransComponent i18nKey="Edit"/></th>*/}
                                {/*}*/}
                                {/*{*/}
                                    {/*permission && <th><TransComponent i18nKey="Delete" /></th>*/}
                                {/*}*/}
                            </tr>
                            </thead>
                            <tbody>
                            {/*{*/}
                                {/*listAccount.length ?*/}
                                    {/*listAccount.map( (item, index) => {*/}
                                        {/*return (*/}

                                            {/*<ReportItemContainer key={index} item={item} index={index} t={t} />*/}

                                        {/*)*/}
                                    {/*}) : isEditReport ? <ReportItemContainer item={selectedItem} index={0}/>*/}

                                    {/*: <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>*/}
                            {/*}*/}
                            {this.renderBody()}
                            </tbody>
                        </table>
                        {pageCount > 1 &&
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                        }

                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        listReport: state.ReportUserReducer.listReport,
        listAccount: state.ReportUserReducer.listAccount,
        selectedItem: state.ReportUserReducer.selectedItem,
        isEditReport: state.ReportUserReducer.isEditReport,
        editStatus: state.ReportUserReducer.editStatus,

    };
};

const mapDispatchToProps = dispatch => {
    // console.log('Dispatch==', dispatch)
    return {
        addExtraItem: (params) => dispatch(addExtraItem(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListUserContaner_2);