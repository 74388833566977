import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { Helpers } from 'my-utils';
// import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { getReport, deleteReport, editReport, changeInput, changeItemEdit } from 'my-actions/ReportUserAction';
import {Field} from "redux-form";
import ReactPaginate from 'react-paginate';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
import { DayPickerContainer } from 'my-containers/manage'

import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'

class ReportItemContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credit_winloss: '',
            note: '',
            id: null,
            editItem: null
        }

    }

    componentDidMount() {
        // this.props.getReport();
    }

    componentDidUpdate() {
        // refresh data
        if(this.props.reload) {
            this.props.getReport();
        }
        // if(!this.props.isSelectDay) {
        //    this.setState({dayObj: null})
        // }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if(nextProps && !nextProps.isSelectDay) {
            this.setState({dayObj: null})
        }
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            this.setState(
                {
                    id: nextProps.selectedItem.id,
                    editItem: nextProps.selectedItem
                })

        }



    }

    handleSearch = () => {
        let dayObj = this.props.selectedDay
        this.setState({dayObj: dayObj})
        // console.log('from---', new Date(dayObj.from).getTime())


    }
    handleChangeInput(item, event, index) {

        let {name, value} = event.target

        if (this.props.isEditReport) {


            let update_item = {...item, [name] : value  }
            let params = {
                updateItem: update_item,

            }

            this.props.changeItemEdit(params)
        } else {


            // let updateList  = { ...item, [name] : value }
            let updateList  = { ...item, [name] : Helpers.formatInputWithCommas(value.replace(/,/g,''), 0) }
            let params = {
                index: index,
                list: updateList,

            }

            this.props.changeInput(params)
        }

    }

    render() {
        let {index, item, t, isEditReport } = this.props
        console.log('isEditReport---', isEditReport)
        let className = null
        if(item.credit_wl && !isEditReport) {
            console.log('item.credit_wl--', item.credit_wl)
            className = Number(item.credit_wl.replace(/,/g, '')) > 0 ? 'font-blue-steel' : 'font-red'
        } else if (item.credit_wl && isEditReport) {
            className = Number(item.credit_wl) > 0 ? 'font-blue-steel' : 'font-red'
        }
        return (
            <tr >
                <td> { index + 1} </td>
                <td className="uppercase">{item.banker_name}</td>
                <td className="uppercase">{item.acc_name || item.account_name}</td>
                <td className="uppercase">{t(item.book_name)}</td>
                <td className="uppercase">{item.acc_group_name || item.account_type}</td>
                <td className={className}>
                    <input type="text" name="credit_wl"  autoComplete="off" step="any"  value={item.credit_wl} style={{border: 'none'}}
                onChange={(e) => this.handleChangeInput(item, e, index )}/>
                </td>
                <td>
                    <input type="text" name="note_cycle" autoComplete="off" value={item.note_cycle } style={{border: 'none'}}
                           onChange={(e) => this.handleChangeInput(item, e, index )}/>
                </td>
            </tr>

        );
    }
}

const mapStateToProps = state => {
    return {
        listAccount: state.ReportUserReducer.listAccount,
        isEditReport: state.ReportUserReducer.isEditReport,
        editStatus: state.ReportUserReducer.editStatus,
        selectedItem: state.ReportUserReducer.selectedItem,


    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeInput: (params) => dispatch(changeInput(params)),
        changeItemEdit: (params) => dispatch(changeItemEdit(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ReportItemContainer);