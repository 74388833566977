import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { initListUser, initListAccount, saveImage } from 'my-actions/manage/ManageImageAction';
import { initListTrick } from 'my-actions/manage/TrickConfigAction';
import { Helpers } from 'my-utils'
// import {} from 'react-toastify'

import { ListImageContainer, DayPickerContainer } from 'my-containers/manage'
import {Field, reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'




class ManageImageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id : null,
            file: '',
            imagePreviewUrl: '',
            files: [],
            reportImg: false,
            reload: false,
            selectDay: null,
            currentItem: this.props.toggleItem
        }

        this.imageRef = React.createRef();
        this.imageFile = null;
        this.imageFileName = null;
    }

    componentWillMount() {

        this.props.initialize({...this.props.initialValues})
        // console.log('this.props---', this.props)
        this.props.initListUser()
        // this.props.initListTrick()


    }

    componentDidUpdate(prevProps) {

        if(this.props.reload !== prevProps.reload) {
            this.setState({
                currentItem: null,
                // id: null
            })
            this.props.initialize({})

        }

    }


    //
    // componentWillReceiveProps (nextProps, prevProps) {
    //     if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
    //         this.props.initialize({...nextProps.selectedItem,
    //             username: this.props.listUser ? this.props.listUser.find(item => item.value === nextProps.selectedItem.belong_to_user) : null,
    //             trick: this.props.listUser ? this.props.listUser.find(item => item.value === nextProps.selectedItem.trick_id) : null
    //         })
    //         this.setState(
    //             {
    //                 id: nextProps.selectedItem.id
    //             })
    //     }
    //
    // }



    renderAlert = _ => {
        return null
    }

    handleCreate = (e, isAdminOP6) => {
        e.preventDefault();
        let img = this.state.files
        let img_arr = []
        for (const key of Object.keys(img)) {
            img_arr.push(img[key])
        }
        let params = null
        if(isAdminOP6) {
            params = {
                user: {
                    _id: _get(this.props.initialValues, 'user.value'),
                    name: _get(this.props.initialValues, 'user.label')
                },
                imageFile: img_arr,
            }



        } else {
            params = {
                user: {
                    _id: _get(this.props.initialValues, 'user.value'),
                    name: _get(this.props.initialValues, 'user.label')
                },
                account: {
                    _id: _get(this.props.initialValues, 'account.value'),
                    name: _get(this.props.initialValues, 'account.label')
                },
                trick: {
                    _id: _get(this.props.initialValues, 'trick.value'),
                    name: _get(this.props.initialValues, 'trick.label')
                },
                code: _get(this.props.initialValues, 'code', 0),

                imageFile: img_arr,
                // day: new Date(this.props.selectedDay.day).getTime(),
                day: new Date(this.state.selectDay).getTime(),
            }
        }
        console.log('params----', params)

        this.props.saveImage(Helpers.convertFormData( params ))
        this.props.initialize({})
        this.imageRef.current.value = null
        this.setState({
            imagePreviewUrl: '',
        })


    }

    handleOnChangeFile = e => {
        e.preventDefault();

        this.setState({
            files: e.target.files
        })

        let fileReader = new FileReader();
        const image = this.imageRef.current.files[0];

        this.imageFile = image;
        this.imageFileName = image ? image.name : '';
        fileReader.onloadend = () => {
            this.setState({
                file: image,
                imagePreviewUrl: fileReader.result
            });
        }
        image ? fileReader.readAsDataURL(image) : this.setState({imagePreviewUrl: null})
    }

    handleClearInput = e => {
        if(this.props.editStatus) {
            this.setState({
                imagePreviewUrl: '',
            })
        }

    }

    handleSelectDay = (data) => {
        this.setState({selectDay: data.day})
    }


    changeSelectUser = (e, isAdminOp6) => {
        console.log('isAdminOp6--', isAdminOp6)
        delete this.props.initialValues.account
        let user_id = e.value
        if(user_id && !isAdminOp6) {
            this.props.initListAccount({uid: user_id})
        }



    }

    renderAlert = () => {
        const { saveStatus } = this.props

        if(saveStatus === false) {
            return (
                <div className="alert alert-danger">
                    <span><b> <TransComponent i18nKey="Failure!"/> </b></span>
                </div>
            )
        } else if(saveStatus === true) {
            return (
                <div className="alert bg-success">
                    <span><b> <TransComponent i18nKey="Successful!"/> </b></span>
                </div>
            )
        }
        return null
    }
    render() {

        const isAdminOP6 = Number(CookieService.get('roles')) === RolesConfig.ADMINOP6
        let {imagePreviewUrl, selectDay} = this.state;
        let has_file = this.imageRef.current !== null ? this.imageRef.current.files[0] : false
        let show_button = has_file ? true : false
        let imageShow = null;
        if (imagePreviewUrl) {
            imageShow = (<img src={imagePreviewUrl} width='100%' height='450px' alt={"image1"}/>);
        } else {
            imageShow = (<div className="previewText">Please select an Image for Preview</div>);
        }


        return (

            <div className="portlet light bordered">
                <div className="portlet-body form">
                    <div className="row portlet-title">
                        <div className="col-md-9 caption">
                            <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="Add Images" /></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <form name="form_group">
                                <div className="form-body ">
                                     <div className="form-group " >
                                        <label><TransComponent i18nKey=" User" /></label>
                                        <Field
                                            name="user"
                                            className="basic-single"
                                            component={renderSelectField}
                                            isSearchable={true}
                                            options={isAdminOP6 ? this.props.listUserOP6 : this.props.listUser}
                                            onChange={ e => this.changeSelectUser(e, isAdminOP6)}

                                        />
                                        <Field name="user"component={renderError} />
                                    </div>
                                    {!isAdminOP6 &&
                                        <div className="form-group" >
                                            <label><TransComponent i18nKey="Account" /></label>
                                            <Field
                                                name="account"
                                                className="basic-single"
                                                component={renderSelectField}
                                                isSearchable={true}
                                                options={this.props.listAccount}

                                            />
                                            <Field name="account"component={renderError} />
                                        </div>
                                    }

                                    {!isAdminOP6 &&
                                        <div className="form-group form-group-time ">
                                            <label><TransComponent i18nKey="Loại" /></label>
                                            <Field
                                                name="trick"
                                                className="basic-single"
                                                component={renderSelectField}
                                                isSearchable={true}
                                                options={this.props.listTrick}

                                            />
                                            <Field name="trick"component={renderError} />
                                        </div>
                                    }

                                    {!isAdminOP6 &&
                                        <div className="form-group " >
                                            <label style={{paddingRight: '10px'}}><TransComponent i18nKey="Day " /></label>
                                            {<DayPickerContainer selectType={'single'} dataFromSelectDay={this.handleSelectDay}/>}
                                            <Field name="day"component={renderError} />
                                        </div>
                                    }

                                    {!isAdminOP6 &&
                                        <div className="form-group">
                                            <label><TransComponent i18nKey="Code" /></label>
                                            <Field
                                                name="code"
                                                type="text"
                                                component="input"
                                                className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                autoComplete="off"
                                            />
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label>Select Image: </label>
                                        <div className={"input_file_wrapper"}>
                                            <input name="image" type="file" multiple className="form-control input_file" placeholder='Choose a file...'
                                                   ref={this.imageRef} onChange={ e => this.handleOnChangeFile(e)} onClick={e => this.handleClearInput(e)}/>
                                        </div>
                                    </div>

                                    <div className="imgPreview">
                                        { imageShow}
                                    </div>
                                    <div className="form-actions text-right">
                                        { !isAdminOP6 && <button type="button" className="btn red" disabled={ !show_button || !selectDay || this.props.invalid  } onClick={(e) => this.handleCreate(e, isAdminOP6)}><TransComponent i18nKey="Create new" /></button>}
                                        { isAdminOP6 && <button type="button" className="btn red" disabled={ !show_button || this.props.invalid  } onClick={(e) => this.handleCreate(e, isAdminOP6)}><TransComponent i18nKey="Create new" /></button>}
                                    </div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={4000}
                                    />

                                </div>
                            </form>
                        </div>
                        <div className="col-md-7">
                            {<ListImageContainer displayItem={this.props.initialValues}/>}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group_manage_image.values', {}),
        listUser: state.ManageImageReducer.listUser,
        listUserOP6: state.ManageImageReducer.listUserOP6,
        listAccount: state.ManageImageReducer.listAccount,
        listAcc: state.ManageImageReducer.listAcc,
        listTrick: state.ManageImageReducer.listTrick,
        reload: state.ManageImageReducer.reload,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        saveImage: params => dispatch(saveImage(params)),
        initListUser: () => dispatch(initListUser()),
        initListAccount: (params) => dispatch(initListAccount(params)),
        initListTrick: () => dispatch(initListTrick()),

    };
};

const validate = values => {
    const errors = {}
    if (!values.user) {
        errors.user = '"user" is required'
    }
    if (!values.account) {
        errors.account = '"account" is required'
    }
    if (!values.trick) {
        errors.trick = '"trick" is required'
    }
    // if (!values.image) {
    //     errors.image = '"image" is required'
    // }
    // if (!values.day) {
    //     errors.day = '"day" is required'
    // }
    if (!values.type) {
        errors.type = '"type" is required'
    }
    return errors
}

export default compose(
    reduxForm({
        form: 'form_group_manage_image',
        validate
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ManageImageContainer);