import React, {Component} from "react";
import { ReportImageContainer } from 'my-containers/manage/report_image'

export default class ReportImagePage extends Component {
    render() {
        return  (
            <div className="portlet light bordered">
                <div className="portlet-body">
                    <ReportImageContainer/>
                </div>
            </div>
        )
    }
}

