import { AppConfig} from 'my-constants'
import { BaseService, HttpService} from 'my-utils/core'

class ConfigService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/config_manage`

    getConfig() {
        return HttpService.post(`${this.serviceUrl}/get`)
    }

    saveConfig(post) {
        return HttpService.post(`${this.serviceUrl}/action`, post)
    }
}

export default new ConfigService()