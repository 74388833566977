import { CreditLogActionType } from 'my-constants/action-types'
import CreditLogService from 'my-services/credit/CreditLogService'
import {get as _get} from "lodash";

export const getCreditLog = () => {
    return (dispatch) => {
        return CreditLogService.getCreditLog()
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: CreditLogActionType.GET_CREDIT_LOG_USER_SUCCESS,
                        payload: _get(res, 'res.data.List', []),
                    });
                } else {
                    dispatch({
                        type: CreditLogActionType.GET_CREDIT_LOG_USER_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                }
            }).catch(e => {
                dispatch({
                    type: CreditLogActionType.GET_CREDIT_LOG_USER_FAIL,
                    payload: _get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            });
    }
};