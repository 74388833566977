import React, { Component } from 'react';

import { ResetContainer } from 'my-containers/auth';

class ResetPage extends Component {
    render() {
        return <ResetContainer />;
    }
}

export default ResetPage;