import { CreditLogActionType } from 'my-constants/action-types'

let defaultState = {
    lstCreditLog: []
};

export const CreditLogReducer = (state = defaultState, action) => {
	switch(action.type){
		case CreditLogActionType.GET_CREDIT_LOG_USER_SUCCESS:
			return { ...state, lstCreditLog: action.payload, error: null };
        case CreditLogActionType.GET_CREDIT_LOG_USER_FAIL:
			return { ...state, error: action.payload };
		default:
			return {...state}
	}
};


export default CreditLogReducer;