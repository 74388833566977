import React,{ Component } from 'react';

import { TransComponent } from 'my-components'
import { FormCreateUserContainer } from 'my-containers/manage/user_image'

class CreateUserPage extends Component {
    render() {
        return (
            <div className="portlet-title">
                <div className="caption font-red-sunglo">
                        <span className="caption-subject bold uppercase">
                            <TransComponent i18nKey="Create new" />
                        </span>
                </div>
                <FormCreateUserContainer/>
            </div>
        );
    }
}

export default CreateUserPage;