/*========GET USERS LIST========*/
export const GET_GROUP_USER_SUCCESS   = 'GET_GROUP_USER_SUCCESS';
export const GET_GROUP_USER_FAIL      = 'GET_GROUP_USER_FAIL';

/*========INIT FORM USER ========*/
export const INIT_GROUP_USER   = 'INIT_GROUP_USER';
export const GROUP_USER_INIT_GROUP_USER_SUCCESS      = 'GROUP_USER_INIT_GROUP_USER_SUCCESS';

/*========SAVE USER ========*/
export const SAVE_GROUP_USER_SUCCESS   = 'SAVE_GROUP_USER_SUCCESS';
export const SAVE_GROUP_USER_FAIL      = 'SAVE_GROUP_USER_FAIL';

/*========DELETE USER ========*/
export const DELETE_GROUP_USER_SUCCESS   = 'DELETE_GROUP_USER_SUCCESS';
export const DELETE_GROUP_USER_FAIL      = 'DELETE_GROUP_USER_FAIL';

/*========EDIT USER ========*/
export const EDIT_GROUP_USER_SELECTED   = 'EDIT_GROUP_USER_SELECTED';

