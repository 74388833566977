import _get from 'lodash/get';

import { AccountGroupActionType } from 'my-constants/action-types';
import { AccountGroupService } from 'my-services/account_group';

export const initAccountGroups = (params) => {
    return (dispatch) => {
        dispatch({
            type: AccountGroupActionType.ACCOUNT_GROUP_INIT_GROUPS,
        });

        AccountGroupService.getAccountGroups().then(res => {
            if (res.status) {
                const optGroups = res.res.data.List.map(item => ({ value: item.id, label: item.name.toUpperCase() }))

                // Dispatch data to reducer
                dispatch({
                    type: AccountGroupActionType.ACCOUNT_GROUP_INIT_GROUPS_SUCCESS,
                    optGroups: optGroups
                });
            }
        })
    }
}

export const getAccountGroups = (post) => {
    return dispatch => {
        return AccountGroupService.getAccountGroups(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: AccountGroupActionType.GET_ACCOUNT_GROUPS_SUCCESS,
                        payload: res,
                    });
                } else {
                    dispatch({
                        type: AccountGroupActionType.GET_ACCOUNT_GROUPS_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: AccountGroupActionType.GET_ACCOUNT_GROUPS_FAIL,
                    payload: _get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};
export const saveAccountGroup = (post) => {
    return dispatch => {
        return AccountGroupService.saveAccountGroup(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: AccountGroupActionType.SAVE_ACCOUNT_GROUP_SUCCESS,
                        saveStatus: res.status,
                    });
                } else {
                    dispatch({
                        type: AccountGroupActionType.SAVE_ACCOUNT_GROUP_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: AccountGroupActionType.SAVE_ACCOUNT_GROUP_FAIL,
                    payload: _get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};
export const deleteAccountGroup = (post) => {
    return dispatch => {
        return AccountGroupService.deleteAccountGroup(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: AccountGroupActionType.DELETE_ACCOUNT_GROUP_SUCCESS,
                        deleteStatus: res.status,
                    });
                } else {
                    dispatch({
                        type: AccountGroupActionType.DELETE_ACCOUNT_GROUP_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: AccountGroupActionType.DELETE_ACCOUNT_GROUP_FAIL,
                    payload: _get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};

export const editAccountGroup = (post) => {
    return dispatch => {
        dispatch({
            type: AccountGroupActionType.EDIT_ACCOUNT_GROUP_SELECTED,
            projectID: post.selectedItem.id,
            selectedItem: post.selectedItem,
        });
    }
};
