import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { TransComponent } from 'my-components'
import { getLisPolicy,  deleteImage } from 'my-actions/PolicyAction';

import 'react-widgets/dist/css/react-widgets.css'
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class ListPolicyContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {

            offset: 0,
            perPage: 20,
            currentPage: 0,
            isOpenImage: false,
            indexToggleImage: null

        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        this.props.getLisPolicy()

    }

    componentDidUpdate() {
        // refresh data

        if(this.props.reload) {
            this.props.getLisPolicy();
        }
    }

    toggleImage = (item, index) => {
        const state = {...this.state}
        state.isOpenImage = !state.isOpenImage
        if(state.isOpenImage) {
            state.indexToggleImage = index
        }

        this.setState(state)
    }



    handleDeleteImage = item => {

        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteImage(params)


    }


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });

    };



    render() {
        let {listPolicy, scrPolicy} = this.props

        return (
            <div className="portlet light bordered">
                <div className="row portlet-title">
                    <div className="col-md-9 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="Policy" /></span>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th className="uppercase"><TransComponent i18nKey="Image"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Created"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Delete" /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                listPolicy.length ?
                                    listPolicy.map( (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {this.state.currentPage * this.state.perPage + index + 1} </td>

                                                <td className="uppercase">
                                                    <button type="button" className="btn btn-primary" onClick={ () => this.toggleImage(item, index)}> {item.image_name}</button>
                                                </td>
                                                <td className="uppercase">{item.time}</td>
                                                <td>
                                                    <button className="text-success btn btn-link font-red" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.handleDeleteImage({selectedItem:item})}>
                                                        <i className="fa fa-close" />
                                                    </button>

                                                </td>

                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            <Viewer
                                visible={this.state.isOpenImage}
                                images={scrPolicy}
                                onClose={this.toggleImage}
                                zoomSpeed='0.5'
                                rotatable={ false}
                                scalable={ false}
                                activeIndex={this.state.indexToggleImage}
                                onMaskClick={this.toggleImage}
                            />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        reload: state.PolicyReducer.reload,
        listPolicy: state.PolicyReducer.listPolicy,
        scrPolicy: state.PolicyReducer.scrPolicy,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLisPolicy: () => dispatch(getLisPolicy()),
        deleteImage: params => dispatch(deleteImage(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListPolicyContainer);