import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class TrickConfigService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getTrick(){
        return HttpService.post(`${this.serviceUrl}/trick_config/get`, );
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveTrick(post){
        return HttpService.post(`${this.serviceUrl}/trick_config/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteTrick(post){
        return HttpService.post(`${this.serviceUrl}/trick_config/delete`, post);
    };
}

export default new TrickConfigService()