import { AppConfig } from 'my-constants';
import { BaseService, HttpService, HttpServiceMultipart } from 'my-utils/core';

class ReportImageService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get list
    |--------------------------------------------------------------------------
    */
    getList(){
        return HttpService.post(`${this.serviceUrl}/report_image/get_list`);
    };

    /*
    /*getImage
    |--------------------------------------------------------------------------
    | @content: Get Image
    |--------------------------------------------------------------------------
    */
    getImage(post){
        return HttpService.post(`${this.serviceUrl}/report_image/get_image`, post);
    };
    /*getImage
    |--------------------------------------------------------------------------
    | @content: Get Image
    |--------------------------------------------------------------------------
    */
    getListImage(post){
        return HttpService.post(`${this.serviceUrl}/report_image/get_list_image`, post);
    };


    saveImage(post){
        return HttpServiceMultipart.post(`${this.serviceUrl}/manage_image/action`, post);
    };


}

export default new ReportImageService()