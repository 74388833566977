import { UserImageActionType, GroupUserActionType } from 'my-constants/action-types';

import { get, sortBy } from 'lodash';

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    listUser: [],
    listGroupUser: [],
    editGroupStatus: false,
    resetPassStatus: false,
    reload: false,
    clearinput: false,
    selectedItem: {},
    isOpenModal: false,
    isOpenModalDelete: false,
    editStatus: false
}

const UserImageReducer = (state = defaultState, action) => {
    switch(action.type) {

        case GroupUserActionType.INIT_GROUP_USER:
            return {...state, listGroupUser: action.listGroupUser, errors:  {}, reload: true, editStatus: false}
        case UserImageActionType.INIT_USERS_SUCCESS:
            return {...state, optUsers: action.optUsers, errors: {}, reload: false};
        case UserImageActionType.GET_USERS_SUCCESS:
            let listUser = get(action.payload, 'res.data.ListUser', [])
            listUser = sortBy(listUser, 'created').reverse()
            let optUsers = listUser.map(item => ({ value: item.id, label: item.username.toUpperCase() }))
            return {...state, listUser: listUser, optUsers: optUsers,
                errors: {}, reload: false, resetPassStatus: false }

        case UserImageActionType.GET_USERS_FAIL:
            return {...state, errors: action.payload};

        case UserImageActionType.SAVE_USER_SUCCESS:
            return {...state, saveStatus: action.saveStatus,
                reload: action.saveStatus, editGroupStatus: false, resetPassStatus: false,
                selectedItem: {id: null, time: 360}, isOpenModal: !action.saveStatus}
        case UserImageActionType.SAVE_USER_FAIL:
            return {...state, errors: action.payload};

        case UserImageActionType.DELETE_USER_SUCCESS:
            return {...state, deleteStatus: action.deleteStatus, reload: action.deleteStatus, isOpenModalDelete: !state.isOpenModalDelete}
        case UserImageActionType.DELETE_USER_FAIL:
            return {...state, errors: action.payload};

        case UserImageActionType.USER_TOGGLE_MODAL_FORM:
            return {...state, selectedItem: action.selectedItem, isOpenModal: !state.isOpenModal,
                resetPassStatus: false, editStatus: action.editStatus };
        case UserImageActionType.RESET_USER_SELECTED:
            return {...state, selectedItem: action.selectedItem,
                resetPassStatus: true, isOpenModal: !state.isOpenModal, editStatus: false };
        case UserImageActionType.USER_TOGGLE_MODAL_FORM_CONFIRM_DELETE:
            return {...state, selectedItem: action.selectedItem,
                isOpenModalDelete: !state.isOpenModalDelete };

        case UserImageActionType.BACK_TO_CREATE:
            return {...state,  resetPassStatus: false, editGroupStatus: false , selectedItem: {id: null, time: 360}};

        default:
            return {...state};
    }

}

export default UserImageReducer
