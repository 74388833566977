export const INIT_LIST_USER_IMAGE = 'INIT_LIST_USER_IMAGE'
export const INIT_LIST_ACCOUNT_IMAGE = 'INIT_LIST_ACCOUNT_IMAGE'
export const CHANGE_DAY = 'CHANGE_DAY'
export const RESET_SELECT_DAY = 'RESET_SELECT_DAY'


export const SAVE_IMAGE_SUCCESS= 'SAVE_IMAGE_SUCCESS'
export const SAVE_IMAGE_FAIL = 'SAVE_IMAGE_FAIL'

export const GET_LIST_USER_IMAGE_SUCCESS = 'GET_LIST_USER_IMAGE_SUCCESS'
export const GET_LIST_USER_IMAGE_FAIL = 'GET_LIST_USER_IMAGE_FAIL'

export const DELETE_IMAGE_SUCCESS= 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const EDIT_IMAGE_SELECTED = 'EDIT_IMAGE_SELECTED'

export const CLEAR_INPUT_IMAGE = 'CLEAR_INPUT_IMAGE'

export const DISABLE_EDIT_GROUP = 'DISABLE_EDIT_GROUP'

export const CHANGE_DISABLE_EDIT_GROUP = 'CHANGE_DISABLE_EDIT_GROUP'