import { ReportImageActionType } from 'my-constants/action-types';

import { get } from 'lodash';
import moment from "moment/moment";

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    listReportImage: [],
    listImageAccount: [],
    reload: false,

}

const ReportImageReducer = (state = defaultState, action) => {
    switch(action.type) {
        case ReportImageActionType.GET_LIST_REPORT_IMAGE_SUCCESS:
            return {...state, listReportImage: get(action.payload, 'res.data.List', []), errors: {}, reload: false}
        case ReportImageActionType.GET_LIST_REPORT_IMAGE_FAIL:
            return {...state, errors: action.payload};
        case ReportImageActionType.GET_LIST_IMAGE_ACCOUNT_SUCCESS:
            let listImageAccount = get(action.payload, 'res.data.List', [])
            let listImageTotal = listImageAccount.map((item, index) => {
                return {
                    src: item.image_url,
                    alt: item.image_name,
                    index:index
                }
            })
            return {...state, listImageAccount: listImageAccount, listImageTotal: listImageTotal, errors: {}, reload: false}
        case ReportImageActionType.GET_LIST_IMAGE_ACCOUNT_FAIL:
            return {...state, errors: action.payload};

        case ReportImageActionType.SAVE_IMAGE_SUCCESS:
            //addMoreItem
            return {...state, reload: action.saveStatus, addMoreImg: false, }
        case ReportImageActionType.SAVE_IMAGE_FAIL:
            return {...state, errors: action.payload};


        default:
            return {...state};
    }

}

export default ReportImageReducer
