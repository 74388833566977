import { AppConfig} from 'my-constants'
import { BaseService, HttpService} from 'my-utils/core'

class CreditLogService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/credit_manage`

    getCreditLog() {
        return HttpService.post(`${this.serviceUrl}/get`)
    }
}

export default new CreditLogService()