import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as flashReducer } from 'redux-flash'

// System Reducers
import AppReducer from './systems/AppReducer';
import LanguageReducer from './systems/LanguageReducer';
import AuthReducer from './systems/AuthReducer';
import NotificationReducer from './systems/NotificationReducer';
import ChangePasswordReducer from './systems/ChangePasswordReducer';
import ChangeSecureCodeReducer from './systems/ChangeSecureCodeReducer'
import AlertReducer from './systems/AlertReducer';
import UserReducer from './user/UserReducer';
import LanguageManageReducer from './language/LanguageManageReducer'
import GroupsReducer from './group/GroupsReducer'
import UserImageReducer from './user_image/UserImageReducer'
import ManageImageReducer from './manage_image/ManageImageReducer'
import AccountReducer from './AccountReducer'
import CreditLogReducer from './CreditLogReducer'
import ConfigReducer from './ConfigReducer'
import AccountGroupReducer from './account_group/AccountGroupReducer'
import ReportUserReducer from './report_user/ReportUserReducer'
import NoticeManageReducer from './notice/NoticeManageReducer'
import PolicyReducer from './PolicyReducer'
import ReportReducer from './ReportReducer'
import HistoryRecoveryReducer from './HistoryRecoveryReducer'
import ReportImageReducer from './report_image/ReportImageReducer'
import TrickConfigReducer from './trick_config/TrickConfigReducer'



const appReducers = combineReducers({
    // Redux form reducer
    form: formReducer,
    flash: flashReducer,

    alert: AlertReducer,
    // System reducers
    AppReducer,
    LanguageManageReducer,
    LanguageReducer,
    AuthReducer,
    NotificationReducer,
    ChangePasswordReducer,
    ChangeSecureCodeReducer,
    UserReducer,
    GroupsReducer,
    UserImageReducer,
    ManageImageReducer,
    AccountReducer,
    CreditLogReducer,
    ConfigReducer,
    AccountGroupReducer,
    ReportUserReducer,
    NoticeManageReducer,
    PolicyReducer,
    ReportReducer,
    TrickConfigReducer,
    ReportImageReducer,
    HistoryRecoveryReducer,

});

export default appReducers;