import DashboardPage from 'my-pages/DashboardPage';
import { LoginPage, ResetPage, SecureCodePage } from 'my-pages/auth';

import RoutesService from './RoutesService'
// import { CookieService } from 'my-utils/core';
import { LogoutContainer } from 'my-containers/auth';
import { LanguagePage, CreditLogPage, ConfigPage, AccountGroupPage, NoticePage, PolicyPage, HistoryCreditPage, HistoryRecoveryPage } from "my-pages/admin";

import { CreateUserPage, ListUserPage } from "my-pages/user_image";
import { ManageImagePage } from "my-pages/manage_image";
import { ReportImagePage } from "my-pages/report_image";
import { TrickConfigPage } from "my-pages/trick_config";
import { ChangePasswordPage } from "my-pages/navigation";
import { AccountPage } from "my-pages/account";
import { ReportPage } from "my-pages/report";
import { ReportPage_2 } from "my-pages/report_2";
import { ReportPageFinancial } from "my-pages/report_2";



const CommonRoutes = [
	{
		path: "/",
		exact: true,
		component: DashboardPage,
	},
	// authentication
	{
		path: RoutesService.getPath('ADMIN', 'AUTH_LOGOUT'),
		exact: true,
		component: LogoutContainer,
	},
	{
		path: RoutesService.getPath('ADMIN', 'AUTH_LOGIN', { type: 'login' }),
		exact: true,
		component: LoginPage,
	},
    {
        path: RoutesService.getPath('ADMIN', 'AUTH_LOGIN', { type: 'reset' }),
        exact: true,
        component: ResetPage,
    },
	{
		path: RoutesService.getPath('ADMIN', 'AUTH_LOGIN', { type: 'secure' }),
		exact: true,
		component: SecureCodePage,
	}
];

const Routes = [
	...CommonRoutes,
	/*
	|--------------------------------------------------------------------------
	| Manage Routes Area
	|--------------------------------------------------------------------------
	*/
    {
        path: RoutesService.getPath('ADMIN', 'ACCOUNT'),
        exact: true,
        component: AccountPage,
    },

    {
        path: RoutesService.getPath('ADMIN', 'CREATE_USER'),
        exact: true,
        component: CreateUserPage,
    },
    {
        path: RoutesService.getPath('ADMIN', 'LIST_USER'),
        exact: true,
        component: ListUserPage,
    },
	{
        path: RoutesService.getPath('ADMIN', 'MANAGE_IMAGE'),
        exact: true,
        component: ManageImagePage,
    },
    {
        path: RoutesService.getPath('ADMIN', 'REPORT_IMAGE'),
        exact: true,
        component: ReportImagePage,
    },
    {
        path: RoutesService.getPath('ADMIN', 'TRICK_CONFIG'),
        exact: true,
        component: TrickConfigPage,
    },

	{
        path: RoutesService.getPath('ADMIN', 'CHANGE_PASSWORD'),
        exact: true,
        component: ChangePasswordPage,
    },
    {
        path: RoutesService.getPath('ADMIN', 'REPORT'),
        exact: true,
        component: ReportPage,
    },
    {
        path: RoutesService.getPath('ADMIN_MANAGE', 'LANGUAGE'),
        exact: true,
        component: LanguagePage,
    },
    {
        path: RoutesService.getPath('ADMIN_MANAGE', 'CREDIT_LOG'),
        exact: true,
        component: CreditLogPage,
    },
    {
        path: RoutesService.getPath('ADMIN_MANAGE', 'CONFIG'),
        exact: true,
        component: ConfigPage,
    },
    {
        path: RoutesService.getPath('ADMIN_MANAGE', 'ACCOUNT GROUP'),
        exact: true,
        component: AccountGroupPage,
    },
    {
        path: RoutesService.getPath('ADMIN_MANAGE', 'NOTICE'),
        exact: true,
        component: NoticePage,
    },
    {
        path: RoutesService.getPath('ADMIN_MANAGE', 'POLICY'),
        exact: true,
        component: PolicyPage,
    },
    {
        path: RoutesService.getPath('ADMIN', 'CREATE_REPORT'),
        exact: true,
        component: ReportPage_2,
    },
    {
        path: RoutesService.getPath('ADMIN', 'ACCOUNTANT_REPORT'),
        exact: true,
        component: ReportPageFinancial,
    },
    {
        path: RoutesService.getPath('ADMIN', 'HISTORY_CREDIT'),
        exact: true,
        component: HistoryCreditPage,
    },
    {
        path: RoutesService.getPath('ADMIN', 'HISTORY_RECOVERY'),
        exact: true,
        component: HistoryRecoveryPage,
    },
	{
		path: '*',
		exact: true,
		component: DashboardPage,
	},

];

export default Routes;