import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button} from 'reactstrap';
import {compose} from "redux";
import {connect} from "react-redux";
// import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { getReportTotal, deleteReport, editReport } from 'my-actions/ReportUserAction';
import {Field} from "redux-form";
import ReactPaginate from 'react-paginate';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
// import { DayPickerContainer } from 'my-containers/manage'
import { ReportItemContainer, DayReportTotalContainer } from 'my-containers/report_user_2'
import {debounce as _deBounce, isEqual as _isEqual} from 'lodash'
import { Helpers } from 'my-utils'

import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'

class ListReportTotalContainer_2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
            filters: [],
            keySearch: '',
            statusFilter: [],
            isOpenDelModal: false,
            delItem : null,
            selectDayObj: null
        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        this.props.getReportTotal();
    }

    componentDidUpdate() {
        // refresh data
        if(this.props.reload) {
            this.props.getReportTotal();
        }
        // if(!this.props.isSelectDay) {
        //    this.setState({dayObj: null})
        // }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if(nextProps && !nextProps.isSelectDay) {
            this.setState({dayObj: null})
        }

        // if(!_isEqual(nextProps.isSelectDaySearch, this.props.isSelectDaySearch)){
        //     console.log('isSelectDaySearch---', nextProps.isSelectDaySearch)
        //     this.setState({
        //         currentPage: 0,
        //         offset: 0
        //     })
        // }
        // console.log('nextProps.selectedDaySearch---', nextProps.selectedDaySearch)
        // if(!_isEqual(nextProps.selectedDaySearch, this.props.selectedDaySearch)){
        //     this.setState({
        //         currentPage: 0
        //     })
        // }

    }



    handleFilterUser = e => {
        const { value } = e.target;
        if(!value) {
            this.setState({ offset: 0, currentPage: 0})
        }
        this.setState({filter: value, currentPage: 0, offset: 0})


    }

    handleChangeFilter = users => {
        this.setState({filters: users})
    }

    handleEditReport = item => {
        const params = {
            selectedItem: item.selectedItem,
        }
        this.props.editReport(params)
    }



    handleDeleteReport = item => {
        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteReport(params)
    }


    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    // handleSearch = () => {
    //     let dayObj = this.props.selectedDay
    //     this.setState({dayObj: dayObj})
    //     // console.log('from---', new Date(dayObj.from).getTime())
    //
    //
    // }

    handleChangeFilter = (item) => {
        this.setState({statusFilter: item})
    }

    handleSearch = _deBounce( key => {
        this.setState({keySearch: key})
    }, 200)

    handleDelReport = () => {
        this.setState({isOpenDelModal: !this.state.isOpenDelModal}, () => {
            this.props.deleteReport({id: this.state.delItem.id})
        })
    }

    toggleDelModal = (item) => {
        const newState = {...this.state}
        newState.isOpenDelModal = !newState.isOpenDelModal
        newState.delItem = item
        this.setState(newState)
    }

    handleSelectDaySearch = (data) => {
        this.setState({
            selectDayObj: data.selectDay,
            currentPage: 0,
            offset: 0
        })
    }

    renderModalDel() {
        const t = this.props.t;
        const {isOpenDelModal, delItem} = this.state
        return (
            <Modal isOpen={isOpenDelModal} toggle={() => this.toggleDelModal()}>
                <ModalHeader toggle={() => this.toggleDelModal()}  className="text-uppercase">
                    <strong><TransComponent i18nKey="Xac nhan" /></strong>
                </ModalHeader>
                <ModalBody>
                    <TransComponent i18nKey="Are you sure delete" />&nbsp;<span className="font-green">
                    {delItem && delItem.cycle}</span> của <span className="font-green uppercase">{` ${delItem && delItem.user}-${delItem && delItem.account_name}`}</span> ?
                </ModalBody>
                <ModalFooter>
                    <Button className="bg-green font-white" onClick={() => this.handleDelReport()}>{t('delete')}</Button>{' '}
                    <Button className="bg-red-sunglo font-white" onClick={() => this.toggleDelModal()}>{t('cancel')}</Button>
                </ModalFooter>
            </Modal>

        )
    }



    render() {
        let permission = Number(CookieService.get('roles')) === RolesConfig.SALE || Number(CookieService.get('roles')) === RolesConfig.FINANCIAL
        let {listReportTotal, isSelectDaySearch, optBook, selectedDaySearch, t} = this.props
        let {selectDayObj} = this.state
        let isSelectDaySearchState = selectDayObj ? true : false
        let statusValue = [...optBook , {value:'vip' , label: 'VIP'}, {value:'thường' , label: 'Thường'} ]
        let {keySearch, statusFilter} = this.state
        if (statusFilter.length > 0) {
            let filters = statusFilter.map(item => item.value)
            listReportTotal = listReportTotal.filter(function (item) {
                return filters.indexOf(item.account_type && item.account_type.toLowerCase()) > -1 || filters.indexOf(item.book_id) > -1
            })
        }
        if (isSelectDaySearchState) {
            let date_from = new Date(selectDayObj.from).getTime()
            let date_to = new Date(selectDayObj.to).getTime()
            listReportTotal = listReportTotal.filter(function (item) {
                return  item.from_date >= date_from && item.to_date <= date_to
            })
        }


        if(keySearch) {
            listReportTotal = listReportTotal.filter(item => {
                if( item.account_name && item.account_name.toLowerCase().indexOf(keySearch.toLowerCase().trim()) > -1 ||
                    item.banker_name && item.banker_name.toLowerCase().trim().indexOf(keySearch.toLowerCase().trim()) > -1 ||
                    item.user.toLowerCase().trim().indexOf(keySearch.toLowerCase().trim()) > -1  ) {
                    return true
                }
                return false
            })
        }

        let pageCount = Math.ceil(listReportTotal.length / this.state.perPage)
        listReportTotal  = listReportTotal.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        return (
            <div className="portlet light bordered report">
                <div className="row portlet-title">
                    <div className="col-lg-3 col-md-4 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="List Report Total" /></span>
                    </div>
                    <div className="col-lg-3 col-md-12 ">
                        <DayReportTotalContainer selectType={'fromto'} isReport={false} dataFromSelectDay={this.handleSelectDaySearch}/>
                    </div>
                    <div className="col-md-2">
                        <Multiselect
                            placeholder={`${t('group/type')}`}
                            data={statusValue}
                            value={statusFilter}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeFilter}
                        />
                    </div>
                    <div className="col-md-2">
                        <div className="input-icon right">
                            <i className="fa fa-search" />
                            <input className="form-control" onChange={e => this.handleSearch(e.target.value)} placeholder={t('search')} type="text" />
                        </div>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th><TransComponent i18nKey="Cycle"/></th>
                                {permission && <th><TransComponent i18nKey="Creator"/></th>}
                                <th><TransComponent i18nKey="Banker"/></th>
                                <th><TransComponent i18nKey="User"/></th>
                                <th><TransComponent i18nKey="Account"/></th>
                                <th><TransComponent i18nKey="Book"/></th>
                                <th><TransComponent i18nKey="Type"/></th>
                                <th><TransComponent i18nKey="Credit Winloss"/></th>
                                <th><TransComponent i18nKey="Note"/></th>
                                {
                                    permission && <th><TransComponent i18nKey="Edit"/></th>
                                }
                                {
                                    permission && <th><TransComponent i18nKey="Delete" /></th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                listReportTotal.length ?
                                    listReportTotal.map( (item, index) => {
                                        let  className = Number(item.credit_wl) > 0 ? 'font-blue-steel' : 'font-red'
                                        return (
                                            <tr key={index} >
                                                <td> { (this.state.currentPage ) * this.state.perPage + index + 1} </td>
                                                <td className="uppercase">{item.cycle}</td>
                                                {permission && <td className="uppercase">{item.creator}</td>}
                                                <td className="uppercase">{item.banker_name}</td>
                                                <td className="uppercase">{item.user}</td>
                                                <td className="uppercase">{item.account_name}</td>
                                                <td className="uppercase">{t(item.book_name)}</td>
                                                <td className="uppercase">{item.account_type}</td>
                                                {/*<td className="uppercase">{Helpers.formatMoney(item.credit_wl,2)}</td>*/}
                                                <td className={className}>{Helpers.formatMoney(item.credit_wl,Number.isInteger(item.credit_wl) ? 0 : 2)}</td>
                                                <td className="uppercase">{item.note_cycle}</td>
                                                {/*{*/}
                                                    {/*permission &&*/}
                                                    {/*<td>*/}
                                                        {/*<a href="javascript:void(0)" className="text-success btn btn-link" onClick={ () => this.handleEditReport({selectedItem:item})}>*/}
                                                            {/*<i className="fa fa-edit" />*/}
                                                        {/*</a>*/}
                                                    {/*</td>*/}
                                                {/*}*/}

                                                {
                                                    permission &&
                                                    <td>
                                                        <button className="text-success btn btn-link font-blue" onClick={ () => this.handleEditReport({selectedItem:item})}>
                                                            <i className="fa fa-edit" />
                                                        </button>
                                                    </td>
                                                }
                                                {
                                                    permission &&
                                                    <td>
                                                        <button className="text-success btn btn-link font-red" onClick={ () => this.toggleDelModal(item)}>
                                                            <i className="fa fa-close" />
                                                        </button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            </tbody>
                        </table>
                        {pageCount > 1 &&
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                        }
                        {this.renderModalDel()}

                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        listReportTotal: state.ReportUserReducer.listReportTotal,
        reload: state.ReportUserReducer.reload,
        listUser: state.ReportUserReducer.listUser,
        selectedDay: state.ReportUserReducer.selectedDay,
        isSelectDay: state.ReportUserReducer.isSelectDay,
        listAccount: state.ReportUserReducer.listAccount,
        selectedDaySearch: state.ReportUserReducer.selectedDaySearch,
        isSelectDaySearch: state.ReportUserReducer.isSelectDaySearch,
        optBook: state.ReportUserReducer.optBook || [],


    };
};

const mapDispatchToProps = dispatch => {
    return {
        getReportTotal: (params) => dispatch(getReportTotal(params)),
        deleteReport: (params) => dispatch(deleteReport(params)),
        editReport: (params) => dispatch(editReport(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListReportTotalContainer_2);