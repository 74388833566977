import { AppConfig} from 'my-constants'
import { BaseService, HttpService} from 'my-utils/core'

class HistoryRecoveryService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/history_recovery`

    getHistory() {
        return HttpService.post(`${this.serviceUrl}/get_history`)
    }
}

export default new HistoryRecoveryService()