import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
// import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { getReport, deleteReport, editReport } from 'my-actions/ReportUserAction';
import {Field} from "redux-form";
import ReactPaginate from 'react-paginate';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
import { DayPickerContainer } from 'my-containers/manage'

import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'

class ListUserContaner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            offset: 0,
            data: [],
            perPage: 20,
            currentPage: 0,
            filters: [],
            dayObj: null
        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        this.props.getReport();
    }

    componentDidUpdate() {
        // refresh data
        if(this.props.reload) {
            this.props.getReport();

        }
        // if(!this.props.isSelectDay) {
        //    this.setState({dayObj: null})
        // }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        if(nextProps && !nextProps.isSelectDay) {
            this.setState({dayObj: null})
        }
    }



    handleFilterUser = e => {
        const { value } = e.target;
        if(!value) {
            this.setState({ offset: 0, currentPage: 0})
        }
        this.setState({filter: value, currentPage: 0, offset: 0})


    }

    handleChangeFilter = users => {
        this.setState({filters: users})
    }

    handleEditReport = item => {
        const params = {
            selectedItem: item.selectedItem,
        }
        console.log('edit-item--', item.selectedItem)
        this.props.editReport(params)
    }



    handleDeleteReport = item => {
        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteReport(params)
    }

    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
    };

    handleSearch = () => {
        let dayObj = this.props.selectedDay
        this.setState({dayObj: dayObj})
        // console.log('from---', new Date(dayObj.from).getTime())


    }

    render() {
        let permission = Number(CookieService.get('roles')) === RolesConfig.SALE || Number(CookieService.get('roles')) === RolesConfig.FINANCIAL
        let {listReport, listUser, isSelectDay} = this.props
        if (this.state.filters.length > 0) {
            let filters = this.state.filters.map(function (item) {
                return item.value
            })
            listReport = listReport.filter(function (item) {
                return filters.indexOf(item.user_id) > -1
            })
        }
        if (this.state.dayObj && isSelectDay ) {
            let date_from = new Date(this.state.dayObj.from).getTime()
            let date_to = new Date(this.state.dayObj.to).getTime()
            listReport = listReport.filter(function (item) {
                return  item.from_date >= date_from && item.to_date <= date_to
            })
        }
        console.log('listReport---', listReport)

        let pageCount = Math.ceil(listReport.length / this.state.perPage)
        console.log('pageCount---', pageCount)
        listReport  = listReport.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);

        return (
            <div className="portlet light bordered">
                <div className="row portlet-title">
                    <div className="col-md-3 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="List Report" /></span>
                    </div>
                    <div className="col-md-6" style={{display: 'flex'}}>
                        <DayPickerContainer/>
                        <button type="button" className="btn red" style={{paddingRight: '10px'}}
                                onClick={this.handleSearch}>
                            <TransComponent i18nKey="Search" />

                        </button>
                    </div>
                    {permission &&
                    <div className="col-md-3 form-group" >
                        <Multiselect
                            data={listUser}
                            value={this.state.filters}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeFilter}
                        />
                    </div>
                    }
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th><TransComponent i18nKey="User"/></th>
                                <th><TransComponent i18nKey="Account"/></th>
                                <th><TransComponent i18nKey="Banker"/></th>
                                <th><TransComponent i18nKey="Cycle"/></th>
                                <th><TransComponent i18nKey="Credit Winloss"/></th>
                                <th><TransComponent i18nKey="New Credit"/></th>
                                {permission && <th><TransComponent i18nKey="Role"/></th>}
                                <th><TransComponent i18nKey="Note"/></th>
                                {
                                    permission && <th><TransComponent i18nKey="Edit"/></th>
                                }
                                {
                                    permission && <th><TransComponent i18nKey="Delete" /></th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                listReport.length ?
                                    listReport.map( (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {this.state.currentPage * this.state.perPage + index + 1} </td>
                                                <td className="uppercase">{item.user}</td>
                                                <td className="uppercase">{item.account}</td>
                                                <td className="uppercase">{item.banker}</td>
                                                <td className="uppercase">{item.cycle}</td>
                                                {item.change_value > 0 ?
                                                    <td className="uppercase" style={{color: 'green'}}>+{item.change_value}</td>
                                                    : <td className="uppercase" style={{color: 'red'}}> {item.change_value}</td>
                                                }
                                                <td className="uppercase" style={{color: 'blue'}}>{item.new_value}</td>
                                                {permission && <td className="uppercase">{item.role}</td>}
                                                <td className="uppercase">{item.note}</td>
                                                {
                                                    permission &&
                                                    <td>
                                                        <a href="javascript:void(0)" className="text-success btn btn-link" onClick={ () => this.handleEditReport({selectedItem:item})}>
                                                            <i className="fa fa-edit" />
                                                        </a>
                                                    </td>
                                                }
                                                {
                                                    permission &&
                                                    <td>
                                                        <button className="text-success btn btn-link font-red" onClick={ () => this.handleDeleteReport({selectedItem:item})}>
                                                            <i className="fa fa-close" />
                                                        </button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })
                                    : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                            }
                            </tbody>
                        </table>
                        {pageCount > 1 &&
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                        }

                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        listReport: state.ReportUserReducer.listReport,
        reload: state.ReportUserReducer.reload,
        listUser: state.ReportUserReducer.listUser,
        selectedDay: state.ReportUserReducer.selectedDay,
        isSelectDay: state.ReportUserReducer.isSelectDay,
    };
};

const mapDispatchToProps = dispatch => {
    // console.log('Dispatch==', dispatch)
    return {
        getReport: (params) => dispatch(getReport(params)),
        deleteReport: (params) => dispatch(deleteReport(params)),
        editReport: (params) => dispatch(editReport(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListUserContaner);