import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { saveImage } from 'my-actions/PolicyAction';
import { Helpers } from 'my-utils'
import { ListPolicyContainer } from 'my-containers/admin/policy_image'
import { reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';




class PolicyImageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id : '',
            file: '',
            imagePreviewUrl: '',
            displayImage: true,
            files: []
        }

        this.imageRef = React.createRef();
        this.imageFile = null;
        this.imageFileName = null;
    }


    componentDidMount() {
        this.props.initialize({...this.props.initialValues})
    }


    componentDidUpdate() {
        if(this.props.deleteStatus) {
            this.setState({
            })
            this.props.initialize({})

        }

    }


    handleCreate = e => {
        e.preventDefault();
        let img = this.state.files
        let img_arr = []
        for (const key of Object.keys(img)) {
            img_arr.push(img[key])
        }


        const params = {
            imageFile: img_arr,
            id: this.state.id,
        }
        console.log('params--', params)
        this.props.saveImage(Helpers.convertFormData(params))
        this.props.initialize({})
        this.imageRef.current.value = null
        this.setState({
            imagePreviewUrl: '',
            displayImage: true
        })

    }

    handleOnChangeFile = e => {
        e.preventDefault();
        this.setState({
            files: e.target.files
        })

        let fileReader = new FileReader();
        const image = this.imageRef.current.files[0];
        this.imageFile = image;
        this.imageFileName = image ? image.name : '';
        fileReader.onloadend = () => {
            this.setState({
                file: image,
                imagePreviewUrl: fileReader.result
            });
        }
        image ? fileReader.readAsDataURL(image) : this.setState({imagePreviewUrl: null})

    }

    handleClearInput = e => {
        if(this.props.editStatus) {
            this.setState({
                imagePreviewUrl: '',
                displayImage: false
            })
        }

    }


    render() {

        let {imagePreviewUrl} = this.state;
        let has_file = this.imageRef.current !== null ? this.imageRef.current.files[0] : false
        let show_button = has_file ? true : false
        let imageShow = null;

        if (imagePreviewUrl) {
            imageShow = (<img src={imagePreviewUrl} width='100%' height='450px' alt={"image1"}/>);
        } else {
            imageShow = (<div className="previewText">Please select an Image for Preview</div>);
        }



        return (

            <div className="portlet light bordered">
                <div className="portlet-body form">
                    <div className="row">
                        <div className="col-md-5">
                            <form name="form_group">
                                <div className="form-body">
                                     <div className="form-group">
                                        <label>Select Image: </label>
                                        <div className={"input_file_wrapper"}>
                                            <input name="image" type="file" multiple className="form-control input_file" placeholder='Choose a file...'
                                                   ref={this.imageRef} onChange={ e => this.handleOnChangeFile(e)} onClick={e => this.handleClearInput(e)}/>
                                        </div>
                                    </div>

                                    <div className="imgPreview">
                                        {imageShow}
                                    </div>
                                    <div className="form-actions text-right">
                                        <button type="button" className="btn red" disabled={ !show_button  } onClick={this.handleCreate}><TransComponent i18nKey="Create new" /></button>
                                    </div>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={4000}
                                    />

                                </div>
                            </form>
                        </div>
                        <div className="col-md-7">
                            {<ListPolicyContainer />}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group.values', {}),
    };

};

const mapDispatchToProps = dispatch => {
    return {
        saveImage: params => dispatch(saveImage(params)),

    };
};

const validate = values => {
    const errors = {}
    if (!values.type) {
        errors.type = '"type" is required'
    }
    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(PolicyImageContainer);