import { AppConfig} from 'my-constants'
import { BaseService, HttpService} from 'my-utils/core'

class AccountGroupService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/account_group`

    getAccountGroups() {
        return HttpService.post(`${this.serviceUrl}/get`)
    }

    saveAccountGroup(post) {
        return HttpService.post(`${this.serviceUrl}/action`, post)
    }

    /*
    |--------------------------------------------------------------------------
    | @content: Delete account group
    |--------------------------------------------------------------------------
    */
    deleteAccountGroup(post){
        return HttpService.post(`${this.serviceUrl}/delete`, post);
    };
}

export default new AccountGroupService()