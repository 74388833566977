/*========GET GROUPS LIST========*/
export const GET_GROUPS_SUCCESS   = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAIL      = 'GET_GROUPS_FAIL';

/*========INIT FORM GROUP ========*/
export const GROUP_INIT_GROUPS   = 'GROUP_INIT_GROUPS';
export const GROUP_INIT_GROUPS_SUCCESS      = 'GROUP_INIT_GROUPS_SUCCESS';

/*========SAVE GROUP ========*/
export const SAVE_GROUP_SUCCESS   = 'SAVE_GROUP_SUCCESS';
export const SAVE_GROUP_FAIL      = 'SAVE_GROUP_FAIL';

/*========DELETE GROUP ========*/
export const DELETE_GROUP_SUCCESS   = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL      = 'DELETE_GROUP_FAIL';

/*========EDIT GROUP ========*/
export const EDIT_GROUP_SELECTED   = 'EDIT_GROUP_SELECTED';

