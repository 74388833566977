import React, {Component} from 'react';

import { ChangePassword2Container } from 'my-containers/navigation';

class ChangePassword2Page extends Component {
    render() {
        return <ChangePassword2Container />;
    }
}

export default ChangePassword2Page;