import { find, forEach, cloneDeep } from "lodash";
import path from 'path';

const listAdminRoutes = [
    /*==========================================================================*/
    { name: "AUTH_LOGIN", path: "/auth/:type", language: "en"},
    /*==========================================================================*/
    { name: "AUTH_LOGOUT", path: "/auth/logout", language: "en"},
    /*==========================================================================*/
    { name: "ACCOUNT", path: "/account", language: "en"},
    /*==========================================================================*/
    { name: "ACCOUNTANT_REPORT", path: "/report", language: "en"},
    /*==========================================================================*/
    { name: "DASHBOARD", path: "/dashboard", language: "en"},
    /*==========================================================================*/
    { name: "CREATE_USER", path: "/manage/create_user", language: "en"},
    /*==========================================================================*/
    { name: "LIST_USER", path: "/manage/users", language: "en"},
    /*==========================================================================*/
    { name: "CREATE_GROUP_USER", path: "/manage/create_group_user", language: "en"},
    /*==========================================================================*/
    { name: "MANAGE_IMAGE", path: "/manage/manage_image", language: "en"},
    /*==========================================================================*/
    { name: "REPORT_IMAGE", path: "/manage/report_image", language: "en"},
    /*==========================================================================*/
    { name: "TRICK_CONFIG", path: "/manage/trick_config", language: "en"},
    /*==========================================================================*/
    { name: "MANAGE_GROUP_IMAGE", path: "/manage/manage_group_image", language: "en"},
    /*==========================================================================*/
    { name: "POLICY", path: "/policy", language: "en"},
    /*==========================================================================*/
    { name: "CREATE_REPORT", path: "/report/create", language: "en"},
    /*==========================================================================*/
    { name: "HISTORY_CREDIT", path: "/report/history_credit", language: "en"},
    /*==========================================================================*/
    { name: "HISTORY_RECOVERY", path: "/report/history_recovery", language: "en"},
    /*==========================================================================*/
    { name: "CHANGE_PASSWORD", path: "/change-password", language: "en"},
    /*==========================================================================*/
    { name: "CHANGE_SECURE_CODE", path: "/change-secure-code", language: "en"},
    /*==========================================================================*/

    /*============================== start ADMIN ROUTES ==================================*/
    { name: "LANGUAGE", path: "/lang", language: "en"},
    { name: "CREDIT_LOG", path: "/credit-log", language: "en"},
    { name: "CONFIG", path: "/config", language: "en"},
    { name: "ACCOUNT GROUP", path: "/account-group", language: "en"},
    { name: "NOTICE", path: "/notice", language: "en"},
];

/*
|--------------------------------------------------------------------------
| Class Manage Routes
| Ex:
|   - RoutesService.setLanguage('en').getPath('ADMIN', 'BLOG_FORM', { method: 'create'}, {name: 'acb', age: 32});
|   - RoutesService.getPath('ADMIN', 'BLOG_FORM', { method: 'create'}, {name: 'acb', age: 32});
|--------------------------------------------------------------------------
*/
class RoutesService {
    language = "en";

    /*
    |--------------------------------------------------------------------------
    | Set Language
    |--------------------------------------------------------------------------
    */
    setLanguage(language) {
        this.language = language;
        return this;
    }

    /*
    |--------------------------------------------------------------------------
    | @params: { method: heroId, id: 'foo' }
    | @queryParams: {name: "teo", age: 34}
    | Ex: ('ADMIN', 'BLOG_FORM', { method: 'create'}, {name: 'acb', age: 32})
    |--------------------------------------------------------------------------
    */
    getPath(area, name, params, queryParams) {
        let routes;
        switch (area) {
            case "ADMIN":
                routes = find(listAdminRoutes, { name, language : this.language });
                break;
            case "ADMIN_MANAGE":
                routes = find(listAdminRoutes, { name, language : this.language });
                if (routes && routes.path) {
                    routes = cloneDeep(routes)
                    routes.path = path.join('/admin', routes.path);
                }
                break;
            default: break;
        }

        if(typeof routes !== "undefined") {
            return this.buildFullPath(routes.path, params, queryParams);
        } else {
            return "/";
        }
    }

    /*
    |--------------------------------------------------------------------------
    | Build url with params & query params
    |--------------------------------------------------------------------------
    */
    buildFullPath(path, params, queryParams) {
        //Add Params Into Path
        if(params) {
            forEach(params, (value, key) => {
                let pattern = new RegExp(`/:${key}[^/]?[^?]?`, "gi");
                path = path.replace(pattern, `/${value}/`);
            });

            //Remove All Params Without Specified
            // path = path.replace(/:.*[^/]?[^?]?/gi, "");

            //Remove Last / In Path
            path = path.replace(/\/$/gi, "");
        }

        //Add Query Params Into Path
        if(queryParams) {
            if (typeof queryParams === "string") {
                queryParams = queryParams.replace("?", "");
                //Check path exist query params
                if(path.indexOf('?') !== -1) {
                    path = `${path}&${queryParams}`;
                } else {
                    path = `${path}?${queryParams}`;
                }
            } else {
                const queryString = this.serialize(queryParams);
                //Check path exist query params
                if(path.indexOf('?') !== -1) {
                    path = `${path}&${queryString}`;
                } else {
                    path = `${path}?${queryString}`;
                }
            }
        }

        return path;
    }

    /*
    |--------------------------------------------------------------------------
    | Convert Object To Query String
    |--------------------------------------------------------------------------
    */
    serialize(object) {
        let str = [];
        for (let p in object)
            if (object.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
            }
        return str.join("&");
    }
}

export default new RoutesService();