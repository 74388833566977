import { AppConfig } from 'my-constants';
import { BaseService, HttpService, HttpServiceMultipart } from 'my-utils/core';

class ManageImageService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getListUser(post){
        return HttpService.post(`${this.serviceUrl}/manage_image/get_list_user`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getListAccount(post){
        return HttpService.post(`${this.serviceUrl}/manage_image/get_list_account`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveImage(post){
        return HttpServiceMultipart.post(`${this.serviceUrl}/manage_image/action`, post);
    };
    // saveImage(post){
    //     return HttpService.post(`${this.serviceUrl}/manage_image/action`, post);
    // };
    /*
    |--------------------------------------------------------------------------
    | @content: Get list user image
    |--------------------------------------------------------------------------
    */
    getListUserImage(post){
        return HttpService.post(`${this.serviceUrl}/manage_image/get_list_user_image`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteImage(post){
        return HttpService.post(`${this.serviceUrl}/manage_image/delete`, post);
    };

    getListAgentOP6(post){
        return HttpService.post(`${this.serviceUrl}/manage_image/get_list_agent`, post);
    };
}

export default new ManageImageService()