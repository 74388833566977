import React, {Component} from 'react';
import { isEmpty as _isEmpty} from 'lodash'

import { TransComponent } from 'my-components'
import AlertService from 'my-services/systems/AlertService'

import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

class PanelAlert extends Component{
    state = {
        data: [],
        policy_image: [],
        visible: false
    }

    componentWillMount() {
        AlertService.get().then(res => {
            if(res.status){
                this.setState({data: res.res.List, policy_image: res.res.policy_image})
            }
        })

    }

    handleClose = () => {
        this.setState({visible: false})
    }

    handleOpen = () => {
        this.setState({visible: !this.state.visible})
    }





    render() {
        const { data, policy_image, visible } =  this.state
        if(_isEmpty(data)) return null;
        return (
            <div className="row widget-row">
                <div className="col-xs-12 col-md-6">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-social-dribbble font-green hide"></i>
                                <span className="caption-subject font-dark bold uppercase"> <TransComponent i18nKey="Alert" /></span>
                            </div>
                        </div>
                        <div className="portlet-body table-responsive" style={{maxHeight:"400px", overflowY: 'auto', overflowX: 'hidden'}}>
                            <div className="mt-btm-transform ">
                                {/*====================================================================*/}
                                {
                                    data.length ?
                                    data.map(function (items) {
                                    return(
                                        <div className="portlet box blue-hoki" key={items.created}>
                                            <div className="portlet-title">
                                                <div className="caption">
                                                    <i className="fa fa-newspaper-o"></i> {items.created_format}
                                                </div>
                                            </div>
                                            <div className="portlet-body" className={items.type === 1 ? 'bg-success' : items.type === 2 ? 'bg-danger' : 'bg-white'} style={{padding: '10px'}}>
                                                <div id="sample_3_wrapper" className="dataTables_wrapper no-footer">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/*Render HTML string as real HTML in a React component*/}
                                                            <div key={items.id} dangerouslySetInnerHTML={{ __html: items.name }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}) : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
                                }
                                {/*====================================================================*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-social-dribbble font-green hide"></i>
                                <span className="caption-subject font-dark bold uppercase"> <TransComponent i18nKey="Policy" /></span>
                            </div>
                        </div>
                        <div className="portlet-body ">
                            <div className="">
                                <span> <TransComponent i18nKey="Policy" />: </span>
                                {policy_image.length > 0 ?  <a onClick={this.handleOpen}>
                                    <TransComponent i18nKey="Detail" />
                                </a> : <span><TransComponent i18nKey="empty data" /></span>}
                                <Viewer
                                    visible={visible}
                                    images={policy_image}
                                    onClose={this.handleClose}
                                    zoomSpeed='0.5'
                                    rotatable={ false}
                                    scalable={ false}
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            )
    }
}



export default PanelAlert