import { ConfigActionType } from 'my-constants/action-types'
import ConfigService from 'my-services/config/ConfigService'
import {get as _get} from "lodash";

export const getConfig = () => {
    return (dispatch) => {
        return ConfigService.getConfig()
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: ConfigActionType.GET_CONFIG_SUCCESS,
                        payload: _get(res, 'res.data.List', []),
                    });
                } else {
                    dispatch({
                        type: ConfigActionType.GET_CONFIG_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                }
            }).catch(e => {
                dispatch({
                    type: ConfigActionType.GET_CONFIG_FAIL,
                    payload: _get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            });
    }
};

export const saveConfig = (post) => {
    return (dispatch) => {

        ConfigService.saveConfig(post).then(async res => {
            dispatch({
                type: ConfigActionType.SAVE_CONFIG_SUCCESS,
                status: res.status
            })
        })
    }
};

export const loadConfig = (post) => {
    return (dispatch) => {
        dispatch({
            type: ConfigActionType.LOAD_CONFIG,
            data: post
        })
    }
};

export const changeInput = (list) => {
    return (dispatch) => {
        dispatch({
            type: ConfigActionType.CHANGE_INPUT,
            payload: list
        })
    }
};