import React, { Component } from 'react';
// import { TransComponent } from 'my-components';

import { AccountGroupContainer } from 'my-containers/admin/account_group'

class AccountGroupPage extends Component {

    render() {
        return (
            <AccountGroupContainer/>
        );
    }
}

export default AccountGroupPage;