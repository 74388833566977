import { AppConfig } from 'my-constants';
import { BaseService, HttpService, HttpServiceMultipart } from 'my-utils/core';

class ReportUserService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getListUser(post){
        return HttpService.post(`${this.serviceUrl}/report_user/get_list_user`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getListAccount(post){
        return HttpService.post(`${this.serviceUrl}/report_user/get_list_account`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getReport(){
        return HttpService.post(`${this.serviceUrl}/report_user/get_list_report`);
    };


    getReportTotal(){
        return HttpService.post(`${this.serviceUrl}/report_user/get_list_report_total`);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveReport(post){
        return HttpService.post(`${this.serviceUrl}/report_user/action`, post);
    };


    saveReport_2(post){
        console.log('post---', post)
        return HttpService.post(`${this.serviceUrl}/report_user/test`, post);
    };


    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteReport(post){
        return HttpService.post(`${this.serviceUrl}/report_user/delete`, post);
    };
}

export default new ReportUserService()