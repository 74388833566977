import React, { Component } from 'react';

import { ResetSecurePassword } from 'my-containers/auth';

class ResetSecurePasswordPage extends Component {
    render() {
        return (
            <ResetSecurePassword />
        );
    }
}

export default ResetSecurePasswordPage;