/*========GET ACCOUNT GROUPS LIST========*/
export const GET_ACCOUNT_GROUPS_SUCCESS   = 'GET_ACCOUNT_GROUPS_SUCCESS';
export const GET_ACCOUNT_GROUPS_FAIL      = 'GET_ACCOUNT_GROUPS_FAIL';

/*========INIT FORM ACCOUNT GROUP ========*/
export const ACCOUNT_GROUP_INIT_GROUPS   = 'ACCOUNT_GROUP_INIT_GROUPS';
export const ACCOUNT_GROUP_INIT_GROUPS_SUCCESS      = 'ACCOUNT_GROUP_INIT_GROUPS_SUCCESS';

/*========SAVE ACCOUNT GROUP ========*/
export const SAVE_ACCOUNT_GROUP_SUCCESS   = 'SAVE_ACCOUNT_GROUP_SUCCESS';
export const SAVE_ACCOUNT_GROUP_FAIL      = 'SAVE_ACCOUNT_GROUP_FAIL';

/*========DELETE ACCOUNT GROUP ========*/
export const DELETE_ACCOUNT_GROUP_SUCCESS   = 'DELETE_ACCOUNT_GROUP_SUCCESS';
export const DELETE_ACCOUNT_GROUP_FAIL      = 'DELETE_ACCOUNT_GROUP_FAIL';

/*========EDIT ACCOUNT GROUP ========*/
export const EDIT_ACCOUNT_GROUP_SELECTED   = 'EDIT_ACCOUNT_GROUP_SELECTED';

