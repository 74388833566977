import { GroupsActionType } from 'my-constants/action-types';

import { get } from 'lodash';

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    groups: [],
    projectID: null
};

const GroupsReducer = (state = defaultState, action) => {
    switch(action.type){
        case GroupsActionType.GET_GROUPS_SUCCESS:
            return {...state, groups: get(action.payload, 'res.data.List', {}), errors: {}, reload: false};
        case GroupsActionType.GET_GROUPS_FAIL:
            return {...state, errors: action.payload};

        case GroupsActionType.GROUP_INIT_GROUPS_SUCCESS:
            return {...state, optGroups: action.optGroups, errors: {}, reload: false};

        case GroupsActionType.SAVE_GROUP_SUCCESS:
            return {...state, projectID: null, projectName: null, saveStatus: action.saveStatus, reload: action.saveStatus}
        case GroupsActionType.SAVE_GROUP_FAIL:
            return {...state, errors: action.payload};

        case GroupsActionType.DELETE_GROUP_SUCCESS:
            return {...state, deleteStatus: action.deleteStatus, reload: action.deleteStatus}
        case GroupsActionType.DELETE_GROUP_FAIL:
            return {...state, errors: action.payload};

        case GroupsActionType.EDIT_GROUP_SELECTED:
            return {...state, projectID: action.projectID, selectedItem: action.selectedItem}

        default:
            return {...state};
    }
};

export default GroupsReducer;