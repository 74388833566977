import React, {Component} from 'react';

import { ChangePasswordContainer } from 'my-containers/navigation';

class ChangePasswordPage extends Component {
    render() {
        return <ChangePasswordContainer />;
    }
}

export default ChangePasswordPage;