import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class GroupsService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getGroups(post){
        return HttpService.post(`${this.serviceUrl}/groups/get`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveGroup(post){
        return HttpService.post(`${this.serviceUrl}/groups/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteGroup(post){
        return HttpService.post(`${this.serviceUrl}/groups/delete`, post);
    };
}

export default new GroupsService()