import React, {Component} from 'react';
import { isEmpty as _isEmpty} from 'lodash'

import { TransComponent } from 'my-components'
import OverviewService from 'my-services/systems/OverviewService'
import { CookieService } from 'my-utils/core';
import { RolesConfig} from 'my-constants'

class OverviewContainer extends Component{
    state = {
        data: []
    }

    componentWillMount() {
        OverviewService.get().then(res => {
            if(res.status){
                this.setState({data: res.res})
            }
        })
    }

    render() {
        const data =  this.state.data
        let is_agent = CookieService.get("roles") == RolesConfig.AGENT
        let cls = "col-md-2"
        if (is_agent) {
            cls = "col-md-3"
        }
        if(_isEmpty(data)) return null;
        return (
            <div className="row widget-row">
                {
                    !is_agent &&
                    <div className="col-md-3">
                        {/* BEGIN WIDGET THUMB */}
                        <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 bordered">
                            <h4 className="widget-thumb-heading"><TransComponent i18nKey="Total Users"/></h4>
                            <div className="widget-thumb-wrap">
                                <i className="widget-thumb-icon bg-blue icon-user" />
                                <div className="widget-thumb-body">
                                <span className="widget-thumb-body-stat" data-counter="counterup" data-value={ data.sumOfAgents }>
                                    { data.sumOfAgents }
                                </span>
                                </div>
                            </div>
                        </div>
                        {/* END WIDGET THUMB */}
                    </div>
                }

                <div className={cls}>
                    {/* BEGIN WIDGET THUMB */}
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 bordered">
                        <h4 className="widget-thumb-heading"><TransComponent i18nKey="Total Accounts"/></h4>
                        <div className="widget-thumb-wrap">
                            <i className="widget-thumb-icon bg-red icon-list" />
                            <div className="widget-thumb-body">
                                <span className="widget-thumb-body-stat" data-counter="counterup"
                                      data-value={ (data.accountStatus[0] || 0) + (data.accountStatus[1] || 0) + (data.accountStatus[2] || 0) }>
                                    { (data.accountStatus[0] || 0) + (data.accountStatus[1] || 0) + (data.accountStatus[2] || 0) }
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* END WIDGET THUMB */}
                </div>
                <div className={cls}>
                    {/* BEGIN WIDGET THUMB */}
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 bordered">
                        <h4 className="widget-thumb-heading"><TransComponent i18nKey="Waiting accounts"/></h4>
                        <div className="widget-thumb-wrap">
                            <i className="widget-thumb-icon bg-yellow icon-pin" />
                            <div className="widget-thumb-body">
                                <span className="widget-thumb-body-stat" data-counter="counterup" data-value={ (data.accountStatus[0] || 0) }>
                                    { (data.accountStatus[0] || 0) }
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* END WIDGET THUMB */}
                </div>
                <div className={cls}>
                    {/* BEGIN WIDGET THUMB */}
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 bordered">
                        <h4 className="widget-thumb-heading"><TransComponent i18nKey="Confirm accounts"/></h4>
                        <div className="widget-thumb-wrap">
                            <i className="widget-thumb-icon bg-blue icon-note" />
                            <div className="widget-thumb-body">
                                <span className="widget-thumb-body-stat" data-counter="counterup" data-value={ (data.accountStatus[1] || 0) }>
                                    { (data.accountStatus[1] || 0) }
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* END WIDGET THUMB */}
                </div>
                <div className={cls}>
                    {/* BEGIN WIDGET THUMB */}
                    <div className="widget-thumb widget-bg-color-white text-uppercase margin-bottom-20 bordered">
                        <h4 className="widget-thumb-heading"><TransComponent i18nKey="Protected accounts"/></h4>
                        <div className="widget-thumb-wrap">
                            <i className="widget-thumb-icon bg-green icon-umbrella" />
                            <div className="widget-thumb-body">
                                <span className="widget-thumb-body-stat" data-counter="counterup" data-value={ (data.accountStatus[2] || 0) }>
                                    { (data.accountStatus[2] || 0) }
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* END WIDGET THUMB */}
                </div>
            </div>
        )
    }
}



export default OverviewContainer