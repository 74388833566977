import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { initListUser, initListAccount } from 'my-actions/manage/ManageImageAction';
import { saveImage } from 'my-actions/manage/ReportImageAction';
import { initListTrick } from 'my-actions/manage/TrickConfigAction';
import { Helpers } from 'my-utils'
// import {} from 'react-toastify'

import {Field, reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';




class AddMoreImageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id : null,
            file: '',
            imagePreviewUrl: '',
            displayImage: true,
            files: [],
            reportImg: false,
            reload: false,
            currentItem: this.props.toggleItem
        }

        this.imageRef = React.createRef();
        this.imageFile = null;
        this.imageFileName = null;
    }

    componentWillMount() {
        this.props.initialize({...this.props.initialValues})

    }


    componentDidUpdate(prevProps) {

        if(this.props.reload !== prevProps.reload) {
            this.setState({
                currentItem: null,
                id: null
            })
            this.props.initialize({})

        }
    }



    componentWillReceiveProps (nextProps, prevProps) {

        if(nextProps.toggleItemImage && (this.state.id !== nextProps.toggleItemImage.id)  ) {
            this.props.initialize({...nextProps.toggleItemImage,
                user: this.props.listUser ? this.props.listUser.find(item => item.value === nextProps.toggleItemImage.user_id) : null,
                trick: this.props.listTrick ? this.props.listTrick.find(item => item.value === nextProps.toggleItemImage.trick_id) : null,
                account: this.props.listAcc ? this.props.listAcc.find(item => item.value === nextProps.toggleItemImage.account_id) : null,
            })

            this.setState({id: nextProps.toggleItemImage.id} )
        }
    }





    handleCreate = e => {
        e.preventDefault();
        let img = this.state.files
        let img_arr = []
        for (const key of Object.keys(img)) {
            img_arr.push(img[key])
        }


        const params = {
            user: {
                _id: _get(this.props.initialValues, 'user.value'),
                name: _get(this.props.initialValues, 'user.label')
            },
            account: {
                _id: _get(this.props.initialValues, 'account.value'),
                name: _get(this.props.initialValues, 'account.label')
            },
            trick: {
                _id: _get(this.props.initialValues, 'trick.value'),
                name: _get(this.props.initialValues, 'trick.label')
            },
            code: _get(this.props.initialValues, 'code', 0),
            imageFile: img_arr,
            // day: new Date(this.props.selectedDay.day).getTime(),
            day: _get(this.props.initialValues, 'day', 0),
        }


        console.log('params----', params)
        console.log('this.props.initialValues----', params)

        this.props.saveImage(Helpers.convertFormData(params))

        this.props.initialize({})
        this.imageRef.current.value = null
        this.setState({
            imagePreviewUrl: '',
            displayImage: true
        })


    }

    handleOnChangeFile = e => {
        e.preventDefault();
        // let arr_img =[]
        // let file = e.target.files
        this.setState({
            files: e.target.files
        })

        let fileReader = new FileReader();
        const image = this.imageRef.current.files[0];
        // const image = this.imageRef.current.files;
        // console.log('imageChangFile', image)
        this.imageFile = image;
        this.imageFileName = image ? image.name : '';
        fileReader.onloadend = () => {
            this.setState({
                file: image,
                imagePreviewUrl: fileReader.result
            });
        }
        image ? fileReader.readAsDataURL(image) : this.setState({imagePreviewUrl: null})

        // image ? fileReader.readAsDataURL(image[0]) : this.setState({imagePreviewUrl: null})
    }





    render() {
        // let {imagePreviewUrl, displayImage} = this.state;
        // let {editStatus, disabledEdit, isSelectDay ,  }= this.props
        let has_file = this.imageRef.current !== null ? this.imageRef.current.files[0] : false
        let show_button = has_file ? true : false
        return (

            <div className="">
                <div className="portlet-body form">
                    <div className="row">
                        <div className="col-md-11">
                            <form name="form_group">
                                <div className="form-body ">

                                    <div className="form-group">
                                        <label><TransComponent i18nKey="Code" /></label>
                                        <Field
                                            name="code"
                                            type="text"
                                            component="input"
                                            className="form-control form-control-solid placeholder-no-fix form-group-time"
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Select Image: </label>
                                        <div className={"input_file_wrapper"}>
                                            <input name="image" type="file" multiple className="form-control input_file" placeholder='Choose a file...'
                                                   ref={this.imageRef} onChange={ e => this.handleOnChangeFile(e)} />
                                        </div>
                                    </div>

                                    <div className=" text-right">
                                        <button type="button" className="btn red" disabled={ !show_button || this.props.invalid  } onClick={this.handleCreate}><TransComponent i18nKey="Create new" /></button>
                                    </div>


                                </div>
                            </form>
                        </div>
                        <div className="col-md-1">

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group.values', {}),
        listUser: state.ManageImageReducer.listUser,
        listAcc: state.ManageImageReducer.listAcc,
        listTrick: state.ManageImageReducer.listTrick,
        toggleItem: state.ReportImageReducer.toggleItem,


    };

};

const mapDispatchToProps = dispatch => {
    return {
        saveImage: params => dispatch(saveImage(params)),
        initListUser: () => dispatch(initListUser()),
        initListAccount: (params) => dispatch(initListAccount(params)),
        initListTrick: () => dispatch(initListTrick()),

    };
};

const validate = values => {
    const errors = {}
    if (!values.user) {
        errors.user = '"user" is required'
    }
    if (!values.account) {
        errors.account = '"account" is required'
    }
    if (!values.trick) {
        errors.trick = '"trick" is required'
    }
    if (!values.type) {
        errors.type = '"type" is required'
    }
    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(AddMoreImageContainer);