import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash'

import { requestInitFormData, saveAccount, resetFormSaveResponse } from 'my-actions/AccountAction'
import { initAccountGroups } from 'my-actions/AccountGroupAction'
import { TransComponent, LoadingComponent } from 'my-components'
import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'

import { AccountService } from 'my-services/account'
import { CookieService } from 'my-utils/core';
import { RolesConfig} from 'my-constants'


const optAccountStatus = [{value: 0, label: <TransComponent i18nKey='Waiting' />},
    {value: 1, label: <TransComponent i18nKey='Confirm' />},
    {value: 2, label: <TransComponent i18nKey='Scanip' />}]
const optIsSub = [{value: 0, label: "Sub"}, {value: 1, label: "Admin"}, {value: 2, label: "Agent"}, {value: 3, label: "Master"},  {value: 4, label: "Super"}]


class FormAccountContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            isWaiting: false
        }
    }

    componentWillMount() {
        this.props.requestInitFormData(_get(this.props, 'account.id'));
        this.props.initAccountGroups()
        // Init Default Value
        if(this.props.formType === "create") {
            this.props.initialize({...this.props.initialValues,
                status: optAccountStatus.find(item => item.value === 0),
                belong_account: {value: "root", label: <TransComponent i18nKey="Is root account" toUpperCase />},
                is_sub: optIsSub.find(item => item.value === 0),
            })
        }
    }

    componentDidUpdate(prevProps){
        // Init Form Incase Edit Item
        if(!_isEqual(prevProps.optBanker, this.props.optBanker) ||!_isEqual(prevProps.optGroups, this.props.optGroups)) {
            if(this.props.formType === 'update' && !_isEmpty(this.props.selectedItem)) {
                const selectedItem = this.props.selectedItem;
                const { acc_parent_id, id, acc_name, sub_user, sub_pass, sub_code, note, is_sub, status, banker, acc_group_id } = selectedItem;
                const optAccountBelong = [{value: 'root', label: <TransComponent i18nKey='Is root account' toUpperCase />}].concat(this.props.optAccountBelong)
                const belong_account_item = acc_parent_id ? acc_parent_id : 'root';
                let belong_account
                if (belong_account_item === 'root') {
                    belong_account = optAccountBelong.find(item => item.value === belong_account_item)
                } else {
                    try {
                        belong_account = optAccountBelong.find(item => item.value === selectedItem.banker_id).options.find(item => item.value === belong_account_item)
                    } catch (e) {
                        // Can not find value
                    }
                }
                this.props.initialize({
                    ...this.props.initialValues,
                    id,
                    company: this.props.optBanker ? this.props.optBanker.find(item => item.value === banker): null,
                    acc_group: this.props.optGroups ? this.props.optGroups.find(item => item.value === acc_group_id): null,
                    acc_name,
                    belong_account: belong_account,
                    sub_user, sub_pass, sub_code, note,
                    is_sub: optIsSub.find(item => item.value === is_sub),
                    status: optAccountStatus.find(item => item.value === status),
                })
                if(selectedItem.status == 0 ) {
                    this.setState({isWaiting: true})
                }
            }
        }
    }
    
    handleSubmit = e => {
        const payload = {
            acc_name: _get(this.props.initialValues, 'acc_name', ""),
            company: _get(this.props.initialValues, 'company.value', ""),
            acc_group: _get(this.props.initialValues, 'acc_group.value', ""),
            acc_group_name: _get(this.props.initialValues, 'acc_group.label', ""),
            banker_name: _get(this.props.initialValues, 'company.name', ""),
            book_name: _get(this.props.initialValues, 'company.book_name', ""),
            need_security: _get(this.props.initialValues, 'company.need_security', ""),
            sub_user: _get(this.props.initialValues, 'sub_user', ""),
            sub_pass: _get(this.props.initialValues, 'sub_pass', ""),
            status: _get(this.props.initialValues, 'status.value', ""),
        }


        if (_get(this.props.initialValues, 'belong_account.value') !== 'root') payload.belong_account = _get(this.props.initialValues, 'belong_account.value', "")
        if (_get(this.props.initialValues, 'belong_account.value') === 'root') payload.check_login = 'yes'
        if (!_isEmpty(_get(this.props.initialValues, 'sub_code'))) payload.sub_code = _get(this.props.initialValues, 'sub_code')
        if (_get(this.props.initialValues, 'is_sub')) payload.is_sub = _get(this.props.initialValues, 'is_sub.value', 0)
        if (!_isEmpty(_get(this.props.initialValues, 'note'))) payload.note = _get(this.props.initialValues, 'note')

        // Incase Edit Item
        if(this.props.formType === "update") {
            payload.id = _get(this.props.initialValues, 'id')
        }

        console.log('payload---', payload)
        this.props.saveAccount(payload)
    }

    renderAlert = _ => {
        const { formSaveStatus, formSaveResponse } = this.props
        if(formSaveStatus === false) {
            return (
                <div className="alert alert-danger">
                    <button className="close" onClick={this.props.resetFormSaveResponse} />
                    <span><b> <TransComponent i18nKey={formSaveResponse.data.message}/>  </b></span>
                </div>
            )
        } else if(formSaveStatus === true) {
            return (
                <div className="alert bg-success">
                    <button className="close" onClick={this.props.resetFormSaveResponse} />
                    <span><b> <TransComponent i18nKey={formSaveResponse.data.message}/>  </b></span>
                </div>
            )
        }
        return null
    }


    render() {
        const {optBanker, formType, isInitSaveFormData, optGroups} = this.props
        const company = _get(this.props.initialValues, 'company')
        const belong_account = _get(this.props.initialValues, 'belong_account')
        const isDisabledBasedOneAccountBelong = _get(belong_account, 'value') === "root" ? false : true
        const role = Number(CookieService.get('roles'))
        const isAgent = role == RolesConfig.AGENT
        let statusValue = _get(this.props.initialValues, 'status.value', 0)
        let {isWaiting} = this.state
        console.log('isWaiting---', isWaiting)
        return (
            <form name="form_account" className="position-relative overload-hidden">
                { _isEmpty(optBanker) ? <LoadingComponent/> : null}
                { isInitSaveFormData ? <LoadingComponent/> : null }
                <div className="form-body">
                    {this.renderAlert()}
                    <div className="form-group ">
                        <label><TransComponent i18nKey="Company" /></label>
                        <Field
                            name="company"
                            className="basic-single"
                            component={renderSelectField}
                            isSearchable={true}
                            options={optBanker}
                            // isDisabled={isWaiting ? false : (formType === "update" && isAgent) ? true : false}
                            isDisabled={!isWaiting && formType === "update"}
                            />
                        <Field name="company"component={renderError} />
                    </div>
                    {
                        formType === "update" || _get(belong_account, 'value') !== "root" ?
                            <div className="form-group ">
                                <label><TransComponent i18nKey="Account name" /></label>
                                <Field
                                    name="acc_name"
                                    type="text"
                                    component="input"
                                    className="form-control form-control-solid placeholder-no-fix"
                                    autoComplete="off"
                                    // readOnly={isWaiting ? false : (formType === "update" && isAgent) ? true : false}
                                    readOnly={!isWaiting && formType === "update"}
                                />
                                <Field name="acc_name"component={renderError} />
                            </div>
                        : null
                    }

                    <div className="form-group">
                        <label><TransComponent i18nKey="Sub user" /></label>
                        <Field
                            name="sub_user"
                            type="text"
                            component="input"
                            className="form-control form-control-solid placeholder-no-fix"
                            autoComplete="off"
                            // readOnly={isWaiting ? false : (formType === "update" && isAgent) ? true : false}
                            readOnly={!isWaiting && formType === "update"}
                        />
                    </div>
                    <div className="form-group">
                        <label><TransComponent i18nKey="Sub password" /></label>
                        <Field
                            name="sub_pass"
                            type="text"
                            component="input"
                            className="form-control form-control-solid placeholder-no-fix"
                            autoComplete="off"
                        />
                    </div>
                    {
                        _get(company, 'need_security', false) ? (
                        <div className="form-group">
                            <label><TransComponent i18nKey="Secure code" /></label>
                            <Field
                                name="sub_code"
                                type="text"
                                component="input"
                                className="form-control form-control-solid placeholder-no-fix"
                                autoComplete="off"
                            />
                            <Field name="sub_code"component={renderError} />
                        </div>
                        ) : null
                    }
                    {
                        role === RolesConfig.ADMIN || role === RolesConfig.SALE ?
                            <div className="form-group">
                                <label><TransComponent i18nKey="Is Active" /></label>
                                <Field
                                    name="status"
                                    className="basic-single"
                                    component={renderSelectField}
                                    isSearchable={false}
                                    options={optAccountStatus}
                                />
                            </div> : null
                    }
                    <div className="form-group">
                        <label><TransComponent i18nKey="Group" /></label>
                        <Field
                            name="acc_group"
                            className="basic-single"
                            component={renderSelectField}
                            isSearchable={false}
                            options={optGroups}
                        />
                        <Field name="acc_group"component={renderError} />
                    </div>
                    <div className="form-group">
                        <label><TransComponent i18nKey="Note" /></label>
                        <Field
                            name="note"
                            type="text"
                            component="input"
                            className="form-control form-control-solid placeholder-no-fix"
                            autoComplete="off"
                        />
                    </div>

                    <div className="form-actions text-right">
                        <button type="button" className="btn red" disabled={this.props.invalid} onClick={this.handleSubmit}><TransComponent i18nKey="Save" /></button>
                    </div>
                </div>
            </form>
        )
    }
}

const asyncValidate = (values, dispatch, props, currentFieldName) => {
    const errors = {}
    return new Promise((resolve, reject) => {
        //Validate fullname
        if(currentFieldName === "acc_name") {
            const payload = {
                'value[acc_name]': values.acc_name,
                'value[banker_id]': values.company.value
            }
            AccountService.validatorAccount(payload).then(res => {
                if(res.status === false) {
                    errors.acc_name = res.res.data.message
                    return reject(errors)
                }
            })
        }
    });
}

const validate = values => {
    const errors = {}
    if (!_get(values, 'company.value')) {
        errors.company = 'Company is invalid'
    }
    if (!values.acc_name) {
        errors.acc_name = '"fullname" is not allowed to be empty'
    }
    if (!values.sub_code) {
        errors.sub_code = '"Sub code" is not allowed to be empty'
    }
    if (!_get(values, 'acc_group.value')) {
        errors.acc_group = '"Group" is invalid'
    }
    return errors
}

const mapStateToProps = state => {
    return {
        //Handle Loading
        isInitSaveFormData : state.AccountReducer.isInitSaveFormData,

        initialValues: _get(state, 'form.form_account.values', {}),
        initFormData : state.AccountReducer.initFormData,
        optBanker : state.AccountReducer.optBanker,
        optGroups: state.AccountGroupReducer.optGroups,
        optAccountBelong : state.AccountReducer.optAccountBelong,
        selectedItem : state.AccountReducer.selectedItem,
        formSaveStatus : state.AccountReducer.formSaveStatus,
        formSaveResponse : state.AccountReducer.formSaveResponse,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initAccountGroups: _ => dispatch(initAccountGroups()),
        requestInitFormData: (accountId) => {dispatch(requestInitFormData(accountId))},
        saveAccount: payload => dispatch(saveAccount(payload)),
        resetFormSaveResponse: _ => dispatch(resetFormSaveResponse()),
    }
};

export default compose(
    reduxForm({
        form: 'form_account',
        validate,
        asyncValidate: asyncValidate,
        asyncChangeFields: [ 'acc_name' ]
    }),
    connect(mapStateToProps, mapDispatchToProps),
)(FormAccountContainer);