import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {  Modal, ModalHeader, ModalBody } from 'reactstrap'
import 'my-styles/reactstrap-modal.css'

import FormCreateUserContainer from './FormCreateUserContainer'
import { toggleModalUser} from 'my-actions/manage/UserImageAction'
import { TransComponent } from 'my-components'

class ModalFormContainer extends Component {
    componentDidUpdate() {
        // Hide modal after save success
        if(this.props.formSaveStatus && this.props.isOpenModal) {
            this.props.toggleModalUser()
        }
    }

    render() {
        const modalTitle = this.props.formType === "create" ? <TransComponent i18nKey="Create account" /> : <TransComponent i18nKey="Update account" />;

        return (
            <Modal isOpen={this.props.isOpenModal} toggle={_ => this.props.toggleModalUser()} scrollable={true}>
                <ModalHeader toggle={_ => this.props.toggleModalUser()}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <FormCreateUserContainer {...this.props} />
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        isOpenModal: state.UserImageReducer.isOpenModal,
        formSaveStatus: state.UserImageReducer.formSaveStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModalUser:  _ => dispatch(toggleModalUser()),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(ModalFormContainer);
