import React, { Component } from 'react';
import { UserImageService } from 'my-services/manage';
import { copyImageToClipboard } from 'copy-image-clipboard'
import { toast } from "react-toastify";

class GetQRCode extends Component {
    getAndCopyQRCode = () => {
        UserImageService.getQRCode({username: this.props.username})
            .then(function (res) {
                const base64 = res.res.data;
                return copyImageToClipboard(base64)
            })
            .then(() => {
                toast.success('Đã copy qrcode')
            })
            .catch(function (error) {
                toast.error(error.message)
            })
    }

    render() {
        return (
            <button className="btn btn-sm btn-primary" onClick={this.getAndCopyQRCode}>
               Lấy QRCode
            </button>
        );
    }
}

export default GetQRCode;
