import { get as _get, sortBy as _sortBy } from 'lodash'
import { AccountActionType } from 'my-constants/action-types'
import moment from 'moment'
let defaultState = {
	isInitSaveFormData: false,

	initFormData: {},
	optBanker: [],
	optAccountBelong: [],

	//Handle Modal Delete Member
	isOpenModalDelete: false,
	formDeleteStatus: null,
	formDeleteResponse: {},

	//Handle Modal Form Member
	selectedItem: {},
	isOpenModal: false,
	isOpenLinkFormula: false,

	//Handel Save Form
	formSaveStatus: null,
	formSaveResponse: {},

	lstTab: [],
	lstAccount: [],
	lstOpen: [],

	modalLinkFormula: {
		lstMember: [],
		lstFormula: [],
		lstFormulaGroup: [],
	},

	//Image
    visibleImage: false,
    closeImage: true
};

export const AccountReducer = (state = defaultState, action) => {
	switch(action.type){
		case AccountActionType.ACCOUNT_RESET_STORE:
            return {...defaultState}
		case AccountActionType.ACCOUNT_TOGGLE_IMAGE:
            let imageList = action.listImageUser
            let imageListEdit = []
            if(imageList) {
                imageList.forEach( item => {
                    imageListEdit.push({
                        alt: `${moment(item.from_date).format('DD/MM/YYYY')} - ${moment(item.to_date).format('DD/MM/YYYY')}`,
                        src: item.image_url,
                        from_date_f: new Date(item.from_date),
                        to_date_f: new Date(item.to_date),
                    })
                })
            }
            imageListEdit.sort((a,b) => b.from_date_f.getTime() - a.from_date_f.getTime() || a.to_date_f.getTime() - b.to_date_f.getTime())

            return {...state, imageListEdit: imageListEdit,visibleImage: action.visibleImage}
		case AccountActionType.ACCOUNT_CLOSE_IMAGE:
            return {...state, visibleImage: action.visibleImage}
		case AccountActionType.ACCOUNT_INIT_FORM_DATA:
			let optBanker = []
			let optAccountBelong = []
			let bankerList = _get(action.initFormData, 'bankerList')
			let accountList = _get(action.initFormData, 'accountList')
			if(bankerList) {
				bankerList = _sortBy(bankerList, [o => o.name.toLowerCase()])
				accountList = _sortBy(accountList, [o => o.acc_name.toLowerCase()])
				for(let x in bankerList) {
					optBanker.push({ ...bankerList[x], value: bankerList[x].id, label: bankerList[x].name.toUpperCase()})

					let groupOtion = accountList.filter(item => item.banker_id === bankerList[x].id)
					groupOtion.forEach(item => {
						item.value = item.id
						item.label = item.acc_name.toUpperCase()
					})
					optAccountBelong.push({label: bankerList[x].name.toUpperCase(), value: bankerList[x].id, options: groupOtion})
				}
			}
			return {...state, initFormData: action.initFormData, optBanker: optBanker, optAccountBelong: optAccountBelong}
		case AccountActionType.ACCOUNT_TOGGLE_MODAL_FORM:
			let newState = {
				...defaultState,
				lstTab: state.lstTab,
				lstAccount: state.lstAccount,
				isFetchingAccount: state.isFetchingAccount,
			};
			//Reset Store When Modal Close
			if (!state.isOpenModal === true && !action.isModalLinkFormula) {
				newState = {...state, selectedItem: action.selectedItem, isInfo: action.isInfo, isOpenModal: true};
			}

			if (!state.isOpenLinkFormula === true && action.isModalLinkFormula) {
				newState = {...state, selectedItem: action.selectedItem, isInfo: action.isInfo, isOpenLinkFormula: true};
			}

			return newState;

		case AccountActionType.ACCOUNT_TOGGLE_MODAL_DELETE:
			//Reset Store When Modal Close
			if (!state.isOpenModalDelete === false) return {...defaultState}
			return {...state, selectedItem: action.selectedItem, isOpenModalDelete: !state.isOpenModalDelete};
		case AccountActionType.ACCOUNT_SAVE_FORM_DATA:
			return {...state, isInitSaveFormData: action.isInitSaveFormData, formSaveStatus: action.formSaveStatus, formSaveResponse: action.formSaveResponse}
		case AccountActionType.ACCOUNT_DELETE_ITEM:
			return {...state, formDeleteStatus: action.formDeleteStatus, formDeleteResponse: action.formDeleteResponse}
		case AccountActionType.ACCOUNT_RESET_FORM_RESPONSE:
			return {...state, formSaveStatus: null, formSaveResponse: {}};

		case AccountActionType.GET_TAB_SUCCESS:
			return { ...state, lstTab: action.payload, error: null };
		case AccountActionType.GET_TAB_FAIL:
			return { ...state, error: action.payload };

		case AccountActionType.GET_ACCOUNT:
			return { ...state, error: null, isFetchingAccount: true };
		case AccountActionType.GET_ACCOUNT_SUCCESS:
			return { ...state, lstAccount: action.payload, isFetchingAccount: false, error: null };
		case AccountActionType.GET_ACCOUNT_FAIL:
			return { ...state, isFetchingAccount: false, error: action.payload };

		case AccountActionType.SET_LIST_OPEN:
			return { ...state, lstOpen: action.lstOpen };

		default:
			return {...state}
	}
};


export default AccountReducer;