import { PolicyActionType } from 'my-constants/action-types';

import { get } from 'lodash';

let defaultState = {
    errors: {},
    saveStatus: {},
    deleteStatus: {},
    response: {},
    listPolicy: [],
    disabledEdit: false,
}

const PolicyReducer = (state = defaultState, action) => {
    switch(action.type) {
        case PolicyActionType.GET_LIST_POLICY_SUCCESS:
            let listPolicy = get(action.payload, 'res.data.List', [])
            let scrPolicy = listPolicy.map((item, index) => {
                return {
                    src: item.image_url,
                    index: index,
                    alt: item.image_name
                }
            })
            return {...state, listPolicy: listPolicy, scrPolicy: scrPolicy, errors: {}, reload: false, editStatus: false}
        case PolicyActionType.GET_LIST_POLICY_FAIL:
            return {...state, errors: action.payload};

        case PolicyActionType.SAVE_IMAGE_SUCCESS:
            return {...state, saveStatus: action.saveStatus, selectedItem: {id: null}, reload: action.saveStatus, editStatus: false}
        case PolicyActionType.SAVE_IMAGE_FAIL:
            return {...state, errors: action.payload};

        case PolicyActionType.DELETE_IMAGE_SUCCESS:
            return {...state, deleteStatus: action.saveStatus, reload: action.deleteStatus}
        case PolicyActionType.DELETE_IMAGE_FAIL:
            return {...state, errors: action.payload};
        default:
            return {...state};
    }

}

export default PolicyReducer
