import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
// import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { getListUserImage, deleteImage } from 'my-actions/manage/ManageImageAction';
// import {Field} from "redux-form";
import ReactPaginate from 'react-paginate';
import { Multiselect } from "react-widgets";
import 'react-widgets/dist/css/react-widgets.css'
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'

import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';

/** Import copy to clip board components */
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

/** Import Modals By Action Containers */
import { ModalByActionContainer } from 'my-containers/manage/user_image';

/** Import User Actions */
import { resetUser} from 'my-actions/manage/UserImageAction';

import { toast } from "react-toastify";
import { GetQRCode } from "../../../components/manage";



class ListImageContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            filters: [],
            offset: 0,
            data: [],
            perPage: 20,
            currentPage: 0,
            visibleImage: false,
            imageSrc: [],
            isFillter: false

        }
        this.handlePageClick = this
            .handlePageClick
            .bind(this);
    }

    componentDidMount() {
        this.props.getListUserImage()

    }

    componentWillReceiveProps (nextProps, prevProps) {
        if (nextProps.displayItem && Object.keys(nextProps.displayItem).length != 0) {
           this.setState({isFillter: true})
        }

    }

    componentDidUpdate() {
        // refresh data
        if(this.props.reload) {
            this.props.getListUserImage();
        }
    }


    handleChangeUsers = item => {
        this.setState({
            filters: item ,
            currentPage: 0,
            isFillter: item.length > 0 || false
        })
    }

    handleToggleImage = item => {
        let imageSrc = []
        imageSrc.push({
            src: item.image_url
        })
        this.setState({
            imageSrc : imageSrc,
            visibleImage: !this.state.visibleImage
        })
    }

    handleDeleteUserImage = item => {

        const params = {
            id: item.selectedItem.id
        }
        this.props.deleteImage(params)
    }


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });

    };

    handleFilterUser = (e) => {
        console.log('event--', e.target.value)
        const {value} = e.target
        if(!value) {
            this.setState({ offset: 0, currentPage: 0})
        }
        this.setState({filter: value, currentPage: 0, offset: 0})
    }

    handleCloseImage = () => {

        this.setState({visibleImage: false})
    }

    handleResetUser = item => {
        const params = {
            selectedItem: {
                id: item.user_id,
                username: item.user,
            }
        }
        this.props.resetUser(params)
    }

    onCopyLinkSuccess = () => {
        toast.success("Đã copy link")
    }

    renderBody(type) {
        let {listUserImage, listUser, displayItem, listUserImageOP6} = this.props
        const isAdminOP6 = Number(CookieService.get('roles')) === RolesConfig.ADMINOP6
        let checkIsEmpty = Object.keys(displayItem).length == 0
        if(!checkIsEmpty) {
            let filterArrs = []
            Object.keys(displayItem).forEach(item => {
                filterArrs.push(displayItem[item].value)
            })
            // listUserImage = listUserImage.filter(item => {
            //     return filterArrs.indexOf(item.user_id) > -1 || filterArrs.indexOf(item.account_id) > -1
            // })
            if(filterArrs.length == 1) {
                listUserImage = listUserImage.filter(item => {
                    return filterArrs.indexOf(item.user_id) > -1
                })
                listUserImageOP6 = listUserImageOP6.filter(item => {
                    return filterArrs.indexOf(item.user_id) > -1
                })
            } else {
                listUserImage = listUserImage.filter(item => {
                    return filterArrs.indexOf(item.user_id) > -1 && filterArrs.indexOf(item.account_id) > -1
                })
            }

        }
        if (this.state.filters.length > 0) {
            let filters = this.state.filters.map(function (item) {
                return item.value
            })
            listUserImage = listUserImage.filter(function (item) {
                return filters.indexOf(item.user_id) > -1
            })
            listUserImageOP6 = listUserImageOP6.filter(function (item) {
                return filters.indexOf(item.user_id) > -1
            })


        }


        let pageCount = Math.ceil((isAdminOP6 ? listUserImageOP6.length : listUserImage.length )/ this.state.perPage)
        console.log('pagecount---', pageCount)
        listUserImage  = listUserImage.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        listUserImageOP6  = listUserImageOP6.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);



        if(type == 'OP6') {
            return listUserImageOP6.length ?
                listUserImageOP6.map( (item, index) => {
                    return (
                        <tr key={index}>
                            <td> {this.state.currentPage * this.state.perPage + index + 1} </td>
                            <td className="uppercase">
                                <button type="button" className="btn btn-primary" onClick={ () => this.handleToggleImage(item)}> {item.image_name}</button>
                            </td>
                            <td className="uppercase">{item.user}</td>
                            <td className="uppercase">{item.created_by}</td>
                            <td className="uppercase">{item.created}</td>
                            <td className="uppercase">{item.status ? <span style={{color: 'blue'}}> Đã xem </span> :  <span style={{color: 'red'}}> Chưa xem </span>}</td>
                            <td className="uppercase">
                                {/* Copy link button */}
                                <CopyToClipboard text={item.link} onCopy={this.onCopyLinkSuccess}>
                                    <i className='fa fa-copy fa-2x' style={{cursor: 'pointer'}}/>
                                </CopyToClipboard>
                            </td>
                            <td className="uppercase">
                                <GetQRCode username={item.user}/>
                            </td>
                            <td className="uppercase">
                                <button className="btn btn-sm btn-danger" onClick={this.handleResetUser.bind(this, item)}>Đặt lại mật khẩu</button>
                            </td>
                            <td>
                                <button className="text-success btn btn-link font-red" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.handleDeleteUserImage({selectedItem:item})}>
                                    <i className="fa fa-close" />
                                </button>

                            </td>
                        </tr>
                    )
                })
                : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
        }

        return listUserImage.length ?
            listUserImage.map( (item, index) => {
                return (
                    <tr key={index}>
                        <td> {this.state.currentPage * this.state.perPage + index + 1} </td>

                        <td className="uppercase">
                            <button type="button" className="btn btn-primary" onClick={ () => this.handleToggleImage(item)}> {item.image_name}</button>
                        </td>
                        {/*<td className="uppercase">*/}
                        {/*<button type="button" className="btn btn-primary"> {item.image_name}</button>*/}
                        {/*</td>*/}
                        <td className="uppercase">{item.user}</td>
                        <td className="uppercase">{item.account}</td>
                        <td className="uppercase">{item.day}</td>
                        <td className="uppercase">{item.code}</td>
                        <td className="uppercase">{item.trick_name}</td>
                        <td className="uppercase">{item.created}</td>
                        <td>
                            <button className="text-success btn btn-link font-red" data-toggle="modal"  data-target="#exampleModal" onClick={ () => this.handleDeleteUserImage({selectedItem:item})}>
                                <i className="fa fa-close" />
                            </button>

                        </td>
                    </tr>
                )
            })
            : <tr><td className="text-center" colSpan="20"><TransComponent i18nKey="Data Empty" /></td></tr>
    }


    render() {
        let {listUserImage, listUser, displayItem, listUserImageOP6,listUserOP6 } = this.props
        const isAdminOP6 = Number(CookieService.get('roles')) === RolesConfig.ADMINOP6
        let pageCount = Math.ceil((isAdminOP6 ? listUserImageOP6.length : listUserImage.length) / this.state.perPage)
        listUserImage  = listUserImage.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        listUserImageOP6  = listUserImageOP6.slice(this.state.currentPage * this.state.perPage, (this.state.currentPage + 1) * this.state.perPage);
        return (
            <div className="portlet light bordered">
                <div className="row portlet-title">
                    <div className="col-md-9 caption">
                        <span className="caption-subject font-green bold uppercase"><TransComponent i18nKey="List Images" /></span>
                    </div>
                    <div className=" col-md-3 form-group" >
                        <Multiselect
                            data={isAdminOP6 ? listUserOP6 : listUser}
                            value={this.state.filters}
                            valueField='value'
                            textField='label'
                            onChange={this.handleChangeUsers}
                        />
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="table-responsive">
                        {/*<div  className='text-right'>*/}
                           {/*<button type="button" className="btn red"  onClick={this.handleDels}><TransComponent i18nKey="Delete" /></button>*/}
                        {/*</div>*/}
                        <table className="table table-hover">
                            <thead>
                            <tr className="font-red-sunglo">
                                <th>#</th>
                                <th className="uppercase"><TransComponent i18nKey="Image"/></th>
                                <th className="uppercase"><TransComponent i18nKey="User"/></th>
                                {!isAdminOP6 && <th className="uppercase"><TransComponent i18nKey="Account"/></th>}
                                {!isAdminOP6 && <th className="uppercase"><TransComponent i18nKey="Day"/></th>}
                                {!isAdminOP6 && <th className="uppercase"><TransComponent i18nKey="Mã"/></th>}
                                {!isAdminOP6 && <th className="uppercase"><TransComponent i18nKey="Loại"/></th>}
                                {isAdminOP6 && <th className="uppercase"><TransComponent i18nKey="KTV Tạo"/></th>}
                                <th className="uppercase"><TransComponent i18nKey="Ngày tạo"/></th>
                                <th className="uppercase"><TransComponent i18nKey="Status"/></th>
                                {isAdminOP6 && <th className="uppercase">Link</th>}
                                {isAdminOP6 && <th className="uppercase">QRCode</th>}
                                {isAdminOP6 && <th className="uppercase">Reset & Copy Mật Khẩu</th>}
                                <th className="uppercase"><TransComponent i18nKey="Delete" /></th>
                                {/*<th className="uppercase"><TransComponent i18nKey="Select" /></th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {isAdminOP6 ? this.renderBody('OP6') : this.renderBody('VSCAN')}
                            <Viewer
                                visible={this.state.visibleImage}
                                images={this.state.imageSrc}
                                onClose={this.handleCloseImage}
                                zoomSpeed='0.5'
                                rotatable={ false}
                                scalable={ false}
                                onMaskClick={this.handleCloseImage}
                            />
                            </tbody>
                        </table>
                        {pageCount > 1 && !this.state.isFillter &&
                        <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                        }

                    </div>
                </div>

                <ModalByActionContainer />
            </div>

        );
    }
}

const mapStateToProps = state => {
    // console.log('STATE===', state.ManageImageReducer)
    return {
        reload: state.ManageImageReducer.reload,
        listUser: state.ManageImageReducer.listUser,
        listUserOP6: state.ManageImageReducer.listUserOP6,
        listUserImage: state.ManageImageReducer.listUserImage,
        listUserImageOP6: state.ManageImageReducer.listUserImageOP6,

    };
};

const mapDispatchToProps = dispatch => {
    // console.log('Dispatch==', dispatch)
    return {
        getListUserImage: (params) => dispatch(getListUserImage(params)),
        deleteImage: params => dispatch(deleteImage(params)),

        /** Toggle modal reset password */
        resetUser: (params) => dispatch(resetUser(params)),
    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ListImageContainer);