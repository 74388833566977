import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class UserImageService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getUser(post){
        return HttpService.post(`${this.serviceUrl}/user_image/get`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveUser(post){
        console.log("POSTCLient====", post)
        return HttpService.post(`${this.serviceUrl}/user_image/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteUser(post){
        return HttpService.post(`${this.serviceUrl}/user_image/delete`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Get QRCode -> Scan to get login link
    |--------------------------------------------------------------------------
    */
    getQRCode(post){
        return HttpService.post(`${this.serviceUrl}/user_image/get_qrcode`, post);
    };
}

export default new UserImageService()