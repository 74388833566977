export const INIT_LIST_USER = 'INIT_LIST_USER';

export const INIT_LIST_ACCOUNT = 'INIT_LIST_ACCOUNT';

export const CHANGE_DAY_SEARCH = 'CHANGE_DAY_SEARCH';

export const RESET_SELECT_DAY_SEARCH = 'RESET_SELECT_DAY_SEARCH';

export const CHANGE_DAY = 'CHANGE_DAY';

export const RESET_SELECT_DAY = 'RESET_SELECT_DAY';

export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';

export const GET_REPORT_TOTAL_SUCCESS = 'GET_REPORT_TOTAL_SUCCESS';
export const GET_REPORT_TOTAL_FAIL = 'GET_REPORT_TOTAL_FAIL';

export const SAVE_REPORT_SUCCESS= 'SAVE_REPORT_SUCCESS'
export const SAVE_REPORT_FAIL = 'SAVE_REPORT_FAIL'

export const EDIT_REPORT = 'EDIT_REPORT'

export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'
export const DELETE_REPORT_FAIL = 'DELETE_REPORT_FAIL'
export const CHANGE_INPUT = 'CHANGE_INPUT'
export const CHANGE_EDIT_ITEM = 'CHANGE_EDIT_ITEM'
export const ADD_EXTRA_ITEM = 'ADD_EXTRA_ITEM'




