/*========GET CONFIG LIST========*/
export const GET_CONFIG_SUCCESS   = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAIL      = 'GET_CONFIG_FAIL';

/*========SAVE CONFIG ========*/
export const SAVE_CONFIG_SUCCESS   = 'SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAIL      = 'SAVE_CONFIG_FAIL';

/*========LOAD CONFIG TO LIST========*/
export const LOAD_CONFIG      = 'LOAD_CONFIG';
/*========CHANGE INPUT========*/
export const CHANGE_INPUT      = 'CHANGE_INPUT';