import React from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import moment from 'moment';
import Helmet from 'react-helmet';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { selectDaySearch, resetSelectDaySearch, selectDay, resetSelectDay } from 'my-actions/ReportUserAction';

import { formatDate, parseDate } from 'react-day-picker/moment';



class DayReportTotalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = {
            from: undefined,
            to: undefined,
            singleday: undefined,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reload) {
            this.setState({
                from: undefined,
                to: undefined,
                singleday: undefined,
            });
        }
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    handleFromChange(from) {
        this.setState({ from });

    }

    handleToChange(to) {
        this.setState({ to }, this.showFromMonth);
        if(this.state.from) {
            if(this.props.isReport) {
                this.props.dataFromSelectDay({
                    selectDay: {
                        from: this.state.from,
                        to: to,
                    }
                })

            } else {
                this.props.dataFromSelectDay({
                    selectDay: {
                        from: this.state.from,
                        to: to,
                    }
                })
            }
        }
    }

    handleResetClick() {
        this.setState({
            from: undefined,
            to: undefined,
            singleday: undefined,
        }, () => {
            if(this.props.isReport) {
                this.props.dataFromSelectDay({
                    selectDay: null
                })
            } else {
                this.props.dataFromSelectDay({
                    selectDay: null
                })
            }

        });
    }

    singleInput(singleday) {

        return (
            <>
                <DayPickerInput
                    value={singleday}
                    onDayChange={this.handleSingleDayChange}
                    dayPickerProps={{selectedDays: singleday}}
                    format="DD/MM/YYYY"
                    placeholder="Chọn ngày"
                    formatDate={formatDate}
                    parseDate={parseDate}

                />
                {singleday && (
                    <button className="link" onClick={this.handleResetClick}>
                        Reset
                    </button>
                )}
            </>
        )
    }


    fromToInput(modifiers, from, to) {
        return (
            <div className="InputFromTo" style={{ minWidth: '236px', padding: '6px', paddingLeft: '0px'}}>
                <DayPickerInput
                    inputProps={{ style: { width: 100} }}
                    value={from}
                    placeholder="From"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 1,
                    onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                />{' '}
                —{' '}
                <span className="InputFromTo-to">
                <DayPickerInput
                    ref={el => (this.to = el)}
                    inputProps={{ style: { width: 100 } }}
                    value={to}
                    placeholder="To"
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 1,
                    }}
                    onDayChange={this.handleToChange}
                />
            {from && to && (
                <button className="link" onClick={this.handleResetClick}>
                Reset
                </button>
            )}
            </span>
            <Helmet>
                <style>{`
                    .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                    background-color: #f0f8ff !important;
                    color: #4a90e2;
                    }
                    .InputFromTo .DayPicker-Day {
                    border-radius: 0 !important;
                    }
                    .InputFromTo .DayPicker-Day--start {
                    border-top-left-radius: 50% !important;
                    border-bottom-left-radius: 50% !important;
                    }
                    .InputFromTo .DayPicker-Day--end {
                    border-top-right-radius: 50% !important;
                    border-bottom-right-radius: 50% !important;
                    }
                    .InputFromTo .DayPickerInput-Overlay {
                    width: 270px;
                    }
                    .InputFromTo-to .DayPickerInput-Overlay {
                    margin-left: -98px;
                    }
                `}</style>
            </Helmet>
        </div>
        )
    }



    render() {


        const { from, to, singleday  } = this.state;

        const modifiers = { start: from, end: to };

        let renderSelect = null
        if(this.props.selectType == 'single') {
            renderSelect = this.singleInput(singleday)
        } else {
            renderSelect = this.fromToInput(modifiers, from, to)
        }
        return (
            <>
                {renderSelect}
            </>


        );
    }
}
const mapStateToProps = state => {
    return {
        reload: state.ManageImageReducer.reload || state.ReportUserReducer.reload,
    };
};

const mapDispatchToProps = dispatch => {

    return {

    };
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(DayReportTotalContainer);