import { get } from 'lodash';

import { TrickConfigActionType} from 'my-constants/action-types';
import { TrickConfigService } from 'my-services/manage';
import _get from "lodash/get";
import {toast} from "react-toastify";

export const initListTrick = () => {
    return dispatch => {
        dispatch({
            type: TrickConfigActionType.INIT_LIST_TRICK
        })
        return TrickConfigService.getTrick()
            .then(res => {
                if(res.status) {
                    const listTrickInit = res.res.data.List.map(item => ({
                        value: item.id,
                        label: item.trick.toUpperCase(),
                    }))

                    //Dispatch data to reducer
                    dispatch({
                        type: TrickConfigActionType.INIT_LIST_TRICK,
                        listTrickInit: listTrickInit

                    })
                }
            })
    }
}

export const saveTrick = (post) => {
    return dispatch => {
        return TrickConfigService.saveTrick(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: TrickConfigActionType.SAVE_TRICK_SUCCESS,
                        saveStatus: res.status
                    })
                    toast.success("SUCCESS !!!");


                } else {
                    dispatch({
                        type: TrickConfigActionType.SAVE_TRICK_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: TrickConfigActionType.SAVE_TRICK_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const getTrick = (post) => {
    return dispatch => {
        return TrickConfigService.getTrick(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: TrickConfigActionType.GET_TRICK_SUCCESS,
                        payload: res,
                    });
                }
            })

    }
};

export const deleteTrick = (post) => {
    return dispatch => {
        return TrickConfigService.deleteTrick(post)
            .then(res => {
                if (res.status) {
                    dispatch({
                        type: TrickConfigActionType.DELETE_TRICK_SUCCESS,
                        deleteStatus: res.status,
                    });
                    toast.success("SUCCESS !!!");
                } else {
                    dispatch({
                        type: TrickConfigActionType.DELETE_TRICK_FAIL,
                        payload: {
                            status: false,
                            error_description: _get(res, 'res.data.message', '')
                        },
                    });
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch(e => {
                dispatch({
                    type: TrickConfigActionType.DELETE_TRICK_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                });
            })
    }
};

export const editTrick = (post) => {
    return dispatch => {
        dispatch({
            type: TrickConfigActionType.EDIT_TRICK,
            selectedItem: post.selectedItem
        });
    }
};


