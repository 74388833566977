import React, {Component} from "react";
import { TrickConfigContainer } from 'my-containers/manage/trick_config'

export default class TrickConfigPage extends Component {
    render() {
        return  (
            <div className="portlet light bordered">
                <div className="portlet-body">
                    <TrickConfigContainer/>
                    {/*<h1>Trick config container</h1>*/}
                </div>
            </div>
        )
    }
}

