import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form';
import { TransComponent } from 'my-components'
import { initListUser, initListAccount, saveReport_2 } from 'my-actions/ReportUserAction';
import { CookieService } from 'my-utils/core'
import { Helpers } from 'my-utils'
import { RolesConfig} from 'my-constants'
import { ListReportContainer_2, ListReportTotalContainer_2, DayReportTotalContainer } from 'my-containers/report_user_2'
import { DayPickerContainer } from 'my-containers/manage'
import {Field, reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


class ReportUserContainer_2 extends Component {
    state = {
        id : null,
        wl_credit: 0,
        selectDayObj: null,
        isSelectDay: false,
        saveStatus: false
    }

    componentDidMount() {
        this.props.initialize({...this.props.initialValues})
        this.props.initListUser()
    }


    componentWillReceiveProps (nextProps, prevProps) {
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            let calcu_credit = nextProps.initialValues.credit - nextProps.total_credit_wl

            this.props.initialize({
                ...nextProps.selectedItem,
                user: this.props.listUser.find(item => item.value === nextProps.selectedItem.user_id),
                credit_winloss: nextProps.total_credit_wl ,
                last_credit: calcu_credit

            })

            this.setState(
                {
                    id: nextProps.selectedItem.id
                })

        }
        // console.log('nextProps.total_credit_wl----', nextProps.total_credit_wl)
        // console.log('this.props.total_credit_wl----', this.props.total_credit_wl)
        // if(nextProps.total_credit_wl && nextProps.total_credit_wl !== this.props.total_credit_wl) {
        if(  nextProps.total_credit_wl !== this.props.total_credit_wl) {
            // let calcu_credit = nextProps.initialValues.credit - nextProps.total_credit_wl
            //console.log('credit---', nextProps.initialValues.credit, typeof nextProps.initialValues.credit)
            //console.log('total_credit_wl---', nextProps.total_credit_wl, typeof nextProps.total_credit_wl)
            let calcu_credit = +nextProps.initialValues.credit.replace(/,/g,'') + nextProps.total_credit_wl
            this.props.initialize({
                ...nextProps.initialValues,
                // credit_winloss: nextProps.total_credit_wl ,
                // last_credit: calcu_credit,
                credit_winloss: Helpers.formatMoney(nextProps.total_credit_wl, 2) ,
                // last_credit: Helpers.formatMoney(),
                last_credit: Helpers.formatMoney(calcu_credit, 2),
            })

        }



    }

    componentDidUpdate(prevProps) {
        if(this.props.reload) {
            console.log('zooo-reload--')
            this.props.initListUser();

        }
    }


    handleCreate = e => {
        e.preventDefault();
        let {selectDayObj} = this.state
        const params = {
            // id: this.state.id,
            user_id: _get(this.props.initialValues, 'user.value'),
            // from_date: Number(new Date(this.props.selectedDay.from).getTime()),
            // to_date: Number(new Date(this.props.selectedDay.to).getTime()),
            from_date: Number(new Date(selectDayObj.from).getTime()),
            to_date: Number(new Date(selectDayObj.to).getTime()),
            credit: Number(_get(this.props.initialValues, 'credit').replace(/,/g,'')),
            credit_winloss: Number(_get(this.props.initialValues, 'credit_winloss', 0).replace(/,/g,'')),
            last_credit: Number(_get(this.props.initialValues, 'last_credit', 0).replace(/,/g,'')),
            note: _get(this.props.initialValues, 'note'),
            listAccUpdate: JSON.stringify(this.props.listAccount)
        }


        console.log('param----', params)
        this.setState({selectDayObj: null })
        this.props.saveReport_2(params)
        this.props.initialize({})



    }

    handleSelectDay = (data) => {

        console.log('data---', data, data.selectDay)
        this.setState({selectDayObj: data.selectDay})
    }

    handleUpdate = e => {
        e.preventDefault();

        const params = {
            id: this.state.id,
            credit_wl: _get(this.props.selectedItem, 'credit_wl', 0),
            note_cycle: _get(this.props.selectedItem, 'note_cycle', ""),
        }


        console.log('param--Update--', params)
        this.props.saveReport_2(params)
        this.props.initialize({})



    }

    changeSelectUser = (e) => {
        let user_id = e.value
        if(user_id) {
            this.props.initListAccount({uid: user_id})
            let user = this.props.listUser.find(item => item.value === user_id)
            this.props.initialize({
                // credit: user ? user.credit : 0
                credit: user ? Helpers.formatMoney(user.credit, 2) : 0
            })

        }
    }

    render() {
        let {  isEditReport, listAccount, isChangeInput } = this.props
        let {changeInput, selectDayObj} = this.state
        let permission = Number(CookieService.get('roles')) === RolesConfig.SALE || Number(CookieService.get('roles')) === RolesConfig.FINANCIAL
        let isSelectDayState = selectDayObj ? true : false

        return (
            <div className="portlet light bordered">
                <div className="portlet-title">
                    <div className="caption font-red-sunglo">
                    <span className="caption-subject bold uppercase">
                        <TransComponent i18nKey="Report_Financial" />
                    </span>
                    </div>
                </div>
                <div className="portlet-body form">
                    <div className="row">
                        {
                            permission ?
                                <>
                                    <div className="col-md-4">
                                        <form name="form_group">
                                            <div className="form-body">
                                                <div className="form-group" >
                                                    <label><TransComponent i18nKey=" User" /></label>
                                                    <Field
                                                        name="user"
                                                        className="basic-single"
                                                        component={renderSelectField}
                                                        isSearchable={true}
                                                        options={this.props.listUser}
                                                        onChange={ e => this.changeSelectUser(e)}

                                                    />
                                                    <Field name="user"component={renderError} />
                                                </div>
                                                <div className="form-group" >
                                                    <label><TransComponent i18nKey="Cycle" /></label>
                                                    {/*{<DayPickerContainer selectType={'fromto'}/>}*/}
                                                    {<DayReportTotalContainer selectType={'fromto'} isReport={true} dataFromSelectDay={this.handleSelectDay}/>}
                                                    <Field name="day"component={renderError} />
                                                </div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="Current credit" /></label>
                                                    <Field
                                                        name="credit"
                                                        type="text"
                                                        // type="number"
                                                        component="input"
                                                        className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                        autoComplete="off"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="Win/loss credit" /></label>
                                                    <Field
                                                        name="credit_winloss"
                                                        // type="number"
                                                        type="text"
                                                        component="input"
                                                        className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                        autoComplete="off"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="Last Credit" /></label>
                                                    <Field
                                                        name="last_credit"
                                                        // type="number"
                                                        type="text"
                                                        component="input"
                                                        className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                        autoComplete="off"
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="form-actions text-right">
                                                    {!isEditReport && <button type="button" className="btn red"
                                                            disabled={this.props.invalid || !isSelectDayState || listAccount.length == 0 || !isChangeInput}
                                                            onClick={this.handleCreate}>
                                                            <TransComponent i18nKey="Create new" />
                                                    </button>}

                                                    {isEditReport && <button type="button" className="btn red"
                                                            disabled={this.props.invalid  || !isEditReport}
                                                            onClick={this.handleUpdate}>
                                                        <TransComponent i18nKey="Update" />
                                                    </button>}
                                                </div>

                                                <ToastContainer
                                                    position="top-right"
                                                    autoClose={4000}
                                                />

                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-8">
                                        {<ListReportContainer_2 />}
                                    </div>

                                    <div className="col-md-12">
                                        {<ListReportTotalContainer_2/>}
                                    </div>
                                </> :
                                <div className="col-md-12">
                                    {<ListReportTotalContainer_2/>}
                                </div>
                        }

                    </div>

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group.values'),
        selectedItem: state.ReportUserReducer.selectedItem,
        listUser: state.ReportUserReducer.listUser,
        listAccFull: state.ReportUserReducer.listAccFull,
        listAccount: state.ReportUserReducer.listAccount,
        isEditReport: state.ReportUserReducer.isEditReport,
        reload: state.ReportUserReducer.reload,
        total_credit_wl: state.ReportUserReducer.total_credit_wl,
        isChangeInput: state.ReportUserReducer.isChangeInput,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        initListUser: () => dispatch(initListUser()),
        initListAccount: (params) => dispatch(initListAccount(params)),
        saveReport_2: (params) => dispatch(saveReport_2(params)),

    };
};

const validate = values => {
    const errors = {}
    if (!values.user) {
        errors.user = '"user" is required'
    }
    if (!values.account ) {
        errors.account = '"account" is required'
    }


    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate,
        initialValues: {
            credit: 0
        },
        enableReinitialize: false
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ReportUserContainer_2);