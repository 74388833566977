import React, { Component } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import {get as _get} from 'lodash'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'my-styles/reactstrap-modal.css'
import { TransComponent } from 'my-components'
import { toggleModalDeleteUser, deleteUser } from 'my-actions/manage/UserImageAction'
import { isPlainObject as _isPlainObject, merge as _merge, cloneDeep as _cloneDeep } from 'lodash';



class ModalDeleteContainer extends Component {
    state = {
        deleteState: {
            isOpenModal: false,
            id: null,
            name: '',
            isLoading: false,
        },
    }
    componentDidUpdate(){
        // Hide modal after delete success
        
        if(this.props.formDeleteStatus && this.props.isOpenModalDelete) {
            this.props.toggleModalDeleteAccount()
        }


    }

    handleChangeState = (state, cb) => () => {
        const oldState = this.state;

        Object.keys(state).forEach(key => {
            if (oldState.hasOwnProperty(key) && _isPlainObject(state[key])) {
                state[key] = _merge(oldState[key], state[key]);
            }
        });

        this.setState(state, () => {
            if (typeof cb === 'function') cb();
        })
    };

    render() {
        let deleteState =  this.state
        let {selectedItem, isOpenModalDelete} = this.props
        return (

                <Modal isOpen={isOpenModalDelete} toggle={_ => this.props.toggleModalDeleteUser()}>
                    <ModalHeader toggle={_ => this.props.toggleModalDeleteUser()}><TransComponent i18nKey="xac nhan" /></ModalHeader>
                    <ModalBody><TransComponent i18nKey="are you sure delete" />&nbsp;<span className="text-uppercase">{selectedItem.username}</span></ModalBody>
                    <ModalFooter>
                        <div className="text-center">
                            <button className="btn bg-green font-white" disabled={deleteState.isLoading} onClick={() => this.props.deleteUser({id: selectedItem.id, username: selectedItem.username})}>
                                <TransComponent i18nKey="Delete" />{ deleteState.isLoading ? <i className="fa fa-spinner fa-spin" /> : null }
                            </button>&nbsp;
                            <button className="btn btn-danger" onClick={() => this.props.toggleModalDeleteUser()}>
                                <TransComponent i18nKey="Cancel" />
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
        );
    }
}



const mapStateToProps = state => {
    return {
        isOpenModalDelete: state.UserImageReducer.isOpenModalDelete,
        formDeleteStatus: state.UserImageReducer.formDeleteStatus,
        selectedItem : state.UserImageReducer.selectedItem,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleModalDeleteUser: _ => dispatch(toggleModalDeleteUser()),
        deleteUser: params => dispatch(deleteUser(params)),
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(ModalDeleteContainer);
