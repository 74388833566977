import { AppConfig} from 'my-constants';
import { BaseService, HttpService} from 'my-utils/core';

class AlertService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}/dashboard`;

    get(){
        return HttpService.get(`${this.serviceUrl}/notice`)
    }
}

export default new AlertService()