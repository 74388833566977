import { get } from 'lodash'

import { ReportImageActionType } from 'my-constants/action-types';
import { ReportImageService } from 'my-services/manage';
import {toast} from "react-toastify";


export const getListReportImage = () => {
    return dispatch => {
        return ReportImageService.getList()
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportImageActionType.GET_LIST_REPORT_IMAGE_SUCCESS,
                        payload: res
                    })
                } else {
                    dispatch({
                        type: ReportImageActionType.GET_LIST_REPORT_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                }

            })
            .catch( e => {
                dispatch({
                    type: ReportImageActionType.GET_LIST_REPORT_IMAGE_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                })
            })

    }
}


export const saveImage = (post) => {
    return dispatch => {
        return ReportImageService.saveImage(post)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportImageActionType.SAVE_IMAGE_SUCCESS,
                        saveStatus: res.status
                    })

                    toast.success("SUCCESS !!!");

                } else {
                    dispatch({
                        type: ReportImageActionType.SAVE_IMAGE_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                    toast.error(get(res, 'res.data.message', 'ERROR !!!'));
                }
            })
            .catch( err => {
                dispatch({
                    type: ReportImageActionType.SAVE_IMAGE_FAIL,
                    payload: get(err, 'response.data', {
                        status: false,
                        error_description: err.stack,
                    })
                })
            })
    }
}

export const getListImageAccount = (params) => {
    return dispatch => {
        ReportImageService.getListImage(params)
            .then(res => {
                if(res.status) {
                    dispatch({
                        type: ReportImageActionType.GET_LIST_IMAGE_ACCOUNT_SUCCESS,
                        payload: res
                    })
                } else {
                    dispatch({
                        type: ReportImageActionType.GET_LIST_IMAGE_ACCOUNT_FAIL,
                        payload: {
                            status: false,
                            error_description: get(res, 'res.data.message', '')
                        }
                    })
                }

            })
            .catch( e => {
                dispatch({
                    type: ReportImageActionType.GET_LIST_IMAGE_ACCOUNT_FAIL,
                    payload: get(e, 'response.data', {
                        status: false,
                        error_description: e.stack,
                    }),
                })
            })
    }
}


