import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import {  renderError } from 'my-utils/components/redux-form/render-form'
import { TransComponent } from 'my-components'
import { saveAccountGroup } from 'my-actions/AccountGroupAction';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'

import { RoutesService } from 'my-routes';

import { AccountGroupListContainer } from 'my-containers/admin/account_group'
import {Field, reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

class AccountGroupContainer extends Component {
    state = {
        id : null,
    }

    componentWillMount() {
        this.props.initialize({...this.props.initialValues})
    }

    componentDidMount() {

    }


    componentWillReceiveProps (nextProps, prevProps) {
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            this.props.initialize({
                ...nextProps.selectedItem
            })

            this.setState(
                {
                    id: nextProps.selectedItem.id
                })

        }


    }

    componentDidUpdate() {

    }

    handleSubmitSave = e => {
        const params = {
            id: this.state.id,
            name: _get(this.props.initialValues, 'name'),
            note: _get(this.props.initialValues, 'note'),

        }
        this.props.saveAccountGroup(params)
        e.preventDefault();
    }

    render() {

        return (
            <div className="portlet light bordered">
                <div className="portlet-title">
                    <div className="caption font-red-sunglo">
                    <span className="caption-subject bold uppercase">
                        <TransComponent i18nKey="Create new" />
                    </span>
                    </div>
                </div>
                <div className="portlet-body form">
                    <div className="row">
                        <div className="col-md-4">
                            <form name="form_group">
                                <div className="form-body">
                                    <div className="form-group">
                                        <label><TransComponent i18nKey="Name" /></label>
                                        <Field
                                            name="name"
                                            type="text"
                                            component="input"
                                            className="form-control form-control-solid placeholder-no-fix"
                                            autoComplete="off"
                                        />
                                        <Field name="name"component={renderError} />
                                    </div>
                                    <div className="form-group">
                                        <label><TransComponent i18nKey="note" /></label>
                                        <Field
                                            name="note"
                                            type="text"
                                            component="textarea"
                                            className="form-control form-control-solid placeholder-no-fix"
                                            autoComplete="off"
                                        />
                                        <Field name="note" component={renderError} />
                                    </div>
                                    <div className="form-actions text-right">
                                        <button type="button" className="btn red" disabled={this.props.invalid}
                                                onClick={this.handleSubmitSave}><TransComponent i18nKey="Save" /></button>
                                    </div>

                                    <ToastContainer
                                        position="top-right"
                                        autoClose={4000}
                                    />

                                </div>
                            </form>
                        </div>
                        <div className="col-md-8">
                            {<AccountGroupListContainer/>}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        initialValues: _get(state, 'form.form_group.values'),
        editStatus: state.AccountGroupReducer.editStatus,
        listGroupUser: state.AccountGroupReducer.listGroupUser,
        selectedItem: state.AccountGroupReducer.selectedItem,
        saveStatus: state.AccountGroupReducer.saveStatus,
        reload: state.AccountGroupReducer.reload,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        saveAccountGroup: (params) => dispatch(saveAccountGroup(params))
    };
};

const validate = values => {
    const errors = {}
    if (!values.username) {
        errors.username = '"username" is required'
    }
    if (!values.password ) {
        errors.password = '"password" is required'
    }
    if (!values.fullname) {
        errors.fullname = '"name" is required'
    }

    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate,
        initialValues: {
            credit: 0
        },
        enableReinitialize: false
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(AccountGroupContainer);