import { AppConfig } from 'my-constants';
import { BaseService, HttpService } from 'my-utils/core';

class GroupUserService extends BaseService {
    serviceUrl = `${AppConfig.API_URL}`;
    /*
    |--------------------------------------------------------------------------
    | @content: Get Groups list
    |--------------------------------------------------------------------------
    */
    getGroupUser(post){
        return HttpService.post(`${this.serviceUrl}/group_user/get`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Save project
    |--------------------------------------------------------------------------
    */
    saveGroupUser(post){
        return HttpService.post(`${this.serviceUrl}/group_user/action`, post);
    };

    /*
    |--------------------------------------------------------------------------
    | @content: Delete project
    |--------------------------------------------------------------------------
    */
    deleteGroupUser(post){
        return HttpService.post(`${this.serviceUrl}/group_user/delete`, post);
    };
}

export default new GroupUserService()