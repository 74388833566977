import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { ModalFormContainer,  ModalDeleteContainer} from 'my-containers/manage/user_image';


class ModalByActionContainer extends Component {
    static propTypes = {
        onToggleModal: PropTypes.bool,
    };

    render() {
        const { selectedItem, isOpenModalDelete } = this.props;
        if(isOpenModalDelete) {
            return <ModalDeleteContainer/>
        }
        if (_isEmpty(selectedItem)) {
            return <ModalFormContainer formType="create" />;
        }

        return <ModalFormContainer formType="update" />;
    }
}

const mapStateToProps = state => {
    return {
        selectedItem: state.UserImageReducer.selectedItem || {},
        isOpenModalDelete: state.UserImageReducer.isOpenModalDelete || false,

    };
};

export default connect(mapStateToProps, null)(ModalByActionContainer);