import io from 'socket.io-client'
import uuidv4 from 'uuid/v4'

import { AppConfig } from 'my-constants'
import { AuthService } from 'my-services/systems'
import EventsService from 'my-utils/core/EventsService'

class SocketService {
    socketUrl = AppConfig.SOCKET_SERVER_URL
    listUUID2Event = {}
    listUUID2AccID = {}
    constructor() {
        this.option = {
            query: `refresh_token=${AuthService.getRefreshToken()}`
        }
    }

    /*
    |--------------------------------------------------------------------------
    | Connect web socket with name space
    |--------------------------------------------------------------------------
    */
    connect(namespace, options = {}) {
        //Inital Socket
        this.option = {...this.option, ...options}
        this.socket = io(this.socketUrl + namespace, this.option).connect()
        
        this.initSocketWatch()
    }

    /*
    |--------------------------------------------------------------------------
    | Method send request to web socket
    | event: init, action
    |--------------------------------------------------------------------------
    */
    send(event, args, uuid) {
        let _uuid = uuid || uuidv4()
        this.listUUID2Event[_uuid] = event

        switch (event) {
            case "init":
                // do something different if any
                this.socket.send({___Send: true, event: event, uuid: _uuid, args: [args]})
            break
            default:
                this.socket.send({___Send: true, event: event, uuid: _uuid, args: [args]})
            break
        }
    }

    listenerResponse() {
        // Listen response from web socket
        this.socket.on('message', msg => {
            switch (this.listUUID2Event[msg.uuid]) {
                case "init":
                    EventsService.emit('init', msg)
                    break
                case "action":
                    if (msg.type === "notify")
                        EventsService.emit('control_server_notify', msg)
                    if (msg.type === "reject")
                        EventsService.emit('control_server_reject', msg)
                    if (msg.type === "resolve")
                        EventsService.emit('control_server_resolve', msg)
                    break
                case "stop":
                    EventsService.emit('control_server_stop', msg)
                break
                default: break
            }
        })
    }

    unListenerResponse() {
        // this.socket.off('message')
        EventsService.removeAllListeners('accountant_init')
        EventsService.removeAllListeners('accountant_scan_notify')
        EventsService.removeAllListeners('accountant_scan_reject')
        EventsService.removeAllListeners('control_server_action_resolve')
        EventsService.removeAllListeners('accountant_reload_banker_account_info')
        EventsService.removeAllListeners('accountant_get_report_banker_account')
        // EventsService.removeAllListeners('accountant_scan_stop')
    }

    /*
    |--------------------------------------------------------------------------
    | Method get response to websocket
    |--------------------------------------------------------------------------
    */
    get(channel, callback) {
        this.socket.on(channel, msg => {
            callback(msg)
        })
    }
    /*
    |--------------------------------------------------------------------------
    | Close connection
    |--------------------------------------------------------------------------
    */
    disconnect() {
        // Reset variable
        this.listUUID2Event = {}
        this.listUUID2AccID = {}
        this.socket.off('connect')
        this.socket.off('ready')
        this.socket.off('connect_error')
        this.socket.off('disconnect')
        this.socket.off('message')
        this.socket.disconnect()
        this.socket = null

        this.unListenerResponse()
    }

    /*
    |--------------------------------------------------------------------------
    | Notify websocket connect status
    |--------------------------------------------------------------------------
    */
    initSocketWatch() {
        // Fire when socket connect successfully
        this.socket.on('connect', _ => {
            console.log(">> Websocket connected successfully", this.socket)
        })

        // Fire when socket connect ready
        this.socket.on('ready', _ => {
            console.log(">> Websocket is ready")
        })

        // Fire when socket connect error
        this.socket.on('connect_error', function (err) {
            console.log(">> Websocket connect err", err)
        })

        // Fire when socket disconnect error
        this.socket.on('disconnect', function (err) {
            console.log(">> Websocket is disconnected", err)
        })
    }
}

export default new SocketService()
