import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import { renderSelectField, renderError } from 'my-utils/components/redux-form/render-form';
import { TransComponent } from 'my-components'
import { initListUser, initListAccount, saveReport } from 'my-actions/ReportUserAction';
import { CookieService } from 'my-utils/core'
import { RolesConfig} from 'my-constants'
import { ListReportContainer } from 'my-containers/report_user'
import { DayPickerContainer } from 'my-containers/manage'
import {Field, reduxForm} from "redux-form";
import {get as _get} from "lodash";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

class ReportUserContainer extends Component {
    state = {
        id : null,
    }



    componentDidMount() {
        this.props.initialize({...this.props.initialValues})
        this.props.initListUser()
    }


    componentWillReceiveProps (nextProps, prevProps) {
        if ((nextProps.selectedItem && this.state.id !== nextProps.selectedItem.id)) {
            let user = this.props.listUser.find(item => item.value === nextProps.selectedItem.user_id)
            this.props.initialize({
                ...nextProps.selectedItem,
                user: user,
                credit: user ? user.credit : 0,
                account: this.props.listAccFull.find(item => item.value === nextProps.selectedItem.account_id),

            })

            this.setState(
                {
                    id: nextProps.selectedItem.id
                })

        }


    }

    componentDidUpdate() {
        if(this.props.reload) {
            this.props.initListUser();

        }
    }


    handleCreate = e => {
        e.preventDefault();

        const params = {
            id: this.state.id,
            user_id: _get(this.props.initialValues, 'user.value'),
            acc_id: _get(this.props.initialValues, 'account.value'),
            from_date: new Date(this.props.selectedDay.from).getTime(),
            to_date: new Date(this.props.selectedDay.to).getTime(),
            credit: _get(this.props.initialValues, 'credit'),
            credit_winloss: _get(this.props.initialValues, 'credit_winloss', null),
            note: _get(this.props.initialValues, 'note'),

        }

        this.props.saveReport(params)
        this.props.initialize({})



    }

    changeSelectUser = (e) => {
        let user_id = e.value
        if(user_id) {
            this.props.initListAccount({uid: user_id})
            let user = this.props.listUser.find(item => item.value === user_id)
            this.props.initialize({
                credit: user ? user.credit : 0
            })
        }
    }

    render() {
        let { isSelectDay, isEditReport } = this.props
        let permission = Number(CookieService.get('roles')) === RolesConfig.SALE || Number(CookieService.get('roles')) === RolesConfig.FINANCIAL

        return (
            <div className="portlet light bordered">
                <div className="portlet-title">
                    <div className="caption font-red-sunglo">
                    <span className="caption-subject bold uppercase">
                        <TransComponent i18nKey="Report" />
                    </span>
                    </div>
                </div>
                <div className="portlet-body form">
                    <div className="row">
                        {
                            permission ?
                                <>
                                    <div className="col-md-4">
                                        <form name="form_group">
                                            <div className="form-body">
                                                <div className="form-group" >
                                                    <label><TransComponent i18nKey=" User" /></label>
                                                    <Field
                                                        name="user"
                                                        className="basic-single"
                                                        component={renderSelectField}
                                                        isSearchable={true}
                                                        options={this.props.listUser}
                                                        onChange={ e => this.changeSelectUser(e)}

                                                    />
                                                    <Field name="user"component={renderError} />
                                                </div>
                                                <div className="form-group" >
                                                    <label><TransComponent i18nKey="Account" /></label>
                                                    <Field
                                                        name="account"
                                                        className="basic-single"
                                                        component={renderSelectField}
                                                        isSearchable={true}
                                                        options={this.props.listAccount}

                                                    />
                                                    <Field name="account"component={renderError} />
                                                </div>
                                                <div className="form-group" >
                                                    <label><TransComponent i18nKey="Cycle" /></label>
                                                    {<DayPickerContainer />}
                                                    <Field name="day"component={renderError} />
                                                </div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="Current credit" /></label>
                                                    <Field
                                                        name="credit"
                                                        type="number"
                                                        component="input"
                                                        className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                        autoComplete="off"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="Win/loss credit" /></label>
                                                    <Field
                                                        name="credit_winloss"
                                                        type="number"
                                                        component="input"
                                                        className="form-control form-control-solid placeholder-no-fix form-group-time"
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label><TransComponent i18nKey="note" /></label>
                                                    <Field
                                                        name="note"
                                                        type="text"
                                                        component="textarea"
                                                        className="form-control form-control-solid placeholder-no-fix"
                                                        autoComplete="off"
                                                    />
                                                    <Field name="note" component={renderError} />
                                                </div>

                                                <div className="form-actions text-right">
                                                    {!isEditReport && <button type="button" className="btn red"
                                                            disabled={this.props.invalid || !isSelectDay }
                                                            onClick={this.handleCreate}>
                                                            <TransComponent i18nKey="Create new" />
                                                    </button>}

                                                    {isEditReport && <button type="button" className="btn red"
                                                            disabled={this.props.invalid ||!isSelectDay || !isEditReport}
                                                            onClick={this.handleCreate}>
                                                        <TransComponent i18nKey="Update" />
                                                    </button>}
                                                </div>

                                                <ToastContainer
                                                    position="top-right"
                                                    autoClose={4000}
                                                />

                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-8">
                                        {<ListReportContainer/>}
                                    </div>
                                </> :
                                <div className="col-md-12">
                                    {<ListReportContainer/>}
                                </div>
                        }
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {

    return {
        initialValues: _get(state, 'form.form_group.values'),
        selectedItem: state.ReportUserReducer.selectedItem,
        listUser: state.ReportUserReducer.listUser,
        listAccFull: state.ReportUserReducer.listAccFull,
        listAccount: state.ReportUserReducer.listAccount,
        selectedDay: state.ReportUserReducer.selectedDay,
        isEditReport: state.ReportUserReducer.isEditReport,
        clearInput: state.ReportUserReducer.clearInput,
        disabledEdit: state.ReportUserReducer.disabledEdit,
        isSelectDay: state.ReportUserReducer.isSelectDay,
        reload: state.ReportUserReducer.reload,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        initListUser: () => dispatch(initListUser()),
        initListAccount: (params) => dispatch(initListAccount(params)),
        saveReport: (params) => dispatch(saveReport(params)),

    };
};

const validate = values => {
    const errors = {}
    if (!values.user) {
        errors.user = '"user" is required'
    }
    if (!values.account ) {
        errors.account = '"account" is required'
    }


    return errors
}

export default compose(
    reduxForm({
        form: 'form_group',
        validate,
        initialValues: {
            credit: 0
        },
        enableReinitialize: false
    }),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(ReportUserContainer);