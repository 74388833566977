import { ConfigActionType } from 'my-constants/action-types'

let defaultState = {
    lstConfig: [],
	updateData: [],
	saveStatus: null
};

export const ConfigReducer = (state = defaultState, action) => {
    const rand = 100 + Math.random() * (100 - 1);
	switch(action.type){
		case ConfigActionType.GET_CONFIG_SUCCESS:
			return { ...state, lstConfig: action.payload, error: null };
        case ConfigActionType.GET_CONFIG_FAIL:
			return { ...state, error: action.payload };
        case ConfigActionType.LOAD_CONFIG:
            return { ...state, updateData: action.data };
		case ConfigActionType.CHANGE_INPUT:
			let index =  action.payload.index
			let list =  action.payload.list
			let newlist = [...state.lstConfig]
            newlist[index] = list
            return { ...state,  lstConfig : newlist };

        case ConfigActionType.SAVE_CONFIG_SUCCESS:
        	console.log('SAVE_CONFIG_SUCCESS...', action)
            return { ...state, saveStatus: action.status, showMessage: true, rand: rand };

		default:
			return {...state}
	}
};


export default ConfigReducer;