/*================================
 * App System Configuration
 *================================*/
export const DEBUG_ENABLED = true

/*
|--------------------------------------------------------------------------
| Define API Variable Enviroment
|--------------------------------------------------------------------------
*/
//export const API_URL = "http://127.0.0.1:3018"
export const API_URL = "https://manage.bprotect.co"
//export const API_URL = "https://manage.vscan.cc"
// export const URL = "http://localhost:3013"
export const API_AUTH_USER = "halfdefense"
export const API_AUTH_PASS = "$jSZ9y})haSLp>LB"
export const API_CLIENT_SECRET = "jashdfjkh1#!$%#^2342@#$@35"

export const SOCKET_SERVER_URL = "http://localhost:5018"
export const DATE_RELEASE = '14/05/2020'
export const VERSION_RELEASE = '1.0.0'

/*
|--------------------------------------------------------------------------
| Define App System Variable Enviroment
|--------------------------------------------------------------------------
*/
export const BASE_HREF = ""
export const SESSION_EXPIRED = 24
export const DEFAULT_LANG = "vi"
export const DEFAULT_LOCALE = "en-US"
export const DEFAULT_TIMEZONE = "America/Caracas"
export const FORMAT_DATE = "MM/DD/YYYY"
export const FORMAT_DATE_DATEPICKER = "MM/dd/yyyy"

export const FORMAT_MONTH = "YYYY"
export const FORMAT_DATETIME = "YYYY/MM/DD HH:mm:ss"
export const FORMAT_DATEMIN = "YYYY/MM/DD HH:mm"
export const FORMAT_LOCALE = "en-Us"


export const LIMIT_ITEM_PER_PAGE = 25



